import { useEffect, useState } from "react";

import styles from "./messages.module.scss";
import SendMessage from "./components/SendMessage";
import HistoryTable from "./components/HistoryTable";
import SelectDevice from "./components/SelectDevice";

import PageComponent from "../../../_components/page/PageComponent";

export default function LauncherMessagesPage({}) {
  const [selectedDevice, setSelectedDevice] = useState(null);

  const [getAgain, setGetAgain] = useState(false);

  return (
    <PageComponent routes={["Launcher", "Messages"]}>
      <SelectDevice selected={selectedDevice} onChange={setSelectedDevice} />

      <div className={styles["container"]}>
        <SendMessage
          id={selectedDevice}
          setGetAgain={() => setGetAgain((prev) => !prev)}
        />

        <HistoryTable id={selectedDevice} getAgain={getAgain} />

        {!selectedDevice && (
          <div className={styles["select-device-message"]}>Please Select Device</div>
        )}
      </div>
    </PageComponent>
  );
}
