import { useEffect, useState } from "react";

import { Button, Drawer, Form, notification } from "antd";

import REQUESTS from "../../../api/requests";
import ImageUpload from "../../../components/ImageUpload";

export default function PlatformDrawer({ onClose, open, editable, getData }) {
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();

    const [image, setImage] = useState({
        url: null,
        file: null,
    });

    const onFinish = (values) => {
        setIsLoading(true);

        const formData = new FormData();

        if (image.file) {
            formData.append("web_icon", image.file);
        }

        formData.append("id", editable.id);

        REQUESTS.WEB_PAGE.PLATFORMS.EDIT(formData)
            .then((response) => {
                setIsLoading(false);

                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });

                    return;
                }

                notification.success({
                    message: "Success",
                    description: response.message,
                });

                onClose();

                getData();
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setImage({
                url: null,
                file: null,
            });
        } else {
            if (editable) {
                setImage({
                    url: editable.web_icon,
                    file: null,
                });
            }
        }
    }, [open, editable]);

    return (
        <Drawer title={`${editable?.name} info`} placement="right" onClose={onClose} open={open}>
            <Form form={form} name="platform" layout="vertical" onFinish={onFinish}>
                <Form.Item label="Image" name="image">
                    <ImageUpload image={image} setImage={setImage} />
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                    <Button type="primary" onClick={() => form.submit()} loading={isLoading}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
}
