import axios from "axios";

import { Checkbox, Drawer } from "antd";
import { useEffect, useState } from "react";

import styles from "./_server.module.scss";
import Loading from "../loadings/Loading";

export default function LogsDrawer({ open, onClose, server }) {
  const [logs, setLogs] = useState([]);
  const [autoScroll, setAutoScroll] = useState(true);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const getLogs = (url) => {
    setErrorMessage(null);
    axios
      .get(url)
      .then((res) => {
        const list = res.data.splice(res.data.length - 200);
        setErrorMessage(null);

        setLogs(list);
        setLoading(false);
        if (autoScroll) {
          document.getElementById("logsSectionId").scrollTop =
            document.getElementById("logsSectionId").scrollHeight;
        }
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(error.message);
      });
  };

  useEffect(() => {
    if (open) {
      if (server && server.ip && server.port) {
        let url = "";

        if (server.ip.search("https://") === 0) {
          url = `${server.ip}/log.json`;
        } else {
          url = `${server.ip}:${server.port}/log.json`;

          if (url.search("http://") === -1) {
            url = `http://${url}`;
          }
        }

        getLogs(url);
      }
    }
  }, [open, server, autoScroll]);

  useEffect(() => {
    if (!open) {
      setLogs([]);
    }
  }, [open]);

  return (
    <Drawer title="Logs" placement="right" onClose={onClose} open={open} width={1000}>
      <Checkbox checked={autoScroll} onChange={(e) => setAutoScroll(e.target.checked)}>
        Auto scroll
      </Checkbox>
      <div id="logsSectionId" className={styles["logs"]}>
        {errorMessage && <p style={{ color: "#f54d4f", fontSize: 18 }}>{errorMessage}</p>}

        {loading ? (
          <Loading style={{ height: "100%" }} />
        ) : (
          logs.map((item, index) => {
            return (
              <p key={index} className={styles["log"]}>
                {item}
              </p>
            );
          })
        )}
      </div>
    </Drawer>
  );
}
