import { useState } from "react";
import { useNavigate } from "react-router-dom";

import REQUESTS from "../../../api/requests";

import { Modal, notification } from "antd";
import LiveTvCategoryDrawer from "./LiveTvCategoryDrawer";
import PageComponent from "../../../_components/page/PageComponent";
import LavieTvCategoriesTable from "./LiveTvCategoriesTable";

export default function LiveTvCategoriesPage() {
  const navigate = useNavigate();
  const [getAgain, setGetAgain] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [editable, setEditable] = useState(null);

  const getCategories = (query, onSuccess, onError) => {
    REQUESTS.LIVE_TV.CATEGORIES.GET({ query: JSON.stringify(query) })
      .then((response) => {
        if (!response.message) {
          notification.error({
            message: "Error",
            description: "Something went wrong",
          });
        } else {
          onSuccess(response.message);
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: "Something went wrong",
        });
        onError(error);
      });
  };

  const onChangePosition = (position, id) => {
    REQUESTS.LIVE_TV.CATEGORIES.POSITION({ position, id, force: false })
      .then((response) => {
        if (response.error) {
          Modal.confirm({
            title: "Do you want to change position",
            content: (
              <div>
                <p>
                  In {position} position already exists <b>{response.message.name}</b>{" "}
                  category. We can switch their positions. Do you want to do it?
                </p>
              </div>
            ),
            okText: "Change",
            onOk() {
              REQUESTS.LIVE_TV.CATEGORIES.POSITION({
                position,
                id: id,
                force: true,
              })
                .then((response) => {
                  notification.success({
                    message: "Success",
                    description: "Position changed successfully",
                  });

                  setGetAgain((prev) => !prev);
                })
                .catch((error) => {
                  notification.error({
                    message: "Error",
                    description: error.message,
                  });
                });
            },
            onCancel() {
              setGetAgain((prev) => !prev);
            },
          });
        } else {
          notification.success({
            message: "Success",
            description: "Position changed successfully",
          });

          setGetAgain((prev) => !prev);
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message,
        });
      });
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "archive":
        REQUESTS.LIVE_TV.CATEGORIES.ARCHIVE_RESTORE({ archive: true, id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });

              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      case "restore":
        REQUESTS.LIVE_TV.CATEGORIES.ARCHIVE_RESTORE({ archive: false, id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });

              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      case "delete":
        REQUESTS.LIVE_TV.CATEGORIES.DELETE({ id: item.id })
          .then((response) => {
            if (response.error) {
              notification.error({
                message: "Error",
                description: response.message,
              });

              return;
            }

            notification.success({
              message: "Success",
              description: response.message,
            });
            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      case "protect":
      case "unprotect":
        const body = {
          id: item.id,
          is_protected: event.key === "protect" ? true : false,
        };

        REQUESTS.LIVE_TV.CATEGORIES.PROTECTED(body)
          .then((response) => {
            if (response.error) {
              notification.error({
                message: "Error",
                description: response.message,
              });

              return;
            }
            notification.success({
              message: "Success",
              description: response.message,
            });

            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });

        break;

      default:
        break;
    }
  };

  const handleBulkDelete = (ids) => {
    REQUESTS.LIVE_TV.CATEGORIES.DELETE_BULK({ ids })
      .then((response) => {
        if (!response.error) {
          notification.success({
            message: "Success",
            description: response.message,
          });
          setGetAgain((prev) => !prev);
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error,
        });
      });
  };

  return (
    <PageComponent routes={["Live TV", "Categories"]}>
      <LavieTvCategoriesTable
        getAgain={getAgain}
        getDataSource={getCategories}
        handleMenuClick={handleMenuClick}
        onChangePosition={onChangePosition}
        openDrawer={() => setIsOpenDrawer(true)}
        handleBulkDelete={handleBulkDelete}
      />

      <LiveTvCategoryDrawer
        open={isOpenDrawer}
        editable={editable}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
          setEditable(null);
        }}
      />
    </PageComponent>
  );
}
