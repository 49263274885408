import { Modal } from "antd";

export default function confirmIsProtected({ form, categoriesOption, checked }) {
    const { categories } = form.getFieldsValue();

    if (categories) {
        const selectedCategories = categoriesOption.filter((obj) => categories.includes(obj.id));

        const isProtectedCategories = selectedCategories.filter((item) => item.is_protected);

        if (isProtectedCategories.length) {
            form.setFields([{ name: "is_protected", value: true }]);

            if (checked === false) {
                Modal.confirm({
                    title: "Are you sure?",
                    content:
                        "Are you sure you want to remove this channel from the protected list? This channel is currently included in the protected category and removing it may result in unintended consequences. Please proceed with caution and ensure that you have a good reason for removing this channel from the protected list before making any changes.",
                    okText: "Yes",
                    onOk() {
                        form.setFields([{ name: "is_protected", value: false }]);
                    },
                    onCancel() {
                        form.setFields([{ name: "is_protected", value: true }]);
                    },
                });
            }
        } else {
            form.setFields([{ name: "is_protected", value: checked }]);
        }
    }
}
