import { Alert, Checkbox, Spin } from "antd";
import { useEffect, useState } from "react";

import REQUESTS from "../../api/requests";

export default function Roles({ defaultCheckedRoles, setCheckedRoles, open }) {
    const [roles, setRoles] = useState([]);

    const [loading, setLoading] = useState(false);

    const isCheckedAll = (item) => {
        let list = item.list;
        for (let i = 0; i < list.length; i++) {
            if (!list[i].checked) return false;
        }
        return true;
    };

    const checkGroupRol = (event, item) => {
        let rolesList = [...roles];

        for (let i = 0; i < rolesList.length; i++) {
            if (rolesList[i].name === item.name) {
                let list = rolesList[i].list;
                for (let g = 0; g < list.length; g++) {
                    list[g].checked = event.target.checked;
                }
            }
        }

        setRoles(rolesList);
    };

    const checkRol = (event, item, groupName) => {
        let rolesList = [...roles];

        for (let i = 0; i < rolesList.length; i++) {
            if (rolesList[i].name === groupName) {
                let list = rolesList[i].list;
                for (let g = 0; g < list.length; g++) {
                    if (list[g].id === item.id) {
                        list[g].checked = event.target.checked;
                    }
                }
            }
        }

        setRoles(rolesList);
    };

    const getRoles = () => {
        setLoading(true);
        REQUESTS.ADMINS.ROLES()
            .then((response) => {
                const data = response.message;

                let newSet = new Set();
                for (let i = 0; i < data.length; i++) {
                    newSet.add(data[i].group);
                }

                let array = [...newSet];

                let listOfGroups = [];

                for (let i = 0; i < array.length; i++) {
                    let group = {
                        name: array[i],
                        list: [],
                    };
                    for (let j = 0; j < data.length; j++) {
                        if (array[i] === data[j].group) {
                            if (defaultCheckedRoles.indexOf(data[j].id) !== -1) {
                                data[j].checked = true;
                            } else {
                                data[j].checked = false;
                            }
                            group.list.push(data[j]);
                        }
                    }
                    listOfGroups.push(group);
                }

                setRoles(listOfGroups);

                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        let checkeds = [];

        for (let i = 0; i < roles.length; i++) {
            for (let j = 0; j < roles[i].list.length; j++) {
                if (roles[i].list[j].checked) {
                    checkeds.push(roles[i].list[j].id);
                }
            }
        }

        setCheckedRoles(checkeds);
    }, [roles]);

    useEffect(() => {
        if (open) {
            getRoles();
        }
    }, [defaultCheckedRoles, open]);

    return (
        <div style={{ marginBottom: 30 }}>
            {loading ? (
                <>
                    <Spin spinning={loading}>
                        <Alert message="Permissions list" description="Please wait" type="info" />
                    </Spin>
                </>
            ) : (
                <div className="gr-parent">
                    {roles.map((item, key) => {
                        let isChecked = isCheckedAll(item);
                        return (
                            <div key={key} style={{ gridRow: item.list.length + " span" }}>
                                <Checkbox
                                    checked={isChecked}
                                    onChange={(e) => checkGroupRol(e, item)}
                                >
                                    {item.name}
                                </Checkbox>

                                <div style={{ marginLeft: 30 }}>
                                    {item.list.map((_item, _key) => {
                                        return (
                                            <Checkbox
                                                key={_key}
                                                style={{ width: "100%", margin: 0 }}
                                                checked={_item.checked}
                                                onChange={(e) => checkRol(e, _item, item.name)}
                                            >
                                                {_item.name}
                                            </Checkbox>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}
