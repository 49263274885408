import { Typography, notification } from "antd";
import TableComponent from "../../_components/table/TableComponent";
import dayjs from "dayjs";
import { useState, useEffect } from "react";

const UsageInfoTable = ({ getDailyInfo }) => {
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataSource, setDataSource] = useState([]);

  const bytesToGigabytes = (bytes) => {
    // const gigabytes = bytes ;
    // return `${gigabytes.toFixed(1)} GB`;

    return ` ${bytes?.toFixed(3)} GB`;
  };

  const tableColumns = [
    {
      title: "#",
      key: "id",
      dataIndex: "id",
      align: "center",

      render: (text, record) => <Typography>{text}</Typography>,
    },
    {
      title: "Client id",
      key: "client_id",
      dataIndex: "client_id",
      align: "center",

      render: (text, record) => <Typography>{text}</Typography>,
    },
    {
      title: "date",
      key: "date",
      dataIndex: "date",
      align: "center",
      render: (text, record) => (
        <Typography>{dayjs(record?.createdAt).format("DD-MM-YYYY")}</Typography>
      ),
    },

    {
      title: "Live streams count",
      key: "live_streams_count",
      dataIndex: "live_streams_count",
      align: "center",
      render: (text, record) => <Typography>{text}</Typography>,
    },

    {
      title: "Subscribers count",
      key: "subscribers_count",
      dataIndex: "subscribers_count",
      align: "center",

      render: (text, record) => <Typography>{text}</Typography>,
    },
    {
      title: "Used storage",
      key: "used_storage",
      dataIndex: "used_storage",
      align: "center",

      render: (text, record) => <Typography>{bytesToGigabytes(text)}</Typography>,
    },
    {
      title: "Used traffic",
      key: "used_traffic",
      dataIndex: "used_traffic",
      align: "center",

      render: (text, record) => <Typography>{bytesToGigabytes(text)}</Typography>,
    },
    {
      title: "Users count",
      key: "users_count",
      dataIndex: "users_count",
      align: "center",

      render: (text, record) => <Typography>{text}</Typography>,
    },
    {
      title: "Videos count",
      key: "videos_count",
      dataIndex: "videos_count",
      align: "center",

      render: (text, record) => <Typography>{text}</Typography>,
    },
  ];

  const handleTAbleChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
  };

  useEffect(() => {
    const query = {
      limit,
      page: currentPage,
    };

    function success(data) {
      setDataSource(data?.rows);
      setCurrentPage(data.page);
      setLimit(data.limit);
    }

    function error(error) {
      console.log(error);
      notification.error({
        message: "Something went wrong",
      });
    }

    getDailyInfo(query, success, error);
  }, [getDailyInfo, currentPage, limit]);

  return (
    <>
      <TableComponent
        columns={tableColumns}
        dataSource={dataSource}
        onChange={handleTAbleChange}
        pagination={{
          page: currentPage,
          limit: limit,
          total: total,
        }}
      />
    </>
  );
};

export default UsageInfoTable;
