import { useEffect, useState } from "react";

import { Form, Input, Switch, notification } from "antd";

import REQUESTS from "../../../../api/requests";

import ImageUpload from "../../../../components/ImageUpload";

import ButtonComponent from "../../../../_components/button/ButtonComponent";

export default function StripeConfig({ data, selectedCurrency }) {
    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);

    const [icon, setIcon] = useState({
        url: null,
        file: null,
    });

    const onFinish = (values) => {
        setIsLoading(true);

        const formData = new FormData();

        if (icon.file) {
            formData.append("stripe_icon", icon.file);
        }

        formData.append("id", data.id);
        formData.append("currency_id", selectedCurrency);
        formData.append("stripe_enabled", values.stripe_enabled);
        formData.append("stripe_public_key", values.stripe_public_key);
        formData.append("stripe_secret_key", values.stripe_secret_key);
        formData.append("stripe_webhook_secret", values.stripe_webhook_secret);

        REQUESTS.PAYMENTS.CONFIGS.UPDATE(formData)
            .then((response) => {
                setIsLoading(false);

                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });

                    return;
                }

                notification.success({
                    message: "Success",
                    description: "Stripe configs is changed",
                });
            })
            .catch((error) => {
                setIsLoading(false);
                notification.error({
                    message: "Error",
                    description: error,
                });
            });
    };

    const onChangeIcon = (value) => {
        setIcon(value);

        form.setFieldsValue({
            stripe_icon: value.file,
        });
    };

    useEffect(() => {
        if (data) {
            setIcon({
                url: data.stripe_icon,
                file: null,
            });

            form.setFields([
                { name: "stripe_icon", value: data.stripe_icon },
                { name: "stripe_enabled", value: data.stripe_enabled },
                { name: "stripe_public_key", value: data.stripe_public_key },
                { name: "stripe_secret_key", value: data.stripe_secret_key },
                { name: "stripe_webhook_secret", value: data.stripe_webhook_secret },
            ]);
        }
    }, [data]);

    return (
        <Form
            form={form}
            name="stripe-config"
            layout="vertical"
            onFinish={onFinish}
            style={{ maxWidth: 800 }}
        >
            <Form.Item shouldUpdate noStyle>
                {() => {
                    const { stripe_enabled } = form.getFieldsValue();

                    return (
                        <Form.Item
                            label="Icon"
                            name="stripe_icon"
                            rules={[
                                {
                                    required: stripe_enabled,
                                    message: "Please choose icon",
                                },
                            ]}
                        >
                            <ImageUpload image={icon} setImage={onChangeIcon} />
                        </Form.Item>
                    );
                }}
            </Form.Item>

            <Form.Item label="Enabled" name="stripe_enabled" valuePropName="checked">
                <Switch />
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
                {() => {
                    const { stripe_enabled } = form.getFieldsValue();

                    return (
                        <Form.Item
                            label="Stripe public key"
                            name="stripe_public_key"
                            rules={[
                                {
                                    required: stripe_enabled,
                                    message: "Please input public key",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    );
                }}
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
                {() => {
                    const { stripe_enabled } = form.getFieldsValue();

                    return (
                        <Form.Item
                            label="Stripe secret key"
                            name="stripe_secret_key"
                            rules={[
                                {
                                    required: stripe_enabled,
                                    message: "Please input secret key",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    );
                }}
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
                {() => {
                    const { stripe_enabled } = form.getFieldsValue();

                    return (
                        <Form.Item
                            label="Stripe webhook secret"
                            name="stripe_webhook_secret"
                            rules={[
                                {
                                    required: stripe_enabled,
                                    message: "Please input webhook secret",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    );
                }}
            </Form.Item>

            <ButtonComponent
                title="Save"
                actionType="save"
                isLoading={isLoading}
                onClick={() => form.submit()}
            />
        </Form>
    );
}
