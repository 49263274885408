import { Form, Select } from "antd";

import { useEffect, useState } from "react";

import REQUESTS from "../../../api/requests";

export default function LessonsSelect({ form, editable, open }) {
    const [moviesOptions, setMoviesOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getMoviesOptions = () => {
        setIsLoading(true);

        let query = {
            limit: 100000,
        };

        if (!editable) {
            query.filter = {
                is_lessons: false,
            };
        }

        REQUESTS.VOD.MEDIAS.GET({ query: JSON.stringify(query) })
        .then((response) => {
            if (response.message) {
                const data = response.message;

                if (editable) {
                    let filteredList = [];
    
                    for (let i = 0; i < data.rows.length; i++) {
                        if (data.rows[i].is_lessons) {
                            let ids = editable.movies_id.split(",");
    
                            for (let g = 0; g < ids.length; g++) {
                                if (ids[g] == data.rows[i].id) {
                                    filteredList.push({
                                        label: data.rows[i].name,
                                        value: data.rows[i].id,
                                    });
                                }
                            }
    
                            continue;
                        }
    
                        filteredList.push({
                            label: data.rows[i].name + " " + data.rows[i].id,
                            value: data.rows[i].id,
                        });
                    }
    
                    setMoviesOptions(filteredList);
    
                    const ids = editable.movies_id.split(",");
                    const selecteds = ids.map((x) => +x);
    
                    if (selecteds) {
                        form.setFields([{ name: "movies_id", value: selecteds }]);
                    }
                } else {
                    const list = data.rows.map((item) => ({
                        label: item.name,
                        value: item.id,
                        ...item,
                    }));
    
                    setMoviesOptions(list);
                }
    
                setIsLoading(false);
            }
        })
        .catch((error) => {});

       
    };

    const onChange = (values) => {
        form.setFields([{ name: "movies_id", value: values }]);
    };

    useEffect(() => {
        getMoviesOptions();
    }, [editable]);

    useEffect(() => {
        if (!open) {
            setMoviesOptions([]);
        } else {
            getMoviesOptions();
        }
    }, [open]);

    return (
        <Form.Item
            label="Lessons"
            name="movies_id"
            rules={[
                {
                    required: true,
                    message: "Please select movie",
                },
            ]}
        >
            <Select
                options={moviesOptions}
                mode="multiple"
                onChange={onChange}
                showArrow={true}
                loading={isLoading}
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())}
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                }
            />
        </Form.Item>
    );
}
