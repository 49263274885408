import { Drawer } from "antd";
import { useEffect, useState } from "react";

import REQUESTS from "../../../../../../api/requests";

import { liveTvServiceId } from "../../../../../../config/statics";

import ButtonComponent from "../../../../../../_components/button/ButtonComponent";

import ByIdDrawer from "./ByIdDrawer";
import LiveTvTable from "./LiveTvTable";
import SetupFilterDrawer from "./SetupFilterDrawer";

export default function LiveTvDrawer({
    open,
    onClose,
    liveTvService,
    setLiveTvSeervice,
    setSelectedTvShowsCount,
}) {
    const [isOpenSetupFilterDrawer, setIsOpenSetupFilterDrawer] = useState(false);
    const [isOpenByIdDrawer, setIsOpenByIdDrawer] = useState(false);

    const [actualLiveTvService, setActualLiveTvService] = useState({
        id: {
            exclude: [],
            include: [],
        },
        category: {
            type: "",
            values: [],
        },
    });

    const getDataSource = (query, onSuccess, onError) => {
        REQUESTS.PAYMENTS.SUBSCRIPTIONS.CONTENT({
            query: JSON.stringify(query),
            filter: JSON.stringify(actualLiveTvService),
            serviceId: liveTvServiceId,
        })
            .then((response) => {
                if (response.message) {
                    setSelectedTvShowsCount(response.message?.total);
                }
                onSuccess(response.message);
            })
            .catch((error) => {
                onError(error);
            });
    };

    const excludeLiveTv = (item) => {
        const exclude = [...actualLiveTvService.id.exclude];

        exclude.push(item.id);

        setActualLiveTvService((prev) => ({
            ...prev,
            id: {
                exclude: exclude,
                include: prev.id.include,
            },
        }));
    };

    const removeLiveTvFromExcludeList = (ids) => {
        let exclude = [...actualLiveTvService.id.exclude];

        exclude = exclude.filter((item) => !ids.includes(item));

        setActualLiveTvService((prev) => ({
            ...prev,
            id: {
                exclude: exclude,
                include: prev.id.include,
            },
        }));
    };

    const onAddLiveTv = (ids) => {
        removeLiveTvFromExcludeList(ids);
        setActualLiveTvService((prev) => ({
            ...prev,
            id: {
                exclude: prev.id.exclude,
                include: ids,
            },
        }));
    };

    const onAdd = () => {
        setLiveTvSeervice(actualLiveTvService);
        onClose();
    };

    const onAddFilterData = (data) => {
        setActualLiveTvService((prev) => ({
            ...prev,
            category: data.category,
        }));
    };

    useEffect(() => {
        if (open) {
            setActualLiveTvService(liveTvService);
        }
    }, [liveTvService, open]);

    return (
        <Drawer width={800} title="Live tv" placement="right" onClose={onClose} open={open}>
            <h6>Here you can see your selected live tv</h6>

            <LiveTvTable
                excludeLiveTv={excludeLiveTv}
                getDataSource={getDataSource}
                actualLiveTvService={actualLiveTvService}
                openSetupFilterDrawer={() => setIsOpenSetupFilterDrawer(true)}
                opnByIdDrawer={() => setIsOpenByIdDrawer(true)}
            />

            <div style={{ marginTop: 20, textAlign: "center" }}>
                <ButtonComponent title="Save" actionType="save" onClick={onAdd} />
            </div>

            <ByIdDrawer
                open={isOpenByIdDrawer}
                actualVodService={actualLiveTvService}
                onAdd={(ids) => {
                    onAddLiveTv(ids);
                }}
                onClose={() => setIsOpenByIdDrawer(false)}
            />

            <SetupFilterDrawer
                onAdd={onAddFilterData}
                open={isOpenSetupFilterDrawer}
                actualLiveTvService={actualLiveTvService}
                onClose={() => setIsOpenSetupFilterDrawer(false)}
            />
        </Drawer>
    );
}
