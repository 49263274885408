import { useEffect, useState } from "react";

import { Button, Input, notification } from "antd";

import REQUESTS from "../../../api/requests";

import styles from "./_translations.module.scss";

export default function EditableTranslation({ record, getData }) {
    const [isLoading, setIsLoading] = useState(false);
    const [text, setText] = useState("");

    const [active, setActive] = useState(false);

    const [disabled, setDisabled] = useState(false);

    const onSave = () => {
        setIsLoading(true);

        const body = {
            word: text,
            id: record.id,
        };

        REQUESTS.APPLICATION.TRANSLATIONS.EDIT(body)
            .then((response) => {
                setIsLoading(false);
                if (!response.error) {
                    notification.success({
                        message: "Success",
                        description: "Translation edited successfully",
                    });

                    setActive(false);
                    setDisabled(true);
                    getData();
                } else {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });
                }
            })
            .catch((error) => {
                notification.error({
                    message: "Error",
                    description: error,
                });

                setIsLoading(false);
            });
    };

    useEffect(() => {
        setText(record?.translatedWord);
    }, [record]);

    useEffect(() => {
        if (text === record?.translatedWord) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [text]);

    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", gap: 10 }}>
            <Input
                value={text}
                readOnly={!active}
                onChange={(e) => setText(e.target.value)}
                disabled={!active}
                style={{ background: !active ? "#f8f8f8" : "white" }}
                onPressEnter={onSave}
            />

            <div style={{ width: 30 }}>
                {active ? (
                    disabled ? (
                        <Button
                            type="primary"
                            danger={true}
                            onClick={() => setActive((prev) => !prev)}
                            className={`${styles["icon-button"]}`}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: '<i class="fas fa-times"></i>',
                                }}
                            ></div>
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            onClick={onSave}
                            loading={isLoading}
                            className={`${styles["icon-button"]}`}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: '<i class="fas fa-check"></i>',
                                }}
                            ></div>
                        </Button>
                    )
                ) : (
                    <Button
                        type="primary"
                        onClick={() => setActive(true)}
                        className={`${styles["icon-button"]} ${styles["edit-button"]}`}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: '<i class="fas fa-pencil-alt"></i>',
                            }}
                        ></div>
                    </Button>
                )}
            </div>
        </div>
    );
}
