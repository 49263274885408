import { useEffect, useState } from "react";
import { getAtSession, setInSession } from "../../../helpers/storages";

import icons from "../../../config/icons";
import { getColumnSearchProps } from "../../../config/config";

import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";

export default function TvodPaymentTable({
  getAgain,
  openDrawer,
  getDataSource,
  handleMenuClick,
}) {
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const [limit, setLimit] = useState(10);

  const [currentPage, setCurrentPage] = useState(getAtSession("tvodPayment")?.page || 1);

  const [sort, setSort] = useState(getAtSession("tvodPayment")?.sort || ["id", "DESC"]);

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState(
    getAtSession("tvodPayment")?.search || {
      name: null,
      categories: null,
    }
  );

  const columns = [
    {
      width: 60,
      title: "#",
      key: "index",
      dataIndex: `id`,
      fixed: "left",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",

      ...getColumnSearchProps(),
    },

    {
      title: "Resolution name",
      key: "resolution_name",
      dataIndex: "resolution_name",
      align: "center",
      render: (text, record, index) => {
        return (
          <>
            {record?.pricing_resolutions?.map((el) => {
              return <p>{el?.name}</p>;
            })}
          </>
        );
      },
    },

    {
      title: "Price",
      key: "resolution_price",
      dataIndex: "resolution_price",
      align: "center",
      render: (text, record, index) => {
        return (
          <>
            {record?.pricing_resolutions?.map((el) => {
              return (
                <>
                  <p>{el?.price}</p>
                </>
              );
            })}
          </>
        );
      },
    },

    {
      title: "Rent price",
      key: "resolution_rent_price",
      dataIndex: "resolution_rent_price",
      align: "center",
      render: (text, record, index) => {
        return (
          <>
            {record?.pricing_resolutions?.map((el) => {
              return <p>{el?.rent_price}</p>;
            })}
          </>
        );
      },
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: icons.EDIT,
            },

            {
              key: "delete",
              name: "Delete",
              icon: icons.DELETE,
              question: `Do you want to delete this group?`,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);
  };

  useEffect(() => {
    const fetchLiveTvData = () => {
      setLoading(true);

      const query = {
        sort,
        limit,
        page: currentPage,
      };

      if (search.name) {
        query.search = {
          name: search.name,
        };
      }

      if (search.categories) {
        query.filter = {
          categoryId: search.categories,
        };
      }

      function onSuccess(data) {
        setLoading(false);

        if (data?.rows?.length > 0 || search?.name) {
          setDataSource(data?.rows);
          setIsEmpty(false);
        } else {
          setIsEmpty(true);
        }
        setTotal(data?.count);

        const maxPage = Math.ceil(data?.count / limit);
        const storedPage = getAtSession("tvodPayment")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("tvodPayment", {
            ...getAtSession("tvodPayment"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("tvodPayment", {
            ...getAtSession("tvodPayment"),
            page: currentPage,
          });
        }
      }

      function onError(data) {
        setLoading(false);
      }
      getDataSource({ query: JSON.stringify(query) }, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (
        getAtSession("tvodPayment") &&
        getAtSession("tvodPayment")?.page > currentPage
      ) {
        setInSession("tvodPayment", {
          ...getAtSession("tvodPayment"),
          page: 1,
        });
        setCurrentPage(getAtSession("tvodPayment")?.page);
      }

      fetchLiveTvData();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, getAgain]);

  return (
    <TableComponent
      header={
        <>
          <ButtonComponent icon={icons.ADD} title="Add" onClick={openDrawer} />
        </>
      }
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      localeClick={openDrawer}
      isEmpty={isEmpty}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
    />
  );
}
