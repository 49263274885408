import { useState } from "react";

import REQUESTS from "../../../api/requests";

import deleteSubscription from "./deleteSubscription";

import PaymentsSubsriptionsTable from "./PaymentsSubsriptionsTable";
import PaymentSubscriptionDrawer from "./drawer/PaymentSubscriptionDrawer";

import PageComponent from "../../../_components/page/PageComponent";

export default function PaymentsSubscriptionsPage() {
    const [getAgain, setGetAgain] = useState(false);

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);

    const [editable, setEditable] = useState(null);

    const getDataSource = (query, onSuccess, onError) => {
        REQUESTS.PAYMENTS.SUBSCRIPTIONS.GET(query)
            .then((response) => {
                onSuccess(response.message);
            })
            .catch((error) => {
                onError(error);
            });
    };

    const handleMenuClick = (event, item) => {
        switch (event.key) {
            case "edit":
                setEditable(item);
                setIsOpenDrawer(true);
                break;

            case "delete":
                deleteSubscription(item.id, setGetAgain);

            default:
                break;
        }
    };

    return (
        <PageComponent routes={["Payment", "Subscriptions"]}>
            <PaymentsSubsriptionsTable
                getAgain={getAgain}
                getDataSource={getDataSource}
                handleMenuClick={handleMenuClick}
                openDrawer={() => setIsOpenDrawer(true)}
            />

            <PaymentSubscriptionDrawer
                open={isOpenDrawer}
                editable={editable}
                getData={() => setGetAgain((prev) => !prev)}
                onClose={() => {
                    setEditable(null);
                    setIsOpenDrawer(false);
                }}
            />
        </PageComponent>
    );
}
