import { useEffect, useState } from "react";

import icons from "../../../../../../config/icons";
import { parseDate } from "../../../../../../config/config";

import TableButtons from "../../../../../../_components/table/TableButtons";
import TableComponent from "../../../../../../_components/table/TableComponent";

export default function DevicesTable({ getAgain, getDataSource, handleMenuClick, userId }) {
    const [dataSource, setDataSource] = useState([]);

    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: "#",
            width: 60,
            dataIndex: `id`,
            align: "center",
            key: "index",
            render: (record, text, index) => {
                return index + 1;
            },
        },

        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "center",
        },

        {
            title: "Model",
            dataIndex: "model",
            key: "model",
            align: "center",
        },

        {
            title: "Ipv4",
            dataIndex: "ipv4",
            key: "ipv4",
            align: "center",
        },

        {
            title: `Connect date`,
            dataIndex: "connectDate",
            key: "connectDate",
            align: "center",

            render: (text, record, index) => {
                return parseDate(record.connect_date);
            },
        },

        {
            title: "",
            key: "operation",
            fixed: "right",
            width: 60,
            align: "center",

            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    items={[
                        {
                            key: "delete",
                            name: "Delete",
                            icon: icons.DELETE,
                            question: `Do you want to delete this device`,
                            onConfirm: (e) => {
                                handleMenuClick(e, record);
                            },
                        },
                    ]}
                />
            ),
        },
    ];

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(true);

            function onSuccess(data) {
                setLoading(false);
                setDataSource(data);
            }

            function onError(data) {
                setLoading(false);
            }

            getDataSource({ id: userId }, onSuccess, onError);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [getAgain]);

    return <TableComponent isLoading={loading} dataSource={dataSource} columns={columns} />;
}
