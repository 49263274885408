import { useEffect, useState } from "react";

import { Button, Drawer, Form, Input, notification } from "antd";
import REQUESTS from "../../../../../../api/requests";
import ButtonComponent from "../../../../../../_components/button/ButtonComponent";
import icons from "../../../../../../config/icons";

export default function NewNote({ selectedLanguage, editableMovieId, getData }) {
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const body = {
      movieId: editableMovieId,
      description: values.description,
      languageId: selectedLanguage?.id,
    };

    REQUESTS.VOD.MEDIA_CONTENT.NOTES.ADD(body)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message,
          });

          return;
        }

        notification.success({
          message: "Success",
          description: "Note added successfully",
        });

        form.resetFields();
        getData();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err,
        });

        setIsLoading(false);
      });
  };

  return (
    <Form
      form={form}
      name="new-note"
      layout="horizontal"
      onFinish={onFinish}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          form.submit();
        }
      }}
    >
      <Form.Item
        label="New Note"
        name="description"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please input note description",
        //   },
        // ]}
      >
        <div style={{ display: "flex", gap: 15 }}>
          <Input />

          <ButtonComponent
            type="secondary"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: ".5px solid #d9d9d9",
            }}
            loading={isLoading}
            icon={icons.ADD}
            onClick={() => form.submit()}
          />
        </div>
      </Form.Item>
    </Form>
  );
}
