import { useParams } from "react-router";

import REQUESTS from "../../../api/requests";

import PATHS from "../../../config/paths";

import TranslationsTable from "./TranslationsTable";

import PageComponent from "../../../_components/page/PageComponent";

export default function TranslationsPage() {
  const params = useParams();

  const getDataSource = (query, onSuccess, onError) => {
    const id = params?.id;

    if (id) {
      REQUESTS.APPLICATION.TRANSLATIONS.GET({ query: JSON.stringify(query), id })
        .then((response) => {
          onSuccess(response.message);
        })
        .catch((error) => {
          onError(error);
        });
    }
  };

  return (
    <PageComponent
      routes={["Application", "Languages", "Translations"]}
      backPath={PATHS.APPLICATION_LANGUAGES}
    >
      <TranslationsTable getDataSource={getDataSource} />
    </PageComponent>
  );
}
