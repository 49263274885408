import React from "react";
import { Layout, theme } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import style from "./header.module.scss";
import Profile from "./components/profile/Profile";
import UsageInfo from "./components/usageInfo/UsageInfo";
import LanguageDropDown from "../../components/languageDropDown/LanguageDropDown";

export default function Header({ collapsed, setCollapsed }) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { permissions } = useSelector((state) => state.globalState);

  return (
    <>
      <Layout.Header
        className={style["header"]}
        style={{
          background: colorBgContainer,
          width: `calc(100% - ${collapsed ? 80 : 200}px)`,
          height: "auto",
        }}
      >
        <div className={style["container"]}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          })}
          {permissions?.cloud_service && (
            <div
              style={{
                padding: "5px",
                flex: "1",
                marginLeft: "10px",
              }}
            >
              <UsageInfo />
            </div>
          )}
          <div className={style["header-item-wrapper"]}>
            {/* <div className={style["language-selector"]}>
              <LanguageDropDown />
            </div> */}
            <Profile />
          </div>
        </div>
        <hr />
      </Layout.Header>
    </>
  );
}
