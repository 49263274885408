import { useEffect, useState } from "react";

import { Form, notification, Switch } from "antd";

import REQUESTS from "../../../../api/requests";

import ImageUpload from "../../../../components/ImageUpload";

import ButtonComponent from "../../../../_components/button/ButtonComponent";

export default function AppleConfig({ data, selectedCurrency }) {
    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);

    const [icon, setIcon] = useState({
        url: null,
        file: null,
    });

    const onFinish = (values) => {
        setIsLoading(true);

        const formData = new FormData();

        if (icon.file) {
            formData.append("apple_icon", icon.file);
        }

        formData.append("id", data.id);
        formData.append("currency_id", selectedCurrency);
        formData.append("apple_enabled", values.apple_enabled);

        REQUESTS.PAYMENTS.CONFIGS.UPDATE(formData)
            .then((response) => {
                setIsLoading(false);

                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });

                    return;
                }

                notification.success({
                    message: "Success",
                    description: "Apple pay configs is changed",
                });
            })
            .catch((error) => {
                setIsLoading(false);
                notification.error({
                    message: "Error",
                    description: error,
                });
            });
    };

    const onChangeIcon = (value) => {
        setIcon(value);

        form.setFieldsValue({
            apple_icon: value.file,
        });
    };

    useEffect(() => {
        if (data) {
            setIcon({
                url: data.apple_icon,
                file: null,
            });

            form.setFields([
                { name: "apple_icon", value: data.apple_icon },
                { name: "apple_enabled", value: data.apple_enabled },
            ]);
        }
    }, [data]);

    return (
        <Form
            form={form}
            name="apple-pay-config"
            layout="vertical"
            onFinish={onFinish}
            style={{ maxWidth: 350 }}
        >
            <Form.Item label="Icon" name="apple_icon">
                <ImageUpload image={icon} setImage={onChangeIcon} />
            </Form.Item>

            <Form.Item label="Enabled" name="apple_enabled" valuePropName="checked">
                <Switch />
            </Form.Item>

            <ButtonComponent
                title="Save"
                actionType="save"
                isLoading={isLoading}
                onClick={() => form.submit()}
            />
        </Form>
    );
}
