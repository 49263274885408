import { useEffect, useState } from "react";
import { Checkbox, Drawer, Form, Select, Radio } from "antd";
import {
  useAgeRestrictionOptions,
  useCountriesOptions,
  usePaymentsSubscriptionsOptions,
  useVodGenresOptions,
  useTvodPaymentsOptions,
  useAddListOPtions,
  useLiveTvOptions,
} from "../../../../../hooks/selectOptions";
import ButtonComponent from "../../../../../_components/button/ButtonComponent";
import icons from "../../../../../config/icons";
import { useSelector } from "react-redux";

const BulkEditDrawer = ({ open, onClose, finish, loading }) => {
  const { permissions } = useSelector((state) => state.globalState);

  const liveTvOptions = useLiveTvOptions();

  const [form] = Form.useForm();

  const ageRestrictionOptions = useAgeRestrictionOptions();
  const countriesOptions = useCountriesOptions();
  const paymentsSubscriptionsOptions = usePaymentsSubscriptionsOptions();
  const categoriesOption = useVodGenresOptions();
  const tvodPaymentsOptions = useTvodPaymentsOptions();
  const addListOptions = useAddListOPtions();

  const [fieldChecked, setFieldChecked] = useState({});

  const handleCheck = (event) => {
    const checked = event.target.id;
    const isChecked = { [checked]: event.target.checked };

    if (checked === "location_restriction_enable") {
      form.setFields([
        { name: "location_restriction_enable", value: event.target.checked },
      ]);
    }

    setFieldChecked((prev) => ({ ...prev, ...isChecked }));
  };

  const checkProtection = (event) => {
    const checked = event.target.checked;

    form.setFields([{ name: "protected", value: checked }]);
  };

  useEffect(() => {
    if (!open) {
      setFieldChecked({});
      form.resetFields();
    }
  }, [open]);

  return (
    <Drawer
      open={open}
      destroyOnClose={true}
      onClose={() => {
        onClose();
        setFieldChecked({});
        form.resetFields();
      }}
      title="Edit"
      width={500}
    >
      <Form
        form={form}
        onFinish={(values) => {
          finish(values, fieldChecked);
          form.resetFields();
        }}
        layout="vertical"
      >
        <div style={{ display: "flex", gap: 30, alignItems: "baseline" }}>
          <Form.Item name="age_restriction_enable">
            <Checkbox onChange={handleCheck} />
          </Form.Item>
          <Form.Item
            label="Age Restriction"
            name="age_restriction"
            style={{ width: "75%" }}
            rules={[
              {
                required: fieldChecked.age_restriction_enable,
                message: "Age Restriction field is required",
              },
            ]}
          >
            <Select
              options={ageRestrictionOptions}
              style={{ width: "100%" }}
              disabled={!fieldChecked.age_restriction_enable}
            />
          </Form.Item>
        </div>
        <div style={{ display: "flex", gap: 30, alignItems: "baseline" }}>
          <Form.Item name="tariff_enable">
            <Checkbox onChange={handleCheck} />
          </Form.Item>
          <Form.Item
            label="Content monetization type"
            name="content_monetization_type"
            style={{
              width: "80%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Radio.Group buttonStyle="solid" disabled={!fieldChecked.tariff_enable}>
              <Radio value="free">Free</Radio>
              <Radio value="stv">SVOD</Radio>
              <Radio value="tvod">TVOD</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            gap: 30,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Form.Item shouldUpdate noStyle style={{ width: "75%", display: "flex" }}>
            {() => {
              const { content_monetization_type } = form.getFieldsValue();

              if (content_monetization_type === "stv") {
                return (
                  <Form.Item
                    label="Tariffs"
                    name="tariffIds"
                    style={{ width: "80%" }}
                    rules={[
                      {
                        required:
                          fieldChecked.tariff_enable &&
                          content_monetization_type === "stv",
                        message: "Tariff field is required",
                      },
                    ]}
                  >
                    <Select
                      options={paymentsSubscriptionsOptions}
                      mode="multiple"
                      style={{ width: "95%" }}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label.toLowerCase() ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) => {
                        return (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase());
                      }}
                      disabled={!fieldChecked.tariff_enable}
                    />
                  </Form.Item>
                );
              } else if (content_monetization_type === "tvod") {
                return (
                  <Form.Item
                    label="TVOD pricing:"
                    name="tariffIds"
                    style={{ width: "80%" }}
                    rules={[
                      {
                        required:
                          fieldChecked.tariff_enable &&
                          content_monetization_type === "tvod",
                        message: "Tariff field is required",
                      },
                    ]}
                  >
                    <Select
                      options={tvodPaymentsOptions}
                      mode="multiple"
                      style={{ width: "95%" }}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label.toLowerCase() ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) => {
                        return (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase());
                      }}
                      disabled={!fieldChecked.tariff_enable}
                    />
                  </Form.Item>
                );
              } else if (content_monetization_type === "free") {
                return (
                  <Form.Item label="Add list" name="ads_id" style={{ width: "80%" }}>
                    <Select
                      options={addListOptions}
                      mode="multiple"
                      style={{ width: "95%" }}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label.toLowerCase() ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) => {
                        return (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase());
                      }}
                      disabled={!fieldChecked.tariff_enable}
                    />
                  </Form.Item>
                );
              }
            }}
          </Form.Item>
        </div>

        <Form.Item
          name="location_restriction_enable"
          style={{
            width: "100%",
            display: "flex",
            gap: 30,
          }}
        >
          <Checkbox onChange={handleCheck} style={{ display: "flex", gap: 28 }}>
            Geolocation restriction
          </Checkbox>
        </Form.Item>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "85%",
          }}
        >
          <Form.Item
            label="Countries"
            name="countries"
            style={{ width: "87%" }}
            rules={[
              {
                required: fieldChecked?.location_restriction_enable,
                message: "Countries field is required",
              },
            ]}
          >
            <Select
              options={countriesOptions}
              mode="multiple"
              style={{ width: "100%" }}
              disabled={!fieldChecked?.location_restriction_enable}
            />
          </Form.Item>
        </div>

        <div style={{ display: "flex", gap: 30 }}>
          <Form.Item name="is_protected_enable">
            <Checkbox onChange={handleCheck} />
          </Form.Item>
          <Form.Item name="protected" style={{ width: "50%" }}>
            <Checkbox
              disabled={!fieldChecked.is_protected_enable}
              onChange={checkProtection}
            >
              Is protected
            </Checkbox>
          </Form.Item>
        </div>
        <div style={{ display: "flex", gap: 30 }}>
          <Form.Item name="categories_enable">
            <Checkbox onChange={handleCheck} />
          </Form.Item>
          <Form.Item
            label="Categories"
            name="categories"
            style={{ width: "75%" }}
            rules={[
              {
                required: fieldChecked.categories_enable,
                message: "Categories field is required",
              },
            ]}
          >
            <Select
              options={categoriesOption}
              mode="multiple"
              style={{ width: "100%" }}
              disabled={!fieldChecked.categories_enable}
            />
          </Form.Item>
        </div>
        {permissions?.rti_service && (
          <div style={{ display: "flex", gap: 30 }}>
            <Form.Item name="channels_id_enable">
              <Checkbox onChange={handleCheck} />
            </Form.Item>
            <Form.Item style={{ width: "75%" }} label="Channels" name="channel_id">
              <Select
                options={liveTvOptions}
                disabled={!fieldChecked.channels_id_enable}
                showSearch={true}
                placeholder="Select a channel"
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input?.toLocaleLowerCase()
                  )
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
          <ButtonComponent
            title={"Save"}
            icon={icons.CHECK}
            isLoading={loading}
            onClick={form.submit}
          />
        </div>
      </Form>
    </Drawer>
  );
};

export default BulkEditDrawer;
