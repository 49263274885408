export const words = {
  en: {
    translation: {
      welcome: "Welcome to {{name}} Management admin panel.",
      please_sign_in: "Please sign in below.",
      email: "Email",
      email_message: "Please input email",
      password: "Password",
      password_message: "Please input password",
      confirm_password_message: "Please confirm password",
      verify_code: "Enter verify code",
      verify_code_message: "Enter email code",
      verify_title:
        "You have received email with code. Please input the code from email here.",
      login: "Login",
      reset_password: "Reset Password",
      new_password: "Enter new password",
      confirm_password: "Confirm password",
      forgot_password: "Forgot password?",
      version: "Version: {{version}}",
      privacy_policy: "Privacy policy",
      content_provider: "You are a content provider",
      verify_message: "Please verify that you are not a robot",
      login_message: "Incorrect username or password",
      welcome_provider:
        "Login to content provider dashboard to review your movies statistics",
      login_as_admin: "Login as administrator",
      back: "Back",
      send: "Send",
      reset: "Reset",
      general: "General",
      live_tv: "Live TV",
      vod: "VOD",
      subscription: "Subscription",
      payments: "Payments",
      users: "Users",
      online: "Online",
      countries: "Countries",
      devices: "Devices",
      payment_count: "Payment count",
      online_users: "Online users",
      registered_user: "Registered users",
      device_platform: "Device platform",
      group_user: "Group users",
      views_per_channel: "Views per channel",
      views_duration_channel: "Views duration channel",
      favorite: "Favorite {{name}}",
      platform: "Platform",
      country: "Country",
      most_watched_movies: "Most Watched Movies",
      top_watched: "Top watched",
      view_per_movie: "Views per movie",
      like_dislike: "Like/Dislike {{name}}",
      by_country: " by Countries",
      duration_movie: "Duration movie",
      content_provider: "Content provider",
      add_provider: "Add provider",
      status: "Status",
      name: "Name",
      company_name: "Company name",
      contact_person: "Contact person",
      phone: "Phone",
      created_at: "Created data",
      percent: "Percent",
      stream_url: "Stream url",
      add_transcoding: "Add transcoding",
      import_scv: "Import from CSV",
      delete: "Delete",
      edit: "Edit",
      medias: "Medias",
      info: "Info",
      more_info: "More info",
      input: "Input",
      output: "Output",
      uptime: "Uptime",
      size: "Size",
      available: "Available",
      used: "Used",
      enable: "Enable",
      media_type: "Media type",
      license_end_date: "License end date",
      highlight: "Highlight",
      provider: "Provider",
      role: "Role",
      birthday: "Birthday",
      birth_in: "Birth in",
      deathday: "Deathday",
      position: "Position",
      icon: "Icon",
      visible: "Visible",
      play: "Play",
      device: "Device",
      categories: "Categories",
      archive: "Archive",
      age_restriction: "Age restriction",
      view: "View",
      catch_up: "Catch up",
      online: "Online",
      offline: "Offline",
      username: "Username",
      type: "Type",
      group: "Group",
      phone: "Phone",
      monetization: "Monetization",
      watched: "Watched",
      tariff: "Tariff",
      all_time: "All time",
      year: "Year",
      moth: "Month",
      season: "Season",
      episode: "Episode",
      subscription: "Subscriptions",
      movies: "Movies",
      tv_shows: "TV shows",
      now_watching: "Now watching",
      genres: "Genres",
      gender: "Gender",
      collection: "Collections",
      cast: "cast",
      add_cast: "Add cast",
      galleries: "Galleries",
      information: "Information",
      registered_users: "Registered users",
    },
  },
  br: {
    translation: {
      welcome: "Welcome to {{name}} Management admin panel.",
      please_sign_in: "Please sign in below.",
      email: "Email",
      email_message: "Please input email",
      password: "Password",
      password_message: "Please input password",
      confirm_password_message: "Please confirm password",
      verify_code: "Enter verify code",
      verify_code_message: "Enter email code",
      verify_title:
        "You have received email with code. Please input the code from email here.",
      login: "Login",
      reset_password: "Reset Password",
      new_password: "Enter new password",
      confirm_password: "Confirm password",
      forgot_password: "Forgot password?",
      version: "Version: {{version}}",
      privacy_policy: "Privacy policy",
      content_provider: "You are a content provider",
      verify_message: "Please verify that you are not a robot",
      login_message: "Incorrect username or password",
      welcome_provider:
        "Login to content provider dashboard to review your movies statistics",
      login_as_admin: "Login as administrator",
      back: "Back",
      send: "Send",
      reset: "Reset",
      general: "General",
      live_tv: "r TV",
      vod: "VOD",
      subscription: "Subscription",
      payments: "Payments",
      users: "Users",
      online: "Online",
      countries: "Countries",
      devices: "Devices",
      payment_count: "Payment count",
      online_users: "Online users",
      registered_user: "Registered users",
      device_platform: "Device platform",
      group_user: "Group users",
      views_per_channel: "Views per channel",
      views_duration_channel: "Views duration channel",
      favorite: "Favorite {{name}}",
      platform: "Platform",
      country: "Country",
      most_watched_movies: "Most Watched Movies",
      top_watched: "Top watched",
      view_per_movie: "Views per movie",
      like_dislike: "Like/Dislike {{name}}",
      by_country: " by Countries",
      duration_movie: "Duration movie",
      content_provider: "Content provider",
      add_provider: "Add provider",
      status: "Status",
      name: "Name",
      company_name: "Company name",
      contact_person: "Contact person",
      phone: "Phone",
      created_at: "Created data",
      percent: "Percent",
      stream_url: "Stream url",
      add_transcoding: "Add transcoding",
      import_scv: "Import from CSV",
      delete: "Delete",
      edit: "Edit",
      medias: "Medias",
      info: "Info",
      more_info: "More info",
      input: "Input",
      output: "Output",
      uptime: "Uptime",
      size: "Size",
      available: "Available",
      used: "Used",
      enable: "Enable",
      media_type: "Media type",
      license_end_date: "License end date",
      highlight: "Highlight",
      provider: "Provider",
      role: "Role",
      birthday: "Birthday",
      birth_in: "Birth in",
      deathday: "Deathday",
      position: "Position",
      icon: "Icon",
      visible: "Visible",
      play: "Play",
      device: "Device",
      categories: "Categories",
      archive: "Archive",
      age_restriction: "Age restriction",
      view: "View",
      catch_up: "Catch up",
      online: "Online",
      offline: "Offline",
      username: "Username",
      type: "Type",
      group: "Group",
      phone: "Phone",
      monetization: "Monetization",
      watched: "Watched",
      tariff: "Tariff",
      all_time: "Atm",
      year: "Year",
      moth: "Month",
      season: "Season",
      episode: "Episode",
      subscription: "Subscriptions",
      movies: "Movies",
      tv_shows: "TV shows",
      now_watching: "Now watching",
      genres: "Genres",
      gender: "Gender",
      collection: "Collections",
      cast: "cast",
      add_cast: "Add cast",
      galleries: "Galleries",
      information: "Information",
    },
  },
};
