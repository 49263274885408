import { useState } from "react";
import { notification } from "antd";

import REQUESTS from "../../../api/requests";

import TranscodersGroupsTable from "./TranscodersGroupsTable";
import TranscodersGroupDrawer from "./TranscodersGroupDrawer";

import PageComponent from "../../../_components/page/PageComponent";

export default function TranscodersGroupsPage() {
  const [getAgain, setGetAgain] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [editable, setEditable] = useState(null);

  const getDataSource = (query, onSuccess, onError) => {
    REQUESTS.TRANSCODERS.GROUPS.GET(query)
      .then((response) => {
        onSuccess(response.message);
      })
      .catch((error) => {
        onError(error);
      });
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "delete":
        REQUESTS.TRANSCODERS.GROUPS.DELETE({ id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      default:
        break;
    }
  };

  return (
    <PageComponent routes={["Transcoders", "Groups"]}>
      <TranscodersGroupsTable
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        openDrawer={() => setIsOpenDrawer(true)}
      />

      <TranscodersGroupDrawer
        open={isOpenDrawer}
        editable={editable}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
          setEditable(null);
        }}
      />
    </PageComponent>
  );
}
