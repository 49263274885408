import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { DatePicker, Form, Input, Radio, Select, notification } from "antd";

import REQUESTS from "../../../../api/requests";

import { useCountriesOptions } from "../../../../hooks/selectOptions";

import { returnGender } from "../../../../config/config";

import ImageUpload from "../../../../components/ImageUpload";
import ButtonComponent from "../../../../_components/ButtonComponent";

import CastFromTmdb from "./CastFromTmdb";
import styles from "../_cast.module.scss";

export default function InformationTab({ editable, isOpen, close }) {
    const countriesOptions = useCountriesOptions();

    const [image, setImage] = useState({
        url: null,
        file: null,
    });

    const [isLoading, setIsLoading] = useState(false);

    const [tmdbId, setTmdbId] = useState(null);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);

        const formData = new FormData();

        formData.append("name", values.name);
        formData.append("bio", values.bio);
        formData.append("role", values.role);
        formData.append("gender", values.gender);

        if (values.country) {
            formData.append("country", values.country);
        }

        if (values.birthday) {
            formData.append("birthday", values.birthday);
        }

        if (values.deathday) {
            formData.append("deathday", values.deathday);
        }

        if (tmdbId) {
            formData.append("tmdb_id", tmdbId);
        }

        if (image.file) {
            formData.append("image", image.file);
        }

        if (editable) {
            formData.append("id", editable.id);

            REQUESTS.VOD.CAST.EDIT(formData)
                .then((response) => {
                    setIsLoading(false);
                    if (response.error) {
                        notification.error({
                            description: response.message,
                        });
                        return;
                    }
                    close();
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        } else {
            REQUESTS.VOD.CAST.ADD(formData)
                .then((response) => {
                    setIsLoading(false);
                    if (response.error) {
                        notification.error({
                            message: "Error",
                            description: response.message,
                        });
                        return;
                    }

                    notification.success({
                        message: "Success",
                        description: response.message,
                    });

                    close();
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        }
    };

    const onSelectCastFromTmdb = (item) => {
        setTmdbId(item.id);

        const query = {
            id: item.id,
        };

        REQUESTS.VOD.CAST.TMDB.PERSON(query).then((response) => {
            if (response.error) {
                notification.error({
                    message: "Error",
                    description: response.message,
                });
                return;
            }

            const info = response.message;

            form.setFields([
                { name: "name", value: info.name },
                { name: "role", value: info.known_for_department },
                { name: "bio", value: info.biography },
                { name: "gender", value: returnGender(info.gender) },
            ]);

            if (info.place_of_birth) {
                form.setFieldsValue({
                    country: info.place_of_birth,
                });
            }

            if (info.birthday) {
                form.setFieldsValue({
                    birthday: dayjs(info.birthday),
                });
            }

            if (info.deathday) {
                form.setFieldsValue({
                    deathday: dayjs(info.deathday),
                });
            }

            setImage({
                url: info.profile_path
                    ? `https://image.tmdb.org/t/p/original/${info.profile_path}`
                    : null,
                file: null,
            });
        });
    };

    const onChangeCastFromTmdb = (event) => {
        setTmdbId(null);
        form.setFields([{ name: "name", value: event.target.value }]);
    };

    useEffect(() => {
        if (editable) {
            form.setFields([
                { name: "name", value: editable.name },
                { name: "role", value: editable.role },
                { name: "bio", value: editable.bio },
                { name: "birthday", value: editable.birthday },
            ]);

            if (editable.birthday) {
                form.setFieldsValue({
                    birthday: dayjs(editable.birthday),
                });
            }

            if (editable.deathday) {
                form.setFieldsValue({
                    deathday: dayjs(editable.deathday),
                });
            }

            if (editable.country) {
                form.setFieldsValue({
                    country: editable.country,
                });
            }

            if (editable.tmdb_id) {
                setTmdbId(editable.tmdb_id);
            }

            setImage({
                url: editable.image,
                file: null,
            });
        }
    }, [editable]);

    useEffect(() => {
        if (!isOpen) {
            form.resetFields();

            setImage({
                url: null,
                file: null,
            });

            setTmdbId(null);

            setIsLoading(false);
        }
    }, [isOpen]);

    return (
        <Form
            form={form}
            name="cast-form"
            layout="vertical"
            onFinish={onFinish}
            className={styles["cast-form"]}
            initialValues={{
                gender: "Male",
            }}
        >
            {!editable && isOpen && (
                <div className={styles["search-from-tmdb"]}>
                    <label>Search from TMDB </label>

                    <CastFromTmdb onSelect={onSelectCastFromTmdb} />
                </div>
            )}

            <div className={styles["container"]}>
                <div style={{ width: 170 }}>
                    <Form.Item label="Image" name="image">
                        <ImageUpload image={image} setImage={setImage} />
                    </Form.Item>

                    <Form.Item label="Gender" name="gender">
                        <Radio.Group name="gender" defaultValue={"Male"}>
                            <Radio value="Male">Male</Radio>
                            <Radio value="Female">Female</Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>

                <div style={{ width: 460 }}>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please input name",
                            },
                        ]}
                    >
                        <Input onChange={onChangeCastFromTmdb} />
                    </Form.Item>

                    <div className={styles["row"]}>
                        <Form.Item
                            label="Role"
                            name="role"
                            style={{ width: "50%" }}
                            rules={[
                                {
                                    required: true,
                                    message: "Please input role",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item label="Birth country" name="country" style={{ width: "50%" }}>
                            <Select
                                options={countriesOptions}
                                showSearch
                                showArrow={true}
                                filterOption={(input, option) =>
                                    (option?.label?.toLowerCase() ?? "").includes(input)
                                }
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "")
                                        .toLowerCase()
                                        .localeCompare((optionB?.label ?? "").toLowerCase())
                                }
                            />
                        </Form.Item>
                    </div>

                    <div className={styles["row"]}>
                        <Form.Item label="Date of birth" name="birthday" style={{ width: "50%" }}>
                            <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item label="Date of death" name="deathday" style={{ width: "50%" }}>
                            <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
                        </Form.Item>
                    </div>

                    <Form.Item label="Bio" name="bio">
                        <Input.TextArea rows={5} />
                    </Form.Item>
                </div>
            </div>

            <Form.Item style={{ textAlign: "center" }}>
                <ButtonComponent
                    title="Save"
                    actionType="save"
                    isLoading={isLoading}
                    onClick={() => form.submit()}
                />
            </Form.Item>
        </Form>
    );
}
