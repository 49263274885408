import { useEffect, useState } from "react";
import { Form, Input, notification } from "antd";

import REQUESTS from "../../../api/requests";

import PageComponent from "../../../_components/page/PageComponent";
import ButtonComponent from "../../../_components/ButtonComponent";

export default function StoreLinksPage({}) {
  const [isLoading, setIsLoading] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);
    setIsDisabled(true);

    const body = {
      app_store_url: values.app_store_url,
      play_market_url: values.play_market_url,
      amazon_fire: values.amazon_fire,
      foxxum_etc: values.foxxum_etc,
      huawei: values.huawei,
      tizen: values.tizen,
      roku: values.roku,
      webos: values.webos,
    };

    REQUESTS.STORE_LINKS.UPDATE(body)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message,
          });

          return;
        }

        notification.success({
          message: "Success",
          description: response.message,
        });

        getData();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getData = () => {
    setIsDisabled(true);
    REQUESTS.STORE_LINKS.GET().then((response) => {
      setIsDisabled(false);
      if (!response.error) {
        const {
          app_store_url,
          play_market_url,
          amazon_fire,
          foxxum_etc,
          huawei,
          tizen,
          roku,
          webos,
        } = response.message;

        form.setFields([
          { name: "app_store_url", value: app_store_url },
          { name: "play_market_url", value: play_market_url },
          { name: "amazon_fire", value: amazon_fire },
          { name: "foxxum_etc", value: foxxum_etc },
          { name: "huawei", value: huawei },
          { name: "tizen", value: tizen },
          { name: "roku", value: roku },
          { name: "webos", value: webos },
        ]);
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <PageComponent routes={["Settings", "Store Links"]}>
      <Form
        form={form}
        name="store-links"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //     if (e.key === "Enter") {
        //         form.submit();
        //     }
        // }}
        disabled={isDisabled}
      >
        <div className="grid-input ">
          <Form.Item label="APP Store URL" name="app_store_url">
            <Input />
          </Form.Item>

          <Form.Item label="Play Market Url" name="play_market_url">
            <Input />
          </Form.Item>

          <Form.Item label="Amazon fire" name="amazon_fire">
            <Input />
          </Form.Item>

          <Form.Item label="Foxxum ETC" name="foxxum_etc">
            <Input />
          </Form.Item>

          <Form.Item label="Huawei" name="huawei">
            <Input />
          </Form.Item>

          <Form.Item label="Tizen" name="tizen">
            <Input />
          </Form.Item>

          <Form.Item label="Roku" name="roku">
            <Input />
          </Form.Item>

          <Form.Item label="Webos" name="webos">
            <Input />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonComponent
            title="Save"
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </PageComponent>
  );
}
