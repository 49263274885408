import { Select } from "antd";
import { useEffect, useState } from "react";

import REQUESTS from "../../../../api/requests";

import styles from "../messages.module.scss";

export default function SelectDevice({ selected, onChange }) {
  const [options, setOptions] = useState([]);

  const getOptions = () => {
    REQUESTS.LAUNCHER.DEVICES.GET({ query: JSON.stringify({ limit: 1000 }) })
      .then((response) => {
        if (!response.error) {
          const list = response.message.rows.map((item) => ({
            label: item.device_model,
            value: item.id,
          }));

          setOptions(list);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <div className={styles["select-device"]}>
      <label>Select device</label>
      <Select
        style={{ width: 300 }}
        options={options}
        value={selected}
        onChange={onChange}
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toLocaleLowerCase() ?? "").includes(input?.toLocaleLowerCase())
        }
        r={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
      />
    </div>
  );
}
