import { Form, Input, Select } from "antd";

import ImageUpload from "../../../../components/ImageUpload";

import NextBackButton from "../../../../_components/button/NextBackButton";

export default function InformationStep({
  editable,
  setStep,
  poster,
  setPoster,
  backdrop,
  setBackdrop,
  categoriesOption,
}) {
  return (
    <div>
      <div style={{ display: "flex", gap: 10 }}>
        <Form.Item
          label="Poster"
          name="poster"
          rules={[
            {
              required: editable ? false : true,
              message: "Please select poster",
            },
          ]}
        >
          <ImageUpload image={poster} setImage={setPoster} />
        </Form.Item>

        <Form.Item
          label="Backdrop"
          name="backdrop"
          rules={[
            {
              required: false,
              message: "Please select backdrop",
            },
          ]}
        >
          <ImageUpload image={backdrop} setImage={setBackdrop} />
        </Form.Item>
      </div>

      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please input name",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Categories"
        name="categories"
        rules={[
          {
            required: false,
            message: "Please select categories",
          },
        ]}
      >
        <Select
          mode="multiple"
          options={categoriesOption}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return (option?.label?.toLowerCase() ?? "").includes(
              input.toLocaleLowerCase()
            );
          }}
          filterSort={(optionA, optionB) => {
            return (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase());
          }}
        />
      </Form.Item>

      <Form.Item label="Comment" name="comment">
        <Input.TextArea rows={4} />
      </Form.Item>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <NextBackButton type="next" onClick={() => setStep("2")} />
      </div>
    </div>
  );
}
