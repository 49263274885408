import { Button, Form, Input, InputNumber } from "antd";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

export default function Answers() {
    return (
        <Form.Item label="Answers" shouldUpdate>
            {() => {
                return (
                    <Form.List name="answers">
                        {(fields, { add, remove }) => {
                            return (
                                <>
                                    {fields.map(({ key, name, ...restField }) => {
                                        return (
                                            <div
                                                key={key}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "start",
                                                    justifyContent: "space-between",
                                                    gap: 10,
                                                }}
                                                align="baseline"
                                            >
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "content"]}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please input content",
                                                        },
                                                    ]}
                                                    style={{ width: "70%" }}
                                                >
                                                    <Input placeholder="Enter content" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "unit"]}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Unit",
                                                        },
                                                    ]}
                                                    style={{ width: "20%" }}
                                                >
                                                    <InputNumber min={0} placeholder="Unit" />
                                                </Form.Item>

                                                <div style={{ width: "10%" }} disabled={true}>
                                                    <Button
                                                        icon={<MinusCircleOutlined />}
                                                        onClick={() => remove(name)}
                                                        // disabled={key == 0 ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            block
                                            icon={<PlusOutlined />}
                                        >
                                            Add answer
                                        </Button>
                                    </Form.Item>
                                </>
                            );
                        }}
                    </Form.List>
                );
            }}
        </Form.Item>
    );
}
