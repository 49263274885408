import { useEffect, useState } from "react";

import Galery from "./Galery";

import GaleryUpload from "./GaleryUpload";

import styles from "../_cast.module.scss";

export default function GaleriesTab({ editable, getData }) {
    const [galeries, setGaleris] = useState([]);

    useEffect(() => {
        if (editable) {
            setGaleris(editable.galery);
        }
    }, [editable]);

    const addGalery = (newGalery) => {
        getData();
        setGaleris((prev) => [...prev, ...newGalery]);
    };

    const deleteGalery = (id) => {
        getData();

        const list = galeries.filter((item) => item.id !== id);

        setGaleris(list);
    };

    return (
        <div>
            {editable && <p>{editable.name} galleries</p>}
            <div className={styles["galeries"]}>
                <GaleryUpload castId={editable?.id} addGalery={addGalery} />

                {galeries.map((item) => {
                    return <Galery key={item.id} item={item} deleteGalery={deleteGalery} />;
                })}
            </div>
        </div>
    );
}
