import { Tabs } from "antd";

import { useEffect, useState } from "react";

import REQUESTS from "../../../api/requests";

import AndroidTvTab from "./tabs/AndroidTvTab";

import AndroidMobileTab from "./tabs/AndroidMobileTab";

import PageComponent from "../../../_components/page/PageComponent";

export default function ApksPage() {
    const [androidMobile, setAndroidMobile] = useState(null);
    const [androidTv, setAndroidTv] = useState(null);

    const getData = () => {
        REQUESTS.SETTINGS.APKS.GET()
            .then((response) => {
                if (response.error) {
                    return;
                }

                if (response.message) {
                    let list = response.message;

                    for (let i = 0; i < list.length; i++) {
                        switch (list[i].name) {
                            case "Android Mobile":
                                setAndroidMobile(list[i]);
                                break;

                            case "Android Tv":
                                setAndroidTv(list[i]);
                                break;

                            default:
                                break;
                        }
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const tabs = [
        {
            label: "Android Tv",
            key: "android_tv",
            children: <AndroidTvTab data={androidTv} />,
        },
        {
            label: "Android Mobile",
            key: "android_mobile",
            children: <AndroidMobileTab data={androidMobile} />,
        },
    ];

    return (
        <PageComponent routes={["Settings", "APKs"]}>
            <Tabs tabPosition="left" items={tabs} />
        </PageComponent>
    );
}
