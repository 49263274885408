import { useState } from "react";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import { Space, Popconfirm } from "antd";
import icons from "../../../config/icons";

const UserBulkAction = ({ handleAction }) => {
  const [showConfirm, setShowConfirm] = useState({
    showLock: false,
    showUnlock: false,
    showArchive: false,
    showRestore: false,
  });
  const handleClick = (type) => {
    handleAction(type);
  };

  return (
    <div>
      <Space>
        <>
          <ButtonComponent
            type="default"
            title={"Lock"}
            icon={icons.LOCK}
            onClick={() => {
              // handleClick("lock");
              setShowConfirm({
                ...showConfirm,
                showLock: true,
              });
            }}
          />
          <Popconfirm
            open={showConfirm.showLock}
            title="Are you sure you want to lock this user?"
            onConfirm={() => handleClick("lock")}
            onCancel={() =>
              setShowConfirm({
                ...showConfirm,
                showLock: false,
              })
            }
          />
        </>
        <>
          <ButtonComponent
            type="default"
            title={"Unlock"}
            icon={icons.UNLOCK}
            onClick={() => {
              setShowConfirm({
                ...showConfirm,
                showUnlock: true,
              });
            }}
          />
          <Popconfirm
            open={showConfirm.showUnlock}
            title="Are you sure you want to unlock this user?"
            onConfirm={() => handleClick("unlock")}
            onCancel={() =>
              setShowConfirm({
                ...showConfirm,
                showUnlock: false,
              })
            }
          />
        </>
        <>
          <ButtonComponent
            type="default"
            title={"Archive"}
            icon={icons.ARCHIVE_ICON}
            onClick={() => {
              setShowConfirm({
                ...showConfirm,
                showArchive: true,
              });
            }}
          />
          <Popconfirm
            open={showConfirm.showArchive}
            title="Are you sure you want to archive this user?"
            onConfirm={() => handleClick("archive")}
            onCancel={() =>
              setShowConfirm({
                ...showConfirm,
                showArchive: false,
              })
            }
          />
        </>

        <>
          <ButtonComponent
            type="default"
            title={"Restore"}
            icon={icons.RESTORE}
            onClick={() => {
              setShowConfirm({
                ...showConfirm,
                showRestore: true,
              });
            }}
          />
          <Popconfirm
            open={showConfirm.showRestore}
            title="Are you sure you want to restore this user?"
            onConfirm={() => handleClick("restore")}
            onCancel={() =>
              setShowConfirm({
                ...showConfirm,
                showRestore: false,
              })
            }
          />
        </>
      </Space>
    </div>
  );
};

export default UserBulkAction;
