import { useEffect, useState } from "react";

import { Drawer, Form, Input, InputNumber, notification, Select, Space } from "antd";

import { useStreamerServersOptions } from "../../../../hooks/selectOptions";

import REQUESTS from "../../../../api/requests";

import Resolutions from "./Resolutions";

import ButtonComponent from "../../../../_components/ButtonComponent";

export default function AddToStreamingDrawer({ onClose, open, channel }) {
  const [isLoading, setIsLoading] = useState(false);

  const serverOptions = useStreamerServersOptions();

  const [resolutions, setResolutions] = useState([]);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const body = {
      name: values.name,
      serverId: values.serverId,
      timeshiftTime: values.timeshiftTime,
      resolutions: resolutions,
    };

    REQUESTS.STREAMER.STREAMS.ADD(body)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            description: response.message,
          });

          return;
        }
        onClose();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setIsLoading(false);
    }

    if (channel) {
      form.setFields([
        { name: "name", value: channel.name },
        { name: "timeshiftTime", value: 0 },
      ]);

      if (channel.resolutions) {
        const _resolutions = channel.resolutions.map((item) => {
          return {
            id: item.id,
            name: item.name,
            input: item.info.output,
          };
        });

        setResolutions(_resolutions);
      }
    }
  }, [open]);

  return (
    <Drawer
      width={600}
      title="Add to streaming"
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Form
        form={form}
        name="add-to-streams"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //     if (e.key === "Enter") {
        //         form.submit();
        //     }
        // }}
        initialValues={{
          serverId: serverOptions[0]?.value,
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Space>
          <Form.Item
            label="Timeshift in minutes"
            name="timeshiftTime"
            rules={[
              {
                required: true,
                message: "Please input  time",
              },
            ]}
          >
            <InputNumber style={{ width: 150 }} controls={false} />
          </Form.Item>

          <Form.Item
            label="Server "
            name="serverId"
            rules={[
              {
                required: true,
                message: "Please select server",
              },
            ]}
          >
            <Select options={serverOptions} style={{ width: 390 }} />
          </Form.Item>
        </Space>

        {/* {open && ( */}
        <Resolutions resolutions={resolutions} setResolutions={setResolutions} />
        {/* )} */}

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title="Save"
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
