import { useState } from "react";

import { notification } from "antd";

import REQUESTS from "../../../api/requests";

import FeatureDrawer from "./FeatureDrawer";
import FeatureTable from "./FeatureTable";
import PageComponent from "../../../_components/page/PageComponent";

export default function FeaturesPage({}) {
    const [current, setCurrent] = useState(null);

    const [getAgain, setGetAgain] = useState(false);

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);

    const getDataSource = (query, onSuccess, onError) => {
        REQUESTS.WEB_PAGE.FEATURES.GET(query)
            .then((response) => {
                onSuccess(response.message);
            })
            .catch((error) => {
                onError(error);
            });
    };

    const handleMenuClick = (e, item) => {
        switch (e.key) {
            case "edit":
                setCurrent(item);
                setIsOpenDrawer(true);
                break;

            case "delete":
                REQUESTS.WEB_PAGE.FEATURES.DELETE({ id: item.id })
                    .then((response) => {
                        if (response.error) {
                            notification.error({
                                message: "Error",
                                description: response.message,
                            });

                            return;
                        }

                        notification.success({
                            message: "Success",
                            description: "Feature deleted successfully",
                        });

                        setGetAgain((prev) => !prev);
                    })
                    .catch((err) => {
                        notification.error({
                            message: "Error",
                            description: err,
                        });
                    });
                break;

            default:
                break;
        }
    };

    const closeDrawer = () => {
        setCurrent(null);
        setIsOpenDrawer(false);
    };

    return (
        <PageComponent routes={["Web Page", "Feature"]}>
            <FeatureTable
                getAgain={getAgain}
                getDataSource={getDataSource}
                handleMenuClick={handleMenuClick}
                openDrawer={() => setIsOpenDrawer(true)}
            />

            <FeatureDrawer
                open={isOpenDrawer}
                onClose={closeDrawer}
                getData={() => setGetAgain((prev) => !prev)}
                current={current}
            />
        </PageComponent>
    );
}
