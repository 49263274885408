import { useSelector } from "react-redux";

import Card from "./Card";

import styles from "./_import.module.scss";

export default function Statistics({ data }) {
    const { permissions } = useSelector((state) => state.globalState);

    return (
        <div className={styles["statistics"]}>
            {permissions?.LIVE_TV && (
                <Card title="Live Tv" total={data?.channels?.total} done={data?.channels?.done} />
            )}

            {permissions?.Vod_Manager && (
                <>
                    <Card title="Movies" total={data?.movies?.total} done={data?.movies?.done} />

                    <Card title="Series" total={data?.series?.total} done={data?.series?.done} />
                </>
            )}
        </div>
    );
}
