import { Tooltip, Image } from "antd";

import { useEffect, useState } from "react";

import TableComponent from "../../../../../../_components/table/TableComponent";

export default function MostWatchedChannelsTable({ getAgain, getDataSource }) {
    const [dataSource, setDataSource] = useState([]);

    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: "#",
            width: 60,
            dataIndex: `id`,
            align: "center",
            key: "index",
            render: (record, text, index) => {
                return index + 1;
            },
        },

        {
            title: "Image",
            dataIndex: "image",
            key: "image",
            align: "center",

            render: (text, record, index) => {
                return (
                    <Image
                        src={record.channel?.image}
                        style={{ width: 30, height: 30, borderRadius: "50%", objectFit: "cover" }}
                    />
                );
            },
        },

        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "center",
            render: (text, record, index) => {
                return record.channel?.name;
            },
        },

        {
            title: "Views",
            dataIndex: "views_count",
            key: "views_count",
            align: "center",
            render: (text, record, index) => {
                return record.views_count;
            },
        },
    ];

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(true);

            function onSuccess(data) {
                setLoading(false);
                setDataSource(data);
            }

            function onError(data) {
                setLoading(false);
            }

            getDataSource(onSuccess, onError);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [getAgain]);

    return <TableComponent columns={columns} isLoading={loading} dataSource={dataSource} />;
}
