import { useState, useEffect } from "react";

import { Button, Form, InputNumber, notification, Space } from "antd";

import REQUESTS from "../../../api/requests";

import style from "./style.module.scss";

export default function ScheduledRestartTab({ data }) {
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);

        const body = {
            seconds: +values.seconds,
        };

        REQUESTS.SETTINGS.GENERAL.SCHEDULED_RESTART(body)
            .then((response) => {
                setIsLoading(false);

                if (response.error) {
                    return;
                }

                notification.success({
                    description: "Scheduled time es changed",
                });
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    const onBlurSeconds = () => {
        const { seconds } = form.getFieldsValue();

        if (!seconds) {
            form.setFields([
                {
                    name: "seconds",
                    value: 1,
                },
            ]);
        }
    };

    useEffect(() => {
        if (data) {
            form.setFields([
                {
                    name: "seconds",
                    value: data?.value,
                },
            ]);
        }
    }, [data]);

    return (
        <div className={style["tab"]}>
            <p className={style["title"]}>{data?.title}</p>

            <p className={style["description"]}>{data?.description}</p>

            <Form form={form} name="geolocation" layout="vertical" onFinish={onFinish}>
                <Space>
                    <Form.Item>
                        <Form.Item noStyle name="seconds" shouldUpdate>
                            <InputNumber min={1} onBlur={onBlurSeconds} />
                        </Form.Item>
                    </Form.Item>

                    <Form.Item
                        style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
                    >
                        <Button type="primary" onClick={() => form.submit()} loading={isLoading}>
                            Save
                        </Button>
                    </Form.Item>
                </Space>
            </Form>
        </div>
    );
}
