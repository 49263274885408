import { useEffect, useState } from "react";

import REQUESTS from "../../../api/requests";

import IconUpload from "./IconUpload";

import IconComponent from "./IconComponent";

import PageComponent from "../../../_components/page/PageComponent";

import styles from "./_user-icons.module.scss";

export default function UserIconsPage() {
    const [icons, setIcons] = useState([]);

    const getUserIcons = () => {
        REQUESTS.APPLICATION.USER_ICONS.GET().then((response) => {
            setIcons(response.message);
        });
    };

    useEffect(() => {
        getUserIcons();
    }, []);

    return (
        <PageComponent routes={["Application", "User Icons"]}>
            <div className={styles.list}>
                <IconUpload getData={getUserIcons} />

                {icons.map((item) => {
                    return <IconComponent key={item.id} item={item} getData={getUserIcons} />;
                })}
            </div>
        </PageComponent>
    );
}
