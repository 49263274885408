import { Divider, Form, Input, Radio, Select } from "antd";

import Container from "../../../../../_components/container/Container";

import { useProvidersOptions } from "../../../../../hooks/selectOptions";

export default function Details({ editableMovieId, type }) {
  const providersOptions = useProvidersOptions();

  return (
    <Container>
      <Divider orientation="left" plain>
        <b>Details</b>
      </Divider>

      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please input name",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Input.TextArea rows={5} style={{ resize: "none" }} />
      </Form.Item>

      <Form.Item label="Provider" name="content_provider_id">
        <Select options={providersOptions} />
      </Form.Item>
    </Container>
  );
}
