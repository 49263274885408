import { useEffect, useState } from "react";
import { Popconfirm, Typography } from "antd";
import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import { getAtSession, setInSession, getAtLocal } from "../../../helpers/storages";
import TableButtons from "../../../_components/table/TableButtons";
import TableImage from "../../../_components/table/TableImage";
import icons from "../../../config/icons";
import PositionComponent from "../../../_components/PositionComponent";

const MenuConfigTable = ({
  getMenuData,
  getAgain,
  setShowDrawer,
  setEditable,
  onEnable,
  onChangePosition,
  onDelete,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(getAtLocal("pageLimit_menu_config") || 10);
  const [currentPage, setCurrentPage] = useState(getAtSession("menu_configs")?.page || 1);
  const [sort, setSort] = useState(getAtSession("menu_configs")?.sort || ["id", "DESC"]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState(
    getAtSession("menu_configs")?.search || { name: null, categories: null }
  );
  const [isEmpty, setIsEmpty] = useState(false);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record) => <Typography>{record.id}</Typography>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text, record) => <Typography>{record.name}</Typography>,
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      align: "center",

      render: (text, record) => {
        return (
          <PositionComponent
            position={record.position}
            onChangePosition={(e) => onChangePosition(e, record.id)}
          />
        );
      },
    },
    {
      title: "Icon",
      dataIndex: "icon",
      key: "icon",
      align: "center",
      render: (text, record) => (record.icon ? <TableImage src={record?.icon} /> : null),
    },
    {
      title: "Selected Icon",
      dataIndex: "selectedIcon",
      key: "selectedIcon",
      align: "center",
      render: (text, record) =>
        record.selectedIcon ? <TableImage src={record?.selectedIcon} /> : null,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (text, record) => <Typography>{record.type}</Typography>,
    },
    {
      title: "Devices",
      dataIndex: "devices",
      key: "devices",
      width: 100,
      ellipsis: true,
      align: "center",
      render: (text, record) => <Typography>{record.devices}</Typography>,
    },
    {
      title: "Is Enabled",
      dataIndex: "is_enabled",
      key: "is_enabled",
      align: "center",
      render: (text, record) => {
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {record.is_enabled ? (
              <ButtonComponent
                type="text"
                onClick={() => {
                  const type = {
                    value: record.id,
                    type: false,
                  };

                  onEnable(type);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                icon={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: '<i class="fa fa-eye"></i>',
                    }}
                  />
                }
              />
            ) : (
              <ButtonComponent
                type="text"
                danger={true}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  const type = {
                    value: record.id,
                    type: true,
                  };
                  onEnable(type);
                }}
                icon={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: '<i class="fa fa-eye-slash"></i>',
                    }}
                  />
                }
              />
            )}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e.key, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: icons.EDIT,
            },
            {
              key: "delete",
              name: "Delete",
              icon: icons.DELETE,
              question: "Are you sure you want to delete this menu config?",
              onConfirm: (e) => handleMenuClick(e.key, record),
            },
          ]}
        />
      ),
    },
  ];

  const handleMenuClick = (key, item) => {
    switch (key) {
      case "edit":
        setEditable(item);
        setShowDrawer(true);
        break;

      case "delete":
        onDelete(item.id);
        break;

      default:
        return null;
    }
  };

  useEffect(() => {
    const getMenuConfigs = () => {
      setLoading(true);

      const query = {
        sort,
        limit,
        page: currentPage,
      };

      if (search.name) {
        query.search = {
          name: search.name[0],
        };
      }

      if (search.categories) {
        query.filter = {
          categoryId: search.categories,
        };
      }

      function onSuccess(data) {
        setLoading(false);

        if (data?.message?.rows?.length > 0) {
          setDataSource(data.message.rows);
          setIsEmpty(false);
        } else {
          setIsEmpty(true);
        }

        setTotal(data.message.count);

        const maxPage = Math.ceil(data.message.count / limit);
        const storedPage = getAtSession("menu_configs")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("menu_configs", {
            ...getAtSession("menu_configs"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("menu_configs", {
            ...getAtSession("menu_configs"),
            page: currentPage,
          });
        }
      }

      function onError(data) {
        setLoading(false);
      }

      getMenuData(query, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (
        getAtSession("menu_configs") &&
        getAtSession("menu_configs")?.page > currentPage
      ) {
        setInSession("menu_configs", {
          ...getAtSession("menu_configs"),
          page: 1,
        });
        setCurrentPage(getAtSession("menu_configs")?.page);
      }

      getMenuConfigs();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, sort, search, currentPage, getAgain]);

  return (
    <div>
      <TableComponent
        header={
          <div>
            <ButtonComponent
              title="Add"
              icon={icons.ADD}
              onClick={() => setShowDrawer(true)}
            />
          </div>
        }
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize: limit,
          total: total,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setLimit(pageSize);
          },
        }}
      />
    </div>
  );
};

export default MenuConfigTable;
