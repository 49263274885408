import { useState, useEffect } from "react";

import { Form, Button, notification } from "antd";

import REQUESTS from "../../../../api/requests";

import ImageUpload from "../../../../components/ImageUpload";

import styles from "../_basic-info.module.scss";

export default function ImagesTab({ data, getData }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [logo, setLogo] = useState({ url: null, file: null });
  const [mobileLogo, setMobileLogo] = useState({ url: null, file: null });
  const [startLogo, setStartLogo] = useState({ url: null, file: null });
  const [backgroundImage, setBackgroundImage] = useState({ url: null, file: null });

  const onFinish = () => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("id", data.id);

    if (logo.file) {
      formData.append("logo", logo.file);
    } else if (logo.url === null) {
      formData.append("logo", null);
    }
    if (mobileLogo.file) {
      formData.append("mobile_logo", mobileLogo.file);
    } else if (mobileLogo.url === null) {
      formData.append("mobile_logo", null);
    }
    if (startLogo.file) {
      formData.append("start_logo", startLogo.file);
    } else if (startLogo.url === null) {
      formData.append("start_logo", null);
    }

    if (backgroundImage.file) {
      formData.append("background_image", backgroundImage.file);
    } else if (backgroundImage.url === null) {
      formData.append("background_image", null);
    }

    REQUESTS.APPLICATION.BASIC_INFO.EDIT(formData)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message,
          });
          return;
        }

        notification.success({
          message: "Success",
          description: "Application information updated successfully",
        });
        getData(true);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    form.resetFields();
    if (data) {
      setLogo({ url: data.logo, file: null });
      setMobileLogo({ url: data.mobile_logo, file: null });
      setStartLogo({ url: data.start_logo, file: null });
      setBackgroundImage({ url: data.background_image, file: null });
    }
  }, [data]);

  useEffect(() => {
    form.setFieldsValue({
      logo: logo.url,
      mobile_logo: mobileLogo.url,
      start_logo: startLogo.url,
      background_image: backgroundImage.url,
    });
  }, [logo, mobileLogo, startLogo, backgroundImage]);

  return (
    <div className={styles["container"]}>
      <Form form={form} name="app-images" layout="vertical" onFinish={onFinish}>
        <div className={styles["images-list"]}>
          <Form.Item label="Logo" name="logo">
            <ImageUpload image={logo} setImage={setLogo} />
            {logo.url && (
              <div
                className="remove-image"
                onClick={() => setLogo({ url: null, file: null })}
              >
                <i className="fa fa-trash" />
              </div>
            )}
          </Form.Item>

          <Form.Item label="Mobile logo" name="mobile_logo">
            <ImageUpload image={mobileLogo} setImage={setMobileLogo} />
            {mobileLogo.url && (
              <div
                className="remove-image"
                onClick={() => setMobileLogo({ url: null, file: null })}
              >
                <i className="fa fa-trash" />
              </div>
            )}
          </Form.Item>

          <Form.Item label="Start logo" name="start_logo">
            <ImageUpload image={startLogo} setImage={setStartLogo} />
            {startLogo.url && (
              <div
                className="remove-image"
                onClick={() => setStartLogo({ url: null, file: null })}
              >
                <i className="fa fa-trash" />
              </div>
            )}
          </Form.Item>

          <Form.Item label="Background image" name="background_image">
            <ImageUpload image={backgroundImage} setImage={setBackgroundImage} />
            {backgroundImage.url && (
              <div
                className="remove-image"
                onClick={() =>
                  setBackgroundImage({ ...backgroundImage, url: null, file: null })
                }
              >
                <i className="fa fa-trash" />
              </div>
            )}
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={isLoading}
            style={{ width: 200 }}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
