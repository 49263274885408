import { notification } from "antd";
import { useEffect, useState } from "react";

import REQUESTS from "../../../../../../api/requests";
import { getToken } from "../../../../../../server/requests";

import Document from "./Document";
import DocumentUploader from "./DocumentUploader";

export default function DocumentsTab({ user, getUser }) {
    const [documents, setDocuments] = useState([]);

    const deleteDocument = (index) => {
        const body = {
            id: user.id,
            index: index,
            token: getToken(),
        };

        REQUESTS.USERS.USER.DOCUMENT.DELETE(body).then((response) => {
            if (response.error) {
                notification.error({
                    message: "Error",
                    description: response.message,
                });
                return;
            }

            notification.success({
                message: "Success",
                message: response.message.message,
            });
            getUser();
        });
    };

    useEffect(() => {
        if (user.document) {
            setDocuments(user.document);
        }
    }, [user]);

    return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: 20 }}>
            <DocumentUploader id={user?.id} getUser={getUser} />

            {documents.map((item, index) => (
                <Document key={item.id} item={item} onConfirm={() => deleteDocument(index)} />
            ))}
        </div>
    );
}
