import { Typography, Tooltip } from "antd";

import { useEffect, useState } from "react";

import icons from "../../../../../../config/icons";

import TableButtons from "../../../../../../_components/table/TableButtons";
import TableComponent from "../../../../../../_components/table/TableComponent";

export default function PlaylistTable({ current, getAgain, getDataSource, handleMenuClick }) {
    const [dataSource, setDataSource] = useState([]);

    const [loading, setLoading] = useState(false);

    const [limit, setLimit] = useState(10);

    const [total, setTotal] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);

    const columns = [
        {
            title: "#",
            width: 60,
            dataIndex: `id`,
            align: "center",
            key: "index",
            render: (record, text, index) => {
                return index + 1;
            },
        },

        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "center",
            width: 300,

            ellipsis: true,

            render: (text, record, index) => {
                return (
                    <Tooltip placement="topLeft" title={text}>
                        {text}
                    </Tooltip>
                );
            },
        },

        {
            title: "Url",
            dataIndex: "url",
            key: "url",
            align: "center",
            render: (text, record, index) => {
                return (
                    <Typography.Paragraph
                        ellipsis={true}
                        style={{ margin: 0, width: 200 }}
                        copyable={{
                            tooltips: false,
                        }}
                    >
                        {record.url}
                    </Typography.Paragraph>
                );
            },
        },

        {
            title: "",
            key: "operation",
            fixed: "right",
            width: 60,
            align: "center",

            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    items={[
                        {
                            key: "edit",
                            name: "Edit",
                            icon: icons.EDIT,
                        },

                        {
                            key: "delete",
                            name: "Delete",
                            icon: icons.DELETE,
                            question: `Do you want to delete this playlist`,
                            onConfirm: (e) => {
                                handleMenuClick(e, record);
                            },
                        },
                    ]}
                />
            ),
        },
    ];

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(true);

            function onSuccess(data) {
                setLoading(false);
                setDataSource(data.rows);
            }

            function onError(data) {
                setLoading(false);
            }

            const query = { id: current?.id, page: currentPage };

            getDataSource(query, onSuccess, onError);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [getAgain]);

    return (
        <TableComponent
            isLoading={loading}
            dataSource={dataSource}
            columns={columns}
            pagination={{
                page: currentPage,
                limit: limit,
                total: total,
            }}
        />
    );
}
