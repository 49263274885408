import { useEffect, useState } from "react";

import { Form, Input, Space } from "antd";

import PathDrawer from "./PathDrawer";

import icons from "../../../../config/icons";

import REQUESTS from "../../../../api/requests";

import ButtonComponent from "../../../../_components/button/ButtonComponent";

import styles from "../_transcoding.module.scss";

export default function OutputField({ form }) {
  const [streamPath, setStreamPath] = useState(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const onInput = (value) => {
    form.setFields([{ name: "output", value: value }]);

    setIsOpenDrawer(false);
  };

  const getStreamPath = () => {
    REQUESTS.VOD.TRANSCODING.STREAM_PATH().then((response) => {
      setStreamPath(response.stream_path);
    });
  };

  useEffect(() => {
    getStreamPath();
  }, []);

  return (
    <>
      <Space>
        <Form.Item
          name="output"
          label={
            <div>
              Output <span className={styles["stream-path"]}>{streamPath}</span>
            </div>
          }
          rules={[
            {
              required: true,
              message: "Please input output",
            },
          ]}
        >
          <Input style={{ width: 510 }} readOnly />
        </Form.Item>

        <ButtonComponent
          style={{
            marginTop: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          type="dashed"
          icon={icons.FOLDER}
          onClick={() => setIsOpenDrawer(true)}
        />
      </Space>

      <PathDrawer
        type="output"
        streamPath={streamPath}
        open={isOpenDrawer}
        onSelect={onInput}
        onClose={() => setIsOpenDrawer(false)}
      />
    </>
  );
}
