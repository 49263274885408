import React, { useState, useEffect, useCallback, useMemo, useContext } from "react";
import { createPortal } from "react-dom";
import { Drawer, Form, Input, Divider, Button, Select, notification } from "antd";
import { useLanguagesOptions } from "../../../../../../hooks/selectOptions";
import TextareaComponent from "../../../../../../components/TextareaComponent";
import ImageUpload from "../../../../../../components/ImageUpload";
import REQUESTS from "../../../../../../api/requests";
import TranscodingSearch from "../../../../../../_components/searches/TranscodingSearch";
import ButtonComponent from "../../../../../../_components/button/ButtonComponent";
import icons from "../../../../../../config/icons";
import Player from "../../../../../../routes/vod/medias/Player";

const AddEpisodeDrawer = ({
  show,
  close,
  isEditMode,
  initialValues,
  selectedEpisodeId,
  addOrUpdateEpisode,
  seasonId,
  addEpisodeTranslation,
  getTranslation,
  translations,
  validTranscodingId,
}) => {
  const [form] = Form.useForm();
  const [translationForm] = Form.useForm();

  const [streamType, setStreamType] = useState("");
  const [imgFile, setImgFile] = useState({ file: null, url: null });
  const [transcodingId, setTranscodingId] = useState(null);
  const [streamData, setStreamData] = useState({ id: null, url: null });
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [translationAddLoading, setTranslationAddLoading] = useState(false);
  const [urlValidation, setUrlValidation] = useState(false);
  const [translationLanguage, setTranslationLanguage] = useState();
  const [introStart, setIntroStart] = useState(0);
  const [introEnd, setIntroEnd] = useState(0);
  const [credit, setCredit] = useState(0);

  const languagesOptions = useLanguagesOptions();

  const handleSubmit = useCallback(
    async (values) => {
      const type = !isEditMode ? "post" : "put";
      let formData = new FormData();

      if (imgFile.file) {
        formData.append("poster", imgFile.file);
      }

      formData.append("name", values.name);
      if (values.description) {
        formData.append("description", values.description);
      }
      if (values.intro_start_time) {
        formData.append("intro_start_time", values.intro_start_time);
      }
      if (values.credit_time_ms) {
        formData.append("credits_time", values.credit_time_ms || 0);
      }

      if (values.intro_end_time_ms) {
        formData.append("intro_end_time", values.intro_end_time_ms || 0);
      }

      formData.append("duration", values.duration_ms || 0);
      formData.append("number", values.number);
      formData.append("stream_type", values.stream_type);

      if (values.stream_type === "internal") {
        formData.append("transcoding_id", streamData.id);
      } else {
        formData.append("stream_url", values.stream_url);
      }

      if (type === "put") formData.append("id", selectedEpisodeId);
      if (type === "post") formData.append("seasonId", seasonId);
      if (values.release_date) formData.append("release_date", values.release_date);

      const cb = (data) => {
        form.resetFields();
        setStreamData({ id: null, url: null });
        setImgFile({ file: null, url: null });
        close();
      };

      addOrUpdateEpisode(seasonId, type, formData, cb);
    },
    [
      imgFile,
      streamData,
      isEditMode,
      selectedEpisodeId,
      seasonId,
      addOrUpdateEpisode,
      form,
      close,
    ]
  );

  const handleStreamTypeChange = useCallback((value) => {
    setStreamType(value);
  }, []);

  const StreamTypeOption = useMemo(
    () => [
      { name: "internal", value: "internal" },
      { name: "external", value: "external" },
    ],
    []
  );

  const handleShowPlayerPopup = useCallback(() => {
    setOpenVideoModal(true);
  }, []);

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const pattern = /^(https?):\/\/[^\s/$.?#].[^\s]*$/;
      const isValid = pattern.test(value);
      setUrlValidation(isValid);

      if (isValid && streamType === "external") {
        setStreamData((prevState) => ({ ...prevState, url: value }));
      }
    },

    [streamType]
  );

  const getStreamOnTranscoding = useCallback(
    async (id) => {
      try {
        const response = await REQUESTS.VOD.TRANSCODING.GET({
          query: JSON.stringify({ filter: { id } }),
        });
        const transcoding = response?.rows?.[0];
        console.log(initialValues);
        if (!transcoding?.id && streamData?.id) {
          validTranscodingId(false);
        }

        form.setFieldsValue({ transcoding_id: transcoding?.id });
        setStreamData({ id: transcoding?.id, url: transcoding?.stream_url });
      } catch (error) {
        console.error("Error fetching transcoding:", error);
      }
    },
    [form]
  );

  const handleSaveSkipOption = useCallback(
    (option) => {
      form.setFieldsValue({
        credit_time_ms: option?.credits,
        intro_end_time_ms: option?.end,
        intro_start_time: option?.start,
        duration_ms: option?.duration,
      });
    },
    [form]
  );

  const submitTranslation = useCallback(
    (values) => {
      const body = {
        id: selectedEpisodeId,
        languageId: translationLanguage,
        model: "episode",
        name: values.translation_name,
        description: values.translation_description,
      };
      setTranslationAddLoading(true);

      const success = (data) => {
        setTranslationAddLoading(false);
        notification.success({
          success: "success",
          message: "Translation added successfully",
        });
      };

      const error = (error) => {
        console.log(error);
        notification.error({
          error: "error",
          message: "Something wrong",
        });
        setTranslationAddLoading(false);
      };

      addEpisodeTranslation(body, success, error);
    },
    [selectedEpisodeId, translationLanguage, addEpisodeTranslation]
  );

  useEffect(() => {
    if (isEditMode) {
      const languageId = translationForm.getFieldValue("languageId");

      if (!languageId) {
        const eng = languagesOptions.find((item) => item.iso_code === "en");

        translationForm.setFieldsValue({ languageId: eng?.id });
        setTranslationLanguage(eng?.id);
      }

      const newSeasonTranslation = translations?.find(
        (item) => item.id === translationLanguage
      );

      if (newSeasonTranslation?.episode_translation) {
        translationForm.setFields([
          {
            name: "translation_name",
            value: newSeasonTranslation.episode_translation.name || "",
          },
          {
            name: "translation_description",
            value: newSeasonTranslation.episode_translation.description || "",
          },
        ]);
      } else {
        translationForm.setFields([
          { name: "translation_name", value: "" },
          { name: "translation_description", value: "" },
        ]);
      }
    }
  }, [translations, translationLanguage, languagesOptions, translationForm, isEditMode]);

  useEffect(() => {
    if (isEditMode) {
      const value = initialValues.find((value) => value.id === selectedEpisodeId);

      setIntroStart(value?.intro_start_time);
      setIntroEnd(value?.intro_end_time);
      setCredit(value?.credits_time);

      if (value) {
        form.setFieldsValue({
          name: value?.name,
          number: value?.number,
          stream_type: value?.stream_type,
          duration_ms: value?.duration,
          intro_start_time: value?.intro_start_time,
          intro_end_time_ms: value?.intro_end_time,
          credit_time_ms: value?.credits_time,
          description: value?.description,
          translation_language: "en",
          poster: value?.poster,
          stream_url: value.stream_url,
        });

        if (value.poster) {
          setImgFile({ file: null, url: value.poster });
        }
      }

      setStreamType(value?.stream_type);

      if (value?.stream_type === "internal") {
        getStreamOnTranscoding(value.transcoding_id);
      } else {
        setStreamData((prevState) => ({ ...prevState, url: value?.stream_url }));
      }
      getTranslation(selectedEpisodeId, "episode");
    }
  }, [isEditMode, show, form, getStreamOnTranscoding]);

  useEffect(() => {
    if (transcodingId) {
      getStreamOnTranscoding(transcodingId);
    }
  }, [transcodingId, getStreamOnTranscoding]);

  return (
    <Drawer
      title={isEditMode ? "Edit Episode" : "Add Episode"}
      open={show}
      destroyOnClose
      onClose={() => {
        close();
        form.resetFields();
        setStreamData({ id: null, url: null });
      }}
      width={600}
      footer={
        <div
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <Button onClick={close}>Cancel</Button>
          <Button type="primary" onClick={() => form.submit()}>
            {isEditMode ? "Save" : "Add"}
          </Button>
        </div>
      }
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          name="poster"
          label="Poster"
          rules={[{ required: !imgFile && true, message: "Poster is required" }]}
          style={{ width: 150 }}
        >
          <ImageUpload image={imgFile} setImage={setImgFile} label={"Select Poster"} />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please enter the name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="number"
          label="Number"
          rules={[{ required: true, message: "Please enter the number" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="stream_type"
          label="Stream Type"
          rules={[{ required: true, message: "Stream type is required" }]}
        >
          <Select options={StreamTypeOption} onChange={handleStreamTypeChange} />
        </Form.Item>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            alignItems: "center",
          }}
        >
          {streamType === "internal" ? (
            <Form.Item
              label="Stream url"
              name="transcoding_id"
              onChange={handleChange}
              shouldUpdate
              style={{ width: "100%" }}
              rules={[{ required: true, message: "Please input a url" }]}
            >
              <TranscodingSearch value={transcodingId} onChange={setTranscodingId} />
            </Form.Item>
          ) : (
            <Form.Item
              label="Stream url"
              name="stream_url"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject(new Error("Please input a URL"));
                    }
                    if (!urlValidation && value && !isEditMode) {
                      return Promise.reject(new Error("Please enter valid URL"));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input onChange={handleChange} />
            </Form.Item>
          )}

          <Button
            className="media-play-button"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid transparent",
              backgroundColor: "transparent",
            }}
            disabled={
              (streamType === "external" &&
                !form.getFieldValue("stream_url") &&
                !urlValidation) ||
              !streamData?.url
            }
            icon={<i className="fas fa-play-circle" />}
            onClick={handleShowPlayerPopup}
          />
        </div>
        <div style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
          <Form.Item
            name="duration_ms"
            label="Duration (ms)"
            rules={[{ required: true, message: "Please enter the duration" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="intro_start_time" label="Intro start (s)">
            <Input />
          </Form.Item>
          <Form.Item name="intro_end_time_ms" label="Intro End Time(s)">
            <Input />
          </Form.Item>
          <Form.Item name="credit_time_ms" label="Credits time(s)">
            <Input />
          </Form.Item>
        </div>
        <Form.Item name="description" label="Description">
          <TextareaComponent />
        </Form.Item>
        {isEditMode && (
          <Form form={translationForm} layout="vertical" onFinish={submitTranslation}>
            <Divider orientation="left">Translations</Divider>

            <Form.Item name="languageId" label="Language">
              <Select
                showSearch
                options={languagesOptions}
                onChange={(e) => setTranslationLanguage(e)}
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input?.toLocaleLowerCase()
                  )
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item name="translation_name" label="Name">
              <Input />
            </Form.Item>
            <Form.Item name="translation_description" label="Description">
              <TextareaComponent />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ButtonComponent
                title={"Save translation"}
                icon={icons.CHECK}
                isLoading={translationAddLoading}
                onClick={() => translationForm.submit()}
              />
            </div>
          </Form>
        )}
      </Form>

      {createPortal(
        <Player
          type="hls"
          isVisible={openVideoModal}
          src={streamData?.url}
          hideModal={() => setOpenVideoModal(false)}
          saveSkeepOption={handleSaveSkipOption}
          introStartTime={introStart}
          introEndTime={introEnd}
          creditsTime={credit}
          useToken={streamType === "internal"}
        />,
        document.body
      )}
    </Drawer>
  );
};

export default React.memo(AddEpisodeDrawer);
