import { Tooltip } from "antd";
import { useEffect, useState } from "react";

import icons from "../../../../../config/icons";
import { parseDate } from "../../../../../config/config";

import TableButtons from "../../../../../_components/table/TableButtons";
import TableComponent from "../../../../../_components/table/TableComponent";
import ButtonComponent from "../../../../../_components/button/ButtonComponent";

export default function ScriptsTable({
  getAgain,
  openDrawer,
  getDataSource,
  handleMenuClick,
}) {
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      render: (record, text, index) => {
        return index + 1;
      },
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },

    {
      title: "Script",
      dataIndex: "script",
      key: "script",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },

    {
      title: `Created date`,
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",

      render: (text, record, index) => {
        return parseDate(text);
      },
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: icons.EDIT,
            },

            {
              key: "delete",
              name: "Delete",
              icon: icons.DELETE,
              question: `Do you want to delete this script`,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);

      const query = {
        sort: ["id", "DESC"],
        limit: 1000,
      };

      function onSuccess(data) {
        setLoading(false);
        setDataSource(data.rows);
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [getAgain]);

  return (
    <TableComponent
      header={
        <>
          <ButtonComponent icon={icons.ADD} title="Add Script" onClick={openDrawer} />
        </>
      }
      isLoading={loading}
      dataSource={dataSource}
      columns={columns}
      localeClick={openDrawer}
    />
  );
}
