import { Tabs } from "antd";
import { useEffect, useState } from "react";

import REQUESTS from "../../../api/requests";

import GeneralTab from "./tabs/GeneralTab";
import AboutUsTab from "./tabs/AboutUsTab";
import SocialLinksTab from "./tabs/SocialLinksTab";
import KeywordsTab from "./tabs/keywords/KeywordsTab";
import PrivacyPolicyTab from "./tabs/PrivacyPolicyTab";
import ScriptsTab from "./tabs/scripts/ScriptsTab";

export default function WebSettingsPage({}) {
    const [settings, setSettings] = useState(null);

    const tabs = [
        {
            title: "General",
            key: "general",
            content: <GeneralTab settings={settings} />,
        },

        {
            title: "About Us",
            key: "about_us",
            content: <AboutUsTab about_us={settings?.about_us} />,
        },

        {
            title: "Privacy Policy",
            key: "privacy",
            content: <PrivacyPolicyTab privacy_policy={settings?.privacy_policy} />,
        },

        {
            title: "Social Links",
            key: "social_links",
            content: <SocialLinksTab settings={settings} />,
        },

        {
            title: "Keywords",
            key: "keywords",
            content: <KeywordsTab settings={settings} />,
        },

        {
            title: "Scripts",
            key: "scripts",
            content: <ScriptsTab settings={settings} />,
        },
    ];

    const getSettings = () => {
        REQUESTS.WEB_PAGE.SETTINGS.GET()
            .then((response) => {
                if (response.error) {
                    return;
                }

                setSettings(response.message);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        getSettings();
    }, []);

    return (
        <div>
            <h4 style={{ marginBottom: 20 }}>Settings</h4>

            <Tabs
                tabPosition="left"
                tabBarStyle={{ background: "white", width: 180 }}
                items={tabs.map((_, i) => {
                    return {
                        label: _.title,
                        key: _.key,
                        children: _.content,
                    };
                })}
            />
        </div>
    );
}
