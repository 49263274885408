import { useEffect, useState } from "react";

import { Drawer, Form, Input, InputNumber, notification, Switch } from "antd";

import REQUESTS from "../../../api/requests";

import ButtonComponent from "../../../_components/ButtonComponent";

import styles from "./tvod_payment.module.scss";

export default function TvodPaymentDrawer({ onClose, open, editable, getData }) {
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const [resolutions, setResolutions] = useState([
    {
      key: 1,
      name: "HD",
      enabled: false,
      price: 0,
      rent_price: 0,
    },

    {
      key: 2,
      name: "FULL HD",
      enabled: false,
      price: 0,
      rent_price: 0,
    },

    {
      key: 3,
      name: "4K",
      enabled: false,
      price: 0,
      rent_price: 0,
    },
  ]);

  const onFinish = (values) => {
    setIsLoading(true);

    let enabledResolutions = [];

    for (let i = 0; i < resolutions.length; i++) {
      if (resolutions[i].enabled) {
        enabledResolutions.push({
          name: resolutions[i].name,
          price: resolutions[i].price,
          rent_price: resolutions[i].rent_price,
        });
      }
    }

    const body = {
      name: values.name,
      resolution: JSON.stringify(enabledResolutions),
    };

    if (editable) {
      body.id = editable.id;

      REQUESTS.PAYMENTS.TVOD_PAYMENT.EDIT(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              description: response.message,
            });

            return;
          }

          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      REQUESTS.PAYMENTS.TVOD_PAYMENT.ADD(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              // description: response.message,
              description: "Select price for all enabled resolutions",
            });

            return;
          }
          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const onChangeResolution = (value, index, type) => {
    let list = [...resolutions];

    list[index][type] = value;

    setResolutions(list);
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setResolutions([
        {
          key: 1,
          name: "HD",
          enabled: false,
          price: 0,
          rent_price: 0,
        },

        {
          key: 2,
          name: "FULL HD",
          enabled: false,
          price: 0,
          rent_price: 0,
        },

        {
          key: 3,
          name: "4K",
          enabled: false,
          price: 0,
          rent_price: 0,
        },
      ]);

      setIsLoading(false);
    }

    if (editable) {
      form.setFields([{ name: "name", value: editable.name }]);

      if (editable.pricing_resolutions) {
        for (let i = 0; i < editable.pricing_resolutions.length; i++) {
          const index = resolutions.findIndex((object) => {
            return object.name === editable.pricing_resolutions[i].name;
          });

          onChangeResolution(true, index, "enabled");
          onChangeResolution(editable.pricing_resolutions[i].price, index, "price");
          onChangeResolution(
            editable.pricing_resolutions[i].rent_price,
            index,
            "rent_price"
          );
        }
      }
    }
  }, [open, editable]);

  return (
    <Drawer
      title={`${editable ? "Edit" : "Add"}`}
      placement="right"
      onClose={onClose}
      open={open}
      width={500}
    >
      <Form
        form={form}
        name="payment-group"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //   if (e.key === "Enter") {
        //     form.submit();
        //   }
        // }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name",
            },
          ]}
        >
          <Input />
        </Form.Item>

        {resolutions.map((item, index) => {
          return (
            <div className={styles["resolutions"]}>
              <p style={{ width: 60 }}> {item.name}</p>
              <Switch
                title={item.name}
                checked={item.enabled}
                onChange={(e) => onChangeResolution(e, index, "enabled")}
              />

              <span>Price:</span>
              <InputNumber
                min={0}
                controls={false}
                placeholder="price"
                value={item.price}
                disabled={!item.enabled}
                onChange={(e) => onChangeResolution(e, index, "price")}
              />

              <span>Rent price:</span>

              <InputNumber
                min={0}
                controls={false}
                placeholder="Rent price"
                disabled={!item.enabled}
                value={item.rent_price}
                onChange={(e) => onChangeResolution(e, index, "rent_price")}
              />
            </div>
          );
        })}

        <Form.Item style={{ textAlign: "center", marginTop: 50 }}>
          <ButtonComponent
            disabled={
              resolutions.filter((item) => item.enabled).length > 0 ? false : true
            }
            title="Save"
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
