import { Drawer, Form, Select, Space } from "antd";
import ButtonComponent from "../../../../_components/ButtonComponent";
import TableImage from "../../../../_components/table/TableImage";
import icons from "../../../../config/icons";

const AddCollectionDrawer = ({ open, close, finish, getMovieName, searchDataSource }) => {
  const [form] = Form.useForm();
  const { Option } = Select;

  const handleFinish = (selectedMovies) => {
    finish(selectedMovies.movies);
  };

  return (
    <Drawer
      title="Add Collection movie"
      visible={open}
      onClose={() => {
        form.resetFields();
        close();
      }}
      width={450}
    >
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        autoComplete="off"
        onChange={(e) => getMovieName(e?.target?.value)}
      >
        <Form.Item label="Movie" name="movies">
          <Select
            showSearch
            mode="multiple"
            // style={{ width: "100%" }}
            placeholder="Select a value"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {searchDataSource?.rows?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item style={{ textAlign: "right" }}>
          <ButtonComponent
            title="Add"
            type="primary"
            icon={icons.CHECK}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddCollectionDrawer;
