import { useEffect, useState } from "react";

import REQUESTS from "../../../api/requests";

import Title from "../../../_components/Title";

import TableComponent from "../../../_components/table/TableComponent";

import returnColumns from "./columns";

export default function ServiceNotificationsPage() {
  const [dataSource, setDataSource] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    sort: ["is_archive", "ASC"],
  });

  const getServicesNotifications = () => {
    setIsLoading(true);

    REQUESTS.SETTINGS.GENERAL.SERVICE_NOTIFICATION.GET({
      query: JSON.stringify(query),
    })
      .then((response) => {
        setIsLoading(false);

        if (response.error) return;

        setDataSource(response.message.rows);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleTableChange = (data) => {
    setQuery((prev) => ({
      ...prev,
      page: data.page,
      limit: data.limit,
    }));
  };

  useEffect(() => {
    getServicesNotifications();
  }, [query]);

  return (
    <div>
      <Title text="Service Notifications" />

      <TableComponent
        isLoading={isLoading}
        dataSource={dataSource}
        onChange={handleTableChange}
        columns={returnColumns({ handleTableChange })}
        pagination={{
          page: query.page,
          limit: query.limit,
        }}
        rowClassName={(record) => {
          if (!record.is_archive) return "table-green-row";
        }}
      />
    </div>
  );
}
