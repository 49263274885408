import { useState } from "react";
import { Checkbox, Drawer, Form, Select, Radio } from "antd";
import ButtonComponent from "../../../../components/ButtonComponent";
import {
  useAgeRestrictionOptions,
  useCountriesOptions,
  useDevicesOptions,
  usePaymentsSubscriptionsOptions,
  useLiveTvCategoriesOptions,
  useAddListOPtions,
} from "../../../../hooks/selectOptions";

const BulkEditDrawer = ({ open, onClose, finish }) => {
  const [form] = Form.useForm();

  const ageRestrictionOptions = useAgeRestrictionOptions();
  const countriesOptions = useCountriesOptions();
  const devicesOptions = useDevicesOptions();
  const paymentsSubscriptionsOptions = usePaymentsSubscriptionsOptions();
  const categoriesOption = useLiveTvCategoriesOptions();
  const addListOptions = useAddListOPtions();

  const allDevicesIds = devicesOptions.map((item) => item.value).slice(1);

  const [fieldChecked, setFieldChecked] = useState({});

  const handleCheck = (event) => {
    const checked = event.target.id;
    const isChecked = { [checked]: event.target.checked };

    if (checked === "location_restriction_enable") {
      form.setFields([
        { name: "location_restriction_enable", value: event.target.checked },
      ]);
    }

    setFieldChecked((prev) => ({ ...prev, ...isChecked }));
  };

  const onSelectDevices = (selected) => {
    if (selected === "all") {
      form.setFields([{ name: "deviceIds", value: allDevicesIds }]);
    }
  };

  const checkProtection = (event) => {
    const checked = event.target.checked;

    form.setFields([{ name: "protected", value: checked }]);
  };

  return (
    <Drawer
      open={open}
      onClose={() => {
        form.resetFields();
        setFieldChecked({});
        onClose();
      }}
      title="Edit"
      width={500}
    >
      <Form
        form={form}
        onFinish={(values) => {
          finish(values, fieldChecked);
          form.resetFields();
        }}
        layout="vertical"
      >
        <div style={{ display: "flex", gap: 30, alignItems: "baseline" }}>
          <Form.Item name="age_restriction_enable">
            <Checkbox onChange={handleCheck} />
          </Form.Item>
          <Form.Item
            label="Age restriction"
            name="age_restriction"
            style={{ width: "75%" }}
            rules={[
              {
                required: fieldChecked.age_restriction_enable,
                message: "Age Restriction field is required",
              },
            ]}
          >
            <Select
              options={ageRestrictionOptions}
              style={{ width: "100%" }}
              disabled={!fieldChecked.age_restriction_enable}
            />
          </Form.Item>
        </div>
        <div style={{ display: "flex", gap: 30, alignItems: "baseline" }}>
          <Form.Item name="tariff_enable">
            <Checkbox onChange={handleCheck} />
          </Form.Item>
          <Form.Item
            label="Content monetization type"
            name="content_monetization_type"
            style={{
              width: "80%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Radio.Group buttonStyle="solid" disabled={!fieldChecked.tariff_enable}>
              <Radio value="free">Free</Radio>
              <Radio value="stv">STV</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            gap: 30,
            alignItems: "baseline",
            justifyContent: "space-around",
          }}
        >
          <Form.Item noStyle shouldUpdate>
            {() => {
              const { content_monetization_type } = form.getFieldsValue();

              if (content_monetization_type === "free") {
                return (
                  <Form.Item
                    label="Add"
                    name="ads_id"
                    style={{ width: "80%" }}
                    rules={[
                      {
                        required:
                          fieldChecked.tariff_enable &&
                          content_monetization_type === "stv",
                        message: "Tariff field is required",
                      },
                    ]}
                  >
                    <Select
                      options={addListOptions}
                      mode="multiple"
                      style={{ width: "95%" }}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label.toLowerCase() ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) => {
                        return (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase());
                      }}
                      disabled={!fieldChecked.tariff_enable}
                    />
                  </Form.Item>
                );
              }
            }}
          </Form.Item>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: 30,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Form.Item shouldUpdate noStyle>
            {() => {
              const { content_monetization_type } = form.getFieldsValue();

              if (content_monetization_type === "stv") {
                return (
                  <Form.Item
                    label="Tariffs"
                    name="tariffIds"
                    style={{ width: "80%" }}
                    rules={[
                      {
                        required:
                          fieldChecked.tariff_enable &&
                          content_monetization_type === "stv",
                        message: "Tariff field is required",
                      },
                    ]}
                  >
                    <Select
                      options={paymentsSubscriptionsOptions}
                      mode="multiple"
                      style={{ width: "95%" }}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label.toLowerCase() ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) => {
                        return (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase());
                      }}
                      disabled={!fieldChecked.tariff_enable}
                    />
                  </Form.Item>
                );
              }
            }}
          </Form.Item>
        </div>

        <div style={{ display: "flex", gap: 30 }}>
          <Form.Item name="devices_enable">
            <Checkbox onChange={handleCheck} />
          </Form.Item>
          <Form.Item
            label="Devices"
            name="deviceIds"
            style={{ width: "75%" }}
            rules={[
              {
                required: fieldChecked.devices_enable,
                message: "Devices field is required",
              },
            ]}
          >
            <Select
              options={devicesOptions}
              onSelect={onSelectDevices}
              mode="multiple"
              style={{ width: "100%", marginTop: 5 }}
              showSearch
              filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? "").includes(input)
              }
              disabled={!fieldChecked.devices_enable}
            />
          </Form.Item>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Form.Item
            name="location_restriction_enable"
            style={{
              width: "100%",
              display: "flex",
              gap: 30,
            }}
          >
            <Checkbox onChange={handleCheck} style={{ display: "flex", gap: 28 }}>
              Geolocation restriction
            </Checkbox>
          </Form.Item>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "85%",
            }}
          >
            <Form.Item label="Countries" name="countries" style={{ width: "87%" }}>
              <Select
                options={countriesOptions}
                mode="multiple"
                style={{ width: "100%" }}
                disabled={!fieldChecked.location_restriction_enable}
              />
            </Form.Item>
          </div>
        </div>

        <div style={{ display: "flex", gap: 30 }}>
          <Form.Item name="is_protected_enable">
            <Checkbox onChange={handleCheck} />
          </Form.Item>
          <Form.Item name="protected" style={{ width: "50%" }}>
            <Checkbox
              disabled={!fieldChecked.is_protected_enable}
              onChange={checkProtection}
            >
              Is protected
            </Checkbox>
          </Form.Item>
        </div>
        <div style={{ display: "flex", gap: 30 }}>
          <Form.Item name="categories_enable">
            <Checkbox onChange={handleCheck} />
          </Form.Item>
          <Form.Item
            label="Categories"
            name="categories"
            style={{ width: "75%" }}
            rules={[
              {
                required: fieldChecked.categories_enable,
                message: "Categories field is required",
              },
            ]}
          >
            <Select
              options={categoriesOption}
              mode="multiple"
              style={{ width: "100%" }}
              disabled={!fieldChecked.categories_enable}
            />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
          <ButtonComponent title={"Save"} notIcon={true} />
        </div>
      </Form>
    </Drawer>
  );
};

export default BulkEditDrawer;
