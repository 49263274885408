import { useEffect, useState } from "react";
import { Popconfirm } from "antd";

import icons from "../../config/icons";
import { getColumnDateProps, getColumnSearchProps, getQueryDate } from "../../config/config";

import TableButtons from "../../_components/table/TableButtons";
import TableComponent from "../../_components/table/TableComponent";
import ButtonComponent from "../../_components/button/ButtonComponent";
import { parseDate } from "../../config/config";

const typeOptions = [
    { value: "update", text: "Update" },
    { value: "add", text: "Add" },
    { value: "delete", text: "Delete" },
    { value: "login", text: "Login" },
    { value: "payment", text: "Payment" },
];

export default function HistoryTable({ getAgain, getDataSource, handleMenuClick, clearAll }) {
    const [dataSource, setDataSource] = useState([]);

    const [loading, setLoading] = useState(false);

    const [limit, setLimit] = useState(10);

    const [currentPage, setCurrentPage] = useState(1);

    const [sort, setSort] = useState(["id", "DESC"]);

    const [total, setTotal] = useState(0);

    const [date, setDate] = useState(null);

    const [search, setSearch] = useState({
        adminName: null,
    });

    const columns = [
        {
            width: 60,
            title: "#",
            key: "index",
            dataIndex: `id`,
            fixed: "left",
            align: "center",
            render: (text, record, index) => {
                return limit * (currentPage - 1) + index + 1;
            },
        },

        {
            title: "Name",
            dataIndex: "adminName",
            key: "adminName",
            align: "center",

            ...getColumnSearchProps(),
        },

        {
            title: "Type",
            key: "type",
            dataIndex: "type",
            align: "center",

            filters: typeOptions,
            filterMultiple: false,
            onFilter: (value, record) => true,
        },

        {
            title: "Action",
            key: "message",
            dataIndex: "message",
            align: "center",
        },

        {
            title: "Ip address",
            key: "ipv4",
            dataIndex: "ipv4",
            align: "center",
        },

        {
            title: "Date",
            key: "createdAt",
            dataIndex: "createdAt",
            align: "center",
            render: (text, record, index) => parseDate(text),

            ...getColumnDateProps(setDate),
        },

        {
            title: "",
            key: "operation",
            fixed: "right",
            width: 60,
            align: "center",

            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    items={[
                        {
                            key: "delete",
                            name: "Delete",
                            icon: icons.DELETE,
                            question: `Do you want to delete this group?`,
                            onConfirm: (e) => {
                                handleMenuClick(e, record);
                            },
                        },
                    ]}
                />
            ),
        },
    ];

    const handleTableChange = (data) => {
        setCurrentPage(data.page);
        setLimit(data.limit);
        setSort(data.sort);

        setSearch(data.search);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(true);

            const query = {
                sort,
                limit,
                page: currentPage,
            };

            if (search.adminName) {
                query.search = {
                    adminName: search.adminName[0],
                };
            }

            if (search.type) {
                query.filter = {
                    type: search.type[0],
                };
            }

            const queryDate = getQueryDate(date);

            if (queryDate) query.between = queryDate;

            function onSuccess(data) {
                if (data.rows?.length === 0 && currentPage > 1) {
                    setCurrentPage((prev) => prev - 1);
                    return;
                }

                setLoading(false);
                setDataSource(data.rows);

                setTotal(data?.total);
                setLimit(data?.limit);
                setCurrentPage(data?.page);
            }

            function onError(data) {
                setLoading(false);
            }

            getDataSource({ query: JSON.stringify(query) }, onSuccess, onError);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [limit, currentPage, sort, search, date, getAgain]);

    return (
        <TableComponent
            header={
                <>
                    <Popconfirm
                        placement="topRight"
                        title="Do you want to clear all history"
                        onConfirm={clearAll}
                        okButtonProps={{
                            danger: true,
                        }}
                        okText="Clear"
                        cancelText="Cancel"
                    >
                        <ButtonComponent danger={true} title="Clear All" />
                    </Popconfirm>
                </>
            }
            isLoading={loading}
            dataSource={dataSource}
            onChange={handleTableChange}
            columns={columns}
            pagination={{
                page: currentPage,
                limit: limit,
                total: total,
            }}
        />
    );
}
