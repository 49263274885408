import styles from "./_server.module.scss";

export default function Storage({ data }) {
    return (
        <div className={styles["storage"]}>
            <p className={styles["title"]}> Storage</p>
            {data ? (
                <>
                    <p>
                        {data?.used && data?.used != "NaN" ? parseFloat(data?.used).toFixed(0) : 0}{" "}
                        gb
                    </p>
                    <div>/</div> <p> {data.size ? parseFloat(data.size).toFixed(0) : 0} gb </p>
                    <p className={styles["percent"]}>
                        (
                        {data?.used_percentages && data?.used_percentages != "NaN"
                            ? parseFloat(data.used_percentages).toFixed(0)
                            : 0}{" "}
                        %){" "}
                    </p>
                </>
            ) : (
                <span>N/A</span>
            )}
        </div>
    );
}
