import { useState, useEffect } from "react";
import REQUESTS from "../../../../api/requests";
import { Form, Switch, Input, notification } from "antd";
import ImageUpload from "../../../../components/ImageUpload";
import ButtonComponent from "../../../../_components/button/ButtonComponent";

export default function KoopBankConfig({ data, selectedCurrency }) {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [icon, setIcon] = useState({
    url: null,
    file: null,
  });

  const onFinish = (values) => {
    setIsLoading(true);

    const formData = new FormData();

    if (icon?.file) {
      formData.append("koopbank_icon", icon.file);
    }

    formData.append("id", data.id);
    formData.append("currency_id", selectedCurrency);
    formData.append("koopbank_enabled", values.koopbank_enabled);
    formData.append("koopbank_iv", values.koopbank_iv);
    formData.append("koopbank_pass_phrase", values.koopbank_pass_phrase);
    formData.append("koopbank_username", values.koopbank_username);
    formData.append("koopbank_password", values.koopbank_password);
    formData.append("koopbank_xposid", values.koopbank_xposid);

    REQUESTS.PAYMENTS.CONFIGS.UPDATE(formData)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message,
          });

          return;
        }

        notification.success({
          message: "Success",
          description: "configs is changed",
        });
      })
      .catch((error) => {
        setIsLoading(false);
        notification.error({
          message: "Error",
          description: error,
        });
      });
  };

  useEffect(() => {
    if (data) {
      setIcon({
        url: data.koopbank_icon,
        file: null,
      });

      form.setFields([
        { name: "koopbank_icon", value: data?.koopbank_icon },
        { name: "koopbank_enabled", value: data.koopbank_enabled },
        { name: "koopbank_iv", value: data.koopbank_iv },
        { name: "koopbank_pass_phrase", value: data.koopbank_pass_phrase },
        { name: "koopbank_username", value: data.koopbank_username },
        { name: "koopbank_password", value: data?.koopbank_password },
        { name: "koopbank_xposid", value: data.koopbank_xposid },
      ]);
    }
  }, [data]);

  const onChangeIcon = (value) => {
    setIcon(value);

    form.setFieldsValue({
      koopbank_icon: value.file,
    });
  };
  return (
    <Form
      form={form}
      name="koopbank"
      layout="vertical"
      onFinish={onFinish}
      style={{ maxWidth: 800 }}
    >
      <Form.Item shouldUpdate noStyle>
        {() => {
          const { koopbank_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label="Icon"
              name="koopbank_icon"
              rules={[
                {
                  required: koopbank_enabled,
                  message: "Please choose icon",
                },
              ]}
            >
              <ImageUpload image={icon} setImage={onChangeIcon} />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item label="Enabled" name="koopbank_enabled" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { koopbank_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label="Input vector"
              name="koopbank_iv"
              rules={[
                {
                  required: koopbank_enabled,
                  message: "Input vector is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { koopbank_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label="Passphrase"
              name="koopbank_pass_phrase"
              rules={[
                {
                  required: koopbank_enabled,
                  message: "Please enter passphrase",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { koopbank_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label="Username"
              name="koopbank_username"
              rules={[
                {
                  required: koopbank_enabled,
                  message: "Please enter username",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { koopbank_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label="Password"
              name="koopbank_password"
              rules={[
                {
                  required: koopbank_enabled,
                  message: "Please enter password",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { koopbank_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label="XPOS ID"
              name="koopbank_xposid"
              rules={[
                {
                  required: koopbank_enabled,
                  message: "Please enter XPOS ID",
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        }}
      </Form.Item>

      <ButtonComponent
        title="Save"
        actionType="save"
        isLoading={isLoading}
        onClick={() => form.submit()}
      />
    </Form>
  );
}
