import { useState, useEffect } from "react";

import { notification, Switch } from "antd";

import REQUESTS from "../../../api/requests";

import style from "./style.module.scss";

export default function NotificationTab({ data }) {
    const [notifications, setNotifications] = useState([]);

    const onChange = (item) => {
        const body = {
            id: item.id,
            enabled: !item.enabled,
        };

        REQUESTS.SETTINGS.GENERAL.NOTIFICATIONS.UPDATE(body)
            .then((response) => {
                if (response.error) {
                    return;
                }

                notification.success({
                    message: "Success",
                    description: `${item.title} is updated`,
                });

                getNotifications();
            })
            .catch((err) => {
                notification.error({
                    message: "Error",
                    description: `${item.title} is not updated`,
                });
            });
    };

    const getNotifications = () => {
        REQUESTS.SETTINGS.GENERAL.NOTIFICATIONS.GET()
            .then((response) => {
                if (response.error) {
                    return;
                }

                setNotifications(response.message);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        getNotifications();
    }, []);

    return (
        <div className={style["tab"]}>
            <p className={style["title"]}>{data?.title}</p>

            <p className={style["description"]}>{data?.description}</p>

            {notifications.map((item) => {
                return (
                    <div style={{ marginBottom: 15 }}>
                        <Switch
                            style={{ marginRight: 10 }}
                            checked={item.enabled}
                            onChange={() => onChange(item)}
                        />
                        {item.title}
                    </div>
                );
            })}
        </div>
    );
}
