import { useState } from "react";

import { notification } from "antd";

import ImageUpload from "../../../components/ImageUpload";

import ButtonComponent from "../../../_components/button/ButtonComponent";

import REQUESTS from "../../../api/requests";

import styles from "./_user-icons.module.scss";

export default function AddUserIcon({ getData }) {
    const [isLoading, setIsLoading] = useState(false);
    const [image, setImage] = useState({
        url: null,
        file: null,
    });

    const onUpload = () => {
        setIsLoading(true);

        const formData = new FormData();

        formData.append("icons", image.file);

        REQUESTS.APPLICATION.USER_ICONS.ADD(formData)
            .then((response) => {
                setIsLoading(false);

                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });

                    return;
                }

                notification.success({
                    message: "Success",
                    description: "Icon is added",
                });

                getData();
                setImage({
                    url: null,
                    file: null,
                });
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    return (
        <div className={styles["icon-upload"]}>
            <ImageUpload image={image} setImage={setImage} />

            <ButtonComponent
                title="Save"
                isLoading={isLoading}
                disabled={!image.file}
                onClick={onUpload}
            />
        </div>
    );
}
