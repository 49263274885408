import { useEffect, useState } from "react";
import { Drawer, Select } from "antd";
import ButtonComponent from "../../../_components/ButtonComponent";

const SetTypeDrawer = ({ open, onClose, onSave, editable }) => {
  const [type, setType] = useState("");
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const typeOPtion = [
    // {
    //   value: "Ads banner",
    //   // value: "ads_banner",
    // },
    // {
    //   value: "Full Banner",
    //   // value: "full_banner",
    // },
    {
      value: "TOP 10",
      // label: "top_10",
    },
  ];

  useEffect(() => {
    setValue(editable?.collection_type);
    setLoading(false);
  }, [editable]);

  return (
    <Drawer
      title={"Set collection type"}
      open={open}
      footer={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 10,
          }}
        >
          <ButtonComponent
            type="default"
            title={"Cancel"}
            onClick={() => {
              onClose();
            }}
          />
          <ButtonComponent
            title={"Ok"}
            isLoading={loading}
            onClick={() => {
              setLoading(true);
              onSave(type, () => {
                setLoading(false);
              });
            }}
          />
        </div>
      }
      onClose={() => {
        onClose();
      }}
    >
      <span>Select type</span>
      <Select
        placeholder="Please select option"
        style={{
          width: "100%",
          marginTop: 5,
        }}
        value={value}
        options={typeOPtion}
        onChange={(e) => {
          const type = {
            value: e,
            type: "collection_type",
          };
          setValue(e);
          setType(type);
        }}
      />
    </Drawer>
  );
};

export default SetTypeDrawer;
