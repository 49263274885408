import { Children, useState } from "react";
import { Tabs, notification } from "antd";

import REQUESTS from "../../api/requests";

import AdsTable from "./AdsTable";
import AdsDrawer from "./drawer/AdsDrawer";
import AdsConfigsDrawer from "./AdsConfigsDrawer";

import PageComponent from "../../_components/page/PageComponent";
import AddStatisticPage from "./addStatistic/AddStatisticPAge";

export default function AdsPage() {
  const [getAgain, setGetAgain] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [isOpenConfigsDrawer, setIsOpenConfigsDrawer] = useState(false);

  const [editable, setEditable] = useState(null);

  const getDataSource = (query, onSuccess, onError) => {
    REQUESTS.ADS.GET({ query: JSON.stringify(query) })
      .then((response) => {
        onSuccess(response.message);
      })
      .catch((error) => {
        onError(error);
      });
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "delete":
        REQUESTS.ADS.DELETE({ id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });

      default:
        break;
    }
  };

  const tabs = [
    {
      label: "Ads",
      key: "adds",
      children: (
        <AdsTable
          getAgain={getAgain}
          getDataSource={getDataSource}
          handleMenuClick={handleMenuClick}
          openDrawer={() => setIsOpenDrawer(true)}
          openConfigsDrawer={() => setIsOpenConfigsDrawer(true)}
        />
      ),
      forceRender: true,
    },
    {
      label: "Dashboard",
      key: "dashboard",
      children: (
        <div style={{ width: "100%", margin: "20px 0" }}>
          <AddStatisticPage />
        </div>
      ),
      forceRender: true,
    },
  ];

  return (
    <PageComponent routes={["AVOD", "ADS"]}>
      {/* <div style={{ width: "100%", margin: "20px 0" }}>
        <AddStatisticPage />
      </div>
      <AdsTable
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        openDrawer={() => setIsOpenDrawer(true)}
        openConfigsDrawer={() => setIsOpenConfigsDrawer(true)}
      /> */}
      <Tabs tabPosition="top" items={tabs} />
      <AdsDrawer
        open={isOpenDrawer}
        editable={editable}
        getData={() => setGetAgain((prev) => !prev)}
        getAgain={getAgain}
        onClose={() => {
          setIsOpenDrawer(false);
          setEditable(null);
        }}
      />

      <AdsConfigsDrawer
        open={isOpenConfigsDrawer}
        onClose={() => {
          setIsOpenConfigsDrawer(false);
        }}
      />
    </PageComponent>
  );
}
