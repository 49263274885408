import REQUESTS from "../../../../api/requests";

export default function onSetEditableValues(
  form,
  editable,
  setPoster,
  setBackdrop,
  permissions
) {
  setPoster({
    url: editable.image,
    file: null,
  });

  setBackdrop({
    url: editable.backdrop,
    file: null,
  });
  const categoriesList = editable.categories.map((item) => item.id);
  form.setFields([
    { name: "name", value: editable.name },
    { name: "categories", value: categoriesList },
    { name: "comment", value: editable.comment },
    { name: "age_restricted", value: editable.age_restricted },
    { name: "is_protected", value: editable.is_protected },
    {
      name: "is_location_restriction",
      value: editable.is_location_restriction,
    },

    {
      name: "available_countries",
      value: editable.available_countries?.split(","),
    },
    { name: "permission_devices", value: editable.permission_devices },
    // { name: "epg_type", value: editable.epg_type },
    { name: "epg_type", value: "xmltv" },

    { name: "id_epg", value: editable?.id_epg || "" },
    { name: "urls", value: editable?.channel_urls || [] },
    { name: "url", value: editable.url || "" },
    {
      name: "content_monetization_type",
      value: editable.content_monetization_type,
    },
    {
      name: "stream_type",
      value: editable.stream_type,
    },

    {
      name: "stream_id",
      value: editable.stream_id,
    },
  ]);
  // const newTariffsIds = editable?.adds_id.split(",");

  if (form.getFieldValue("content_monetization_type") === "free") {
    form.setFields([{ name: "add_tariffs", value: editable.adds_id }]);
  }

  if (permissions?.Archive) {
    form.setFields([{ name: "has_archive", value: editable.has_archive }]);

    if (editable.archived_channel) {
      form.setFields([
        { name: "archive_day", value: editable.archived_channel.archiveDays },
        { name: "archive_server", value: editable.archived_channel.archiverId },
        { name: "archive_url", value: editable.archived_channel.url },
        { name: "archived_channel_type", value: editable.archived_channel.type },
      ]);
    }
  }

  getTariffsIds(editable.id, form);
  getVodIds(editable.id, form);
}

const getTariffsIds = (id, form) => {
  REQUESTS.LIVE_TV.CHANNELS.TARIFFS(id)
    .then((response) => {
      if (response.message) {
        const ids = response.message.map((item) => item.id);
        form.setFields([{ name: "tariffIds", value: ids }]);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

const getVodIds = (id, form) => {
  REQUESTS.LIVE_TV.CHANNELS.ADDS_IDS(id)
    .then((response) => {
      if (response.message) {
        const ids = response.message.map((item) => {
          if (item.type === "text") {
            return item.text;
          } else {
            return item.url;
          }
        });
        form.setFields([{ name: "adds_id", value: ids }]);
      }
    })

    .catch((err) => {
      console.log(err);
    });
};
