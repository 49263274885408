import { Drawer } from "antd";
import { useEffect, useState } from "react";

import REQUESTS from "../../../../../../api/requests";

import { vodServiceId } from "../../../../../../config/statics";

import VodTable from "./VodTable";
import SetupFilterDrawer from "./SetupFilterDrawer";
import CustomMoviesDrawer from "./CustomMoviesDrawer";

import ButtonComponent from "../../../../../../_components/button/ButtonComponent";

export default function VodDrawer({
    open,
    onClose,
    setSelectedMoviesCount,
    vodService,
    setVodSeervice,
}) {
    const [isOpenSetupFilterDrawer, setIsOpenSetupFilterDrawer] = useState(false);
    const [isOpenCustomMoviesDrawer, setIsOpenCustomMoviesDrawer] = useState(false);

    const [actualVodService, setActualVodService] = useState({
        year: {
            type: "",
            values: [],
        },
        id: {
            exclude: [],
            include: [],
        },
        category: {
            type: "",
            values: [],
        },
    });

    const getDataSource = (query, onSuccess, onError) => {
        REQUESTS.PAYMENTS.SUBSCRIPTIONS.CONTENT({
            query: JSON.stringify(query),
            filter: JSON.stringify(actualVodService),
            serviceId: vodServiceId,
        })
            .then((response) => {
                if (response.message) {
                    setSelectedMoviesCount(response.message?.total);
                }
                onSuccess(response.message);
            })
            .catch((error) => {
                onError(error);
            });
    };

    const excludeMovie = (item) => {
        const exclude = [...actualVodService.id.exclude];

        exclude.push(item.id);

        setActualVodService((prev) => ({
            ...prev,
            id: {
                exclude: exclude,
                include: prev.id.include,
            },
        }));
    };

    const removeMoviesFromExcludeList = (ids) => {
        let exclude = [...actualVodService.id.exclude];

        exclude = exclude.filter((item) => !ids.includes(item));

        setActualVodService((prev) => ({
            ...prev,
            id: {
                exclude: exclude,
                include: prev.id.include,
            },
        }));
    };

    const onAddMovie = (ids) => {
        removeMoviesFromExcludeList(ids);
        setActualVodService((prev) => ({
            ...prev,
            id: {
                exclude: prev.id.exclude,
                include: ids,
            },
        }));
    };

    const onAdd = () => {
        setVodSeervice(actualVodService);
        onClose();
    };

    const onAddFilterData = (data) => {
        setActualVodService((prev) => ({
            ...prev,
            year: data.year,
            category: data.category,
        }));
    };

    useEffect(() => {
        if (open) {
            setActualVodService(vodService);
        }
    }, [vodService, open]);

    return (
        <Drawer width={800} title="Vod" placement="right" onClose={onClose} open={open}>
            <h6>Here you can see your selected moves</h6>

            <VodTable
                excludeMovie={excludeMovie}
                getDataSource={getDataSource}
                actualVodService={actualVodService}
                openSetupFilterDrawer={() => setIsOpenSetupFilterDrawer(true)}
                openCutomMoviesDrawer={() => setIsOpenCustomMoviesDrawer(true)}
            />

            <div style={{ marginTop: 20, textAlign: "center" }}>
                <ButtonComponent title="Save" actionType="save" onClick={onAdd} />
            </div>

            <CustomMoviesDrawer
                open={isOpenCustomMoviesDrawer}
                actualVodService={actualVodService}
                onAdd={(ids) => {
                    onAddMovie(ids);
                }}
                onClose={() => setIsOpenCustomMoviesDrawer(false)}
            />

            <SetupFilterDrawer
                onAdd={onAddFilterData}
                open={isOpenSetupFilterDrawer}
                actualVodService={actualVodService}
                onClose={() => setIsOpenSetupFilterDrawer(false)}
            />
        </Drawer>
    );
}
