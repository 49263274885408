import styles from "./_import.module.scss";

export default function Card({ title, total, done }) {
    return (
        <div className={styles["card"]}>
            <p className={styles["title"]}>{title}</p>
            <p>
                Total: <span>{total}</span>
            </p>
            <p>
                Done: <span>{done}</span>
            </p>
        </div>
    );
}
