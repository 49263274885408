import { MenuOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Image, Tooltip, Tag } from "antd";

import React, { useEffect, useState } from "react";
import REQUESTS from "../../../api/requests";
import defaultImage from "../../../img/photo.png";
import TableButtons from "../../../_components/table/TableButtons";
import { parseDateFormat } from "../../../config/config";
import icons from "../../../config/icons";
import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import DeleteConfirmButton from "../../../_components/button/DeleteConfirmButton";

const Row = ({ children, ...props }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props["data-row-key"],
    });

    const style = {
        ...props.style,
        transform: CSS.Transform.toString(
            transform && {
                ...transform,
                scaleY: 1,
            }
        ),
        transition,
        ...(isDragging
            ? {
                  position: "relative",
                  zIndex: 9999,
              }
            : {}),
    };

    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
                if (child.key === "sort") {
                    return React.cloneElement(child, {
                        children: (
                            <MenuOutlined
                                ref={setActivatorNodeRef}
                                style={{
                                    touchAction: "none",
                                    cursor: "move",
                                }}
                                {...listeners}
                            />
                        ),
                    });
                }
                return child;
            })}
        </tr>
    );
};

export default function VodCollectionMoviesTable({
    getAgain,
    changePosition,
    getDataSource,
    onDelete,
}) {
    const [dataSource, setDataSource] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [isUpdated, setIsUpdated] = useState(false);

    const columns = [
        {
            key: "sort",
        },

        {
            title: "Icon",
            dataIndex: "icon",
            align: "center",

            render: (text, record, index) => {
                return (
                    <Image
                        src={record?.movie?.poster}
                        width={30}
                        height={30}
                        style={{ objectFit: "cover", borderRadius: "50%" }}
                        fallback={defaultImage}
                    />
                );
            },
        },

        {
            title: "Name",
            dataIndex: "name",
            align: "center",
            render: (text, record, index) => {
                return <p style={{ margin: 0, marginLeft: 10 }}>{record?.movie?.name}</p>;
            },
        },

        {
            title: "Type",
            dataIndex: "type",
            align: "center",
            render: (text, record, index) => {
                return <p style={{ margin: 0, marginLeft: 10 }}>{record?.movie?.type}</p>;
            },
        },

        {
            title: `Release date`,
            dataIndex: "release_date",
            key: "release_date",
            align: "center",

            render: (text, record, index) => {
                return parseDateFormat(record?.movie?.release_date);
            },
        },

        {
            title: ``,
            dataIndex: "delete",
            key: "delete",
            align: "center",

            render: (text, record, index) => {
                return (
                    <DeleteConfirmButton name="movie" onConfirm={() => onDelete(record.movieId)} />
                );
            },
        },
    ];

    useEffect(() => {
        setIsLoading(true);

        const query = {
            pagination: 1,
            limit: 1000,
        };

        function onSuccess(data) {
            setIsLoading(false);
            setDataSource(data);
        }

        function onError(data) {
            setIsLoading(false);
        }

        getDataSource(query, onSuccess, onError);
    }, [getAgain]);

    const onDragEnd = ({ active, over }) => {
        console.log(active, over);
        if (active.id !== over?.id) {
            setDataSource((previous) => {
                const activeIndex = previous.findIndex((i) => i.movieId === active.id);
                const overIndex = previous.findIndex((i) => i.movieId === over?.id);
                setIsUpdated(true);
                return arrayMove(previous, activeIndex, overIndex);
            });
        }
    };

    useEffect(() => {
        if (dataSource.length > 0) {
            if (isUpdated) {
                let ids = dataSource.map((item) => item.movieId);

                ids = ids.join(",");

                changePosition(ids);
            }
        }
    }, [dataSource, isUpdated]);

    return (
        <div style={{ marginTop: 20 }}>
            <DndContext onDragEnd={onDragEnd}>
                <SortableContext
                    items={dataSource.map((i) => i.movieId)}
                    strategy={verticalListSortingStrategy}
                >
                    <TableComponent
                        rowKey="movieId"
                        isLoading={isLoading}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        components={
                            dataSource.length > 0 && {
                                body: {
                                    row: Row,
                                },
                            }
                        }
                    />
                </SortableContext>
            </DndContext>
        </div>
    );
}
