import React, { useEffect, useState } from "react";

import { Layout, notification } from "antd";

import { Outlet } from "react-router";

import { bindActionCreators } from "redux";

import { useDispatch, useSelector } from "react-redux";

import * as actions from "../redux-store/actions";

import configDashboardSettings from "../config/configDashboardSettings";

import REQUESTS from "../api/requests";

import Header from "./header/Header";

import Sidebar from "./sidebar/Sidebar";
import Blur from "../_components/blur/Blur";

export default function LayoutComponent({}) {
  const dispatch = useDispatch();

  const { dashboardSettings, permissions } = useSelector((state) => state.globalState);

  const { setDashboardSettings } = bindActionCreators(actions, dispatch);

  const [collapsed, setCollapsed] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const [isBlur, setIsBlur] = useState(true);

  const getDashboardSettings = () => {
    REQUESTS.SETTINGS.GENERAL.GET().then((response) => {
      if (!response.error) {
        const dashboardSettins = configDashboardSettings(response.message);

        setDashboardSettings(dashboardSettins);
      }
    });
  };

  const getServicesNotification = () => {
    const query = {
      filter: {
        is_archive: false,
      },
    };

    REQUESTS.SETTINGS.GENERAL.SERVICE_NOTIFICATION.GET({ query: JSON.stringify(query) })
      .then((response) => {
        if (response.error) return;

        const notifications = response.message?.rows;

        for (let i = 0; i < notifications.length; i++) {
          openNotification(notifications[i]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openNotification = (item) => {
    api.error({
      message: item.service_name,
      description: item.message,
      placement: "bottomRight",
      duration: 0,

      onClose: () => {
        REQUESTS.SETTINGS.GENERAL.SERVICE_NOTIFICATION.CLOSE({ id: item.id });
      },
    });
  };

  useEffect(() => {
    getDashboardSettings();
    getServicesNotification();
  }, []);

  useEffect(() => {
    let timeout = null;

    if (dashboardSettings && permissions) {
      timeout = setTimeout(() => {
        setIsBlur(false);
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [permissions, dashboardSettings]);

  return (
    <Layout style={{ minHeight: "100vh", overflow: "hidden" }} hasSider>
      {contextHolder}

      {isBlur && <Blur />}

      <Sidebar collapsed={collapsed} />

      <Layout
        className="site-layout"
        style={{
          marginLeft: collapsed ? 80 : 200,
          transition: "0.4s",
          background: "#fbfdff",
          paddingTop: 70,
        }}
      >
        <Header collapsed={collapsed} setCollapsed={setCollapsed} />

        <Layout.Content
          style={{
            padding: "24px 50px",
            background: "#fbfdff",
            overflow: "auto",
          }}
        >
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
