import { useEffect, useState } from "react";

import { Form, Input, notification, Switch } from "antd";

import REQUESTS from "../../../../api/requests";

import ImageUpload from "../../../../components/ImageUpload";

import ButtonComponent from "../../../../_components/button/ButtonComponent";

export default function TelcellConfig({ data, selectedCurrency }) {
    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);

    const [icon, setIcon] = useState({
        url: null,
        file: null,
    });

    const onFinish = (values) => {
        setIsLoading(true);

        const formData = new FormData();

        if (icon.file) {
            formData.append("telcell_icon", icon.file);
        }

        formData.append("id", data.id);
        formData.append("currency_id", selectedCurrency);
        formData.append("telcell_enabled", values.telcell_enabled);
        formData.append("telcell_url", values.telcell_url);

        REQUESTS.PAYMENTS.CONFIGS.UPDATE(formData)
            .then((response) => {
                setIsLoading(false);

                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });

                    return;
                }

                notification.success({
                    message: "Success",
                    description: "Telcell configs is changed",
                });
            })
            .catch((error) => {
                setIsLoading(false);
                notification.error({
                    message: "Error",
                    description: error,
                });
            });
    };

    const onChangeIcon = (value) => {
        setIcon(value);

        form.setFieldsValue({
            telcell_icon: value.file,
        });
    };

    useEffect(() => {
        if (data) {
            setIcon({
                url: data.telcell_icon,
                file: null,
            });

            form.setFields([
                { name: "telcell_icon", value: data.telcell_icon },
                { name: "telcell_enabled", value: data.telcell_enabled },
                { name: "telcell_url", value: data.telcell_url },
            ]);
        }
    }, [data]);

    return (
        <Form
            form={form}
            name="telcell-config"
            layout="vertical"
            onFinish={onFinish}
            style={{ maxWidth: 350 }}
        >
            <Form.Item shouldUpdate noStyle>
                {() => {
                    const { telcell_enabled } = form.getFieldsValue();

                    return (
                        <Form.Item
                            label="Icon"
                            name="telcell_icon"
                            rules={[
                                {
                                    required: telcell_enabled,
                                    message: "Please choose icon",
                                },
                            ]}
                        >
                            <ImageUpload image={icon} setImage={onChangeIcon} />
                        </Form.Item>
                    );
                }}
            </Form.Item>

            <Form.Item label="Enabled" name="telcell_enabled" valuePropName="checked">
                <Switch />
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
                {() => {
                    const { telcell_enabled } = form.getFieldsValue();

                    return (
                        <Form.Item
                            label="Telcell URL"
                            name="telcell_url"
                            rules={[
                                {
                                    required: telcell_enabled,
                                    message: "Please input url",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    );
                }}
            </Form.Item>

            <ButtonComponent
                title="Save"
                actionType="save"
                isLoading={isLoading}
                onClick={() => form.submit()}
            />
        </Form>
    );
}
