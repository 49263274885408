import { Drawer, Tabs } from "antd";

import { useEffect, useState } from "react";

import GaleriesTab from "./components/GaleriesTab";

import InformaitonTab from "./components/InformationTab";
import { useTranslation } from "react-i18next";

export default function CastDrawer({ onClose, open, editable, getData }) {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("informaiton");

  const closeDrawer = () => {
    onClose();
    getData();
  };

  const tabs = [
    {
      label: t("information"),
      key: "informaiton",
      children: (
        <InformaitonTab
          editable={editable}
          getData={getData}
          isOpen={open}
          close={closeDrawer}
        />
      ),
      forceRender: true,
    },

    {
      label: t("galleries"),
      key: "galeries",
      children: <GaleriesTab getData={getData} editable={editable} />,
      disabled: editable ? false : true,
    },
  ];

  useEffect(() => {
    if (!open) setActiveTab("informaiton");
  }, [open]);

  return (
    <Drawer
      width={700}
      title={`${editable ? t("edit") : t("add")} ${t("cast")}`}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Tabs
        tabPosition="top"
        items={tabs}
        activeKey={activeTab}
        onChange={setActiveTab}
      />
    </Drawer>
  );
}
