import { Divider, List, Select, Skeleton } from "antd";
import { useEffect, useState } from "react";

import REQUESTS from "../../../../../../api/requests";

import NewNote from "./NewNote";
import NoteItem from "./NoteItem";
import { useLanguagesOptions } from "../../../../../../hooks/selectOptions";

export default function Notes({ editableMovieId }) {
  const languageOption = useLanguagesOptions();

  const [notes, setNotes] = useState([]);

  const [selectedLanguage, setSelectedLanguage] = useState({
    value: null,
    id: null,
  });

  const getNotes = () => {
    const query = {
      movieId: editableMovieId,
      language_id: selectedLanguage.id,
    };

    REQUESTS.VOD.MEDIA_CONTENT.NOTES.GET(query)
      .then((response) => {
        if (response.error) {
          return;
        }

        const data = response.message.map((item) => {
          return {
            label: item.name,
            value: item.id,
            ...item,
          };
        });

        const newNotes = data?.find((item) => item.value == selectedLanguage.id);

        setNotes(newNotes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getNotes();
  }, [selectedLanguage]);

  useEffect(() => {
    const languageId = languageOption?.find((item) => item.iso_code == "en");
    setSelectedLanguage({
      ...selectedLanguage,
      id: languageId?.id,
    });
  }, [languageOption]);

  return (
    <div>
      <Divider orientation="left" plain>
        <b>Notes</b>
      </Divider>

      <div style={{ marginBottom: 15 }}>
        <label style={{ marginBottom: 5, color: "black" }}>Select Language</label>
        <Select
          showSearch={true}
          filterOption={(input, option) =>
            (option?.label?.toLowerCase() ?? "").includes(input?.toLocaleLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={languageOption}
          value={selectedLanguage?.id}
          placeholder="Select language"
          onChange={(value) => {
            setSelectedLanguage({
              ...selectedLanguage,
              id: value,
            });
          }}
          style={{ width: "100%" }}
        />
      </div>

      <NewNote
        selectedLanguage={selectedLanguage}
        editableMovieId={editableMovieId}
        getData={getNotes}
      />

      <div style={{ marginTop: 20, display: "flex", flexDirection: "column" }}>
        {notes?.not_translations?.map((item) => {
          return <NoteItem item={item} key={item.id} getData={getNotes} />;
        })}
      </div>
    </div>
  );
}
