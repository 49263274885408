import { useState } from "react";

import REQUESTS from "../../../api/requests";

import LauncherAppsTable from "./LauncherAppsTable";
import LauncherAppsDrawer from "./LauncherAppsDrawer";

import PageComponent from "../../../_components/page/PageComponent";

export default function LauncherAppsPage() {
    const [getAgain, setGetAgain] = useState(false);

    const [editable, setEditable] = useState(null);

    const getDataSource = (onSuccess, onError) => {
        REQUESTS.LAUNCHER.APPS.GET()
            .then((response) => {
                onSuccess(response.message);
            })
            .catch((error) => {
                onError(error);
            });
    };

    const handleMenuClick = (event, item) => {
        switch (event.key) {
            case "edit":
                setEditable(item);
                break;

            default:
                break;
        }
    };

    return (
        <PageComponent routes={["Launcher", "Apps"]}>
            <LauncherAppsTable
                getAgain={getAgain}
                getDataSource={getDataSource}
                handleMenuClick={handleMenuClick}
            />

            <LauncherAppsDrawer
                open={editable ? true : false}
                editable={editable}
                getData={() => setGetAgain((prev) => !prev)}
                onClose={() => {
                    setEditable(null);
                }}
            />
        </PageComponent>
    );
}
