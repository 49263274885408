import { useState } from "react";
import { Button, notification } from "antd";
import { ColorPicker, useColor } from "react-color-palette";

import REQUESTS from "../../../api/requests";

import style from "./style.module.scss";

export default function PrimaryColorTab({ data, getDashboardSettings }) {
    const [isLoading, setIsLoading] = useState(false);

    const [color, setColor] = useColor("hex", data.value ? data.value : "green");

    const save = () => {
        setIsLoading(true);

        REQUESTS.SETTINGS.GENERAL.PRIMARY_COLOR({ color: color?.hex })
            .then((response) => {
                if (!response.error) {
                    notification.success({
                        message: "Success",
                        description: "Primary color is updated",
                    });

                    getDashboardSettings();
                }

                setIsLoading(false);
            })

            .catch((err) => {
                notification.error({
                    message: "Error",
                    description: "Something is wrong",
                });

                setIsLoading(false);
            });
    };

    return (
        <div className={style["tab"]}>
            <p className={style["title"]}>{data?.title}</p>

            <p className={style["description"]}>{data?.description}</p>

            <ColorPicker
                width={320}
                height={200}
                color={color}
                onChange={setColor}
                hideHSV
                hideRGB
                dark
            />

            <Button
                type="primary"
                onClick={save}
                loading={isLoading}
                style={{ width: 320, marginTop: 10 }}
            >
                Save
            </Button>
        </div>
    );
}
