import axios from "axios";
import { getToken } from "../server/requests";

const request = async (method, url, params, isTimeout = true, headers, onPorgress) => {
  let options = {
    url,
    method,
    headers: {
      "content-type": "application/json",
      Authorization: getToken(),
    },

    onUploadProgress: (progressEvent) => {
      const total = parseFloat(progressEvent.total);

      const loaded = parseFloat(progressEvent.loaded);

      const progress = Math.floor((loaded / total) * 100);

      if (onPorgress) {
        onPorgress(progress);
      }
    },
  };

  if (isTimeout) {
    options.timeout = 20000;
  }

  if (method.toUpperCase() == "GET") {
    options.params = params;
  } else {
    options.data = params;
  }

  if (headers) {
    options.headers = {
      ...options.headers,
      ...headers,
    };
  }

  try {
    const data = await axios(options).catch((error) => {
      if (error.response && error.response.data) {
        throw new Error(error.response.data);
      } else if (error.request) {
        throw new Error("Server is not responding");
      } else {
        throw new Error(error.message);
      }
    });

    // if (data?.data.error) {
    //     throw new Error({ data: data });
    // }

    // if (response.data.message === "Forbidden") {
    //     window.location.href = "#";
    //     localStorage.clear();
    //     return;
    // }
    if (data?.data) {
      return data.data;
    } else {
      return data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export default request;
