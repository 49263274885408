import { Drawer } from "antd";
import { useEffect, useState } from "react";
import MoviesSearch from "./MoviesSearch";
import ButtonComponent from "../../../../../../_components/button/ButtonComponent";

export default function CustomMoviesDrawer({ open, onClose, onAdd, actualVodService }) {
    const [selectedsIds, setSelectedsIds] = useState([]);

    const add = () => {
        onAdd(selectedsIds);
        onClose();
    };

    useEffect(() => {
        if (!open) {
            setSelectedsIds([]);
        }
    }, [open]);

    return (
        <Drawer width={600} title="Add movies" placement="right" onClose={onClose} open={open}>
            {open && (
                <MoviesSearch
                    selectedsIds={selectedsIds}
                    setSelectedsIds={setSelectedsIds}
                    filter={actualVodService}
                />
            )}

            <div style={{ marginTop: 20, textAlign: "center" }}>
                <ButtonComponent title="Add" actionType="add" onClick={add} />
            </div>
        </Drawer>
    );
}
