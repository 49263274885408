import { useEffect, useState } from "react";
import PageComponent from "../../../_components/page/PageComponent";
import AppColors from "./AppColors";
import REQUESTS from "../../../api/requests";
import { notification } from "antd";

const AppBrandingPage = () => {
  const [colorsData, setColorsData] = useState(null);
  const getColors = () => {
    REQUESTS.BRAND_COLORS.GET({})
      .then((res) => {
        if (!res.error) {
          setColorsData(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleColorChange = (params) => {
    REQUESTS.BRAND_COLORS.UPDATE(params)
      .then((res) => {
        console.log(res);
        notification.success({
          message: "Colors updated successfully",
        });
        getColors();
      })
      .catch((err) => {
        notification.error({
          message: err?.message,
        });
        console.log(err);
      });
  };

  useEffect(() => {
    getColors();
  }, []);

  return (
    <PageComponent routes={["Application", "Branding"]}>
      <div style={{ padding: "20px", width: "35%" }}>
        <AppColors
          colors={colorsData}
          handleColorChange={(params) => handleColorChange(params)}
        />
      </div>
    </PageComponent>
  );
};

export default AppBrandingPage;
