import { Button, Result } from "antd";
const BillingStatusPage = () => (
  <Result
    status="success"
    title="Successfully Purchased Cloud Server !"
    subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
    extra={[
      <Button type="primary" key="console">
        Go Home
      </Button>,
    ]}
  />
);
export default BillingStatusPage;
