import { useEffect, useState } from "react";

import { Drawer, Form, Input, Select, notification } from "antd";

import PlaylistTable from "./PlaylistTable";

import REQUESTS from "../../../../../../api/requests";
import ButtonComponent from "../../../../../../_components/button/ButtonComponent";
import EditableMovieDrawer from "./EditableMovieDrawer";

export default function PlaylistDrawer({ onClose, open, getData, current }) {
    const [getAgain, setGetAgain] = useState(false);

    const [editableMovie, setEditableMovie] = useState(false);

    const getDataSource = (query, onSuccess, onError) => {
        REQUESTS.USERS.USER.PLAYLISTS.ROWS.GET(query)
            .then((response) => {
                onSuccess(response.message);
            })
            .catch((error) => {
                onError(error);
            });
    };

    const handleMenuClick = (event, item) => {
        switch (event.key) {
            case "edit":
                setEditableMovie(item);
                break;

            case "delete":
                REQUESTS.USERS.USER.PLAYLISTS.ROWS.DELETE({ id: item.id })
                    .then((response) => {
                        if (!response.error) {
                            notification.success({
                                message: "Success",
                                description: response.message,
                            });

                            setGetAgain((prev) => !prev);
                        }
                    })
                    .catch((error) => {
                        notification.error({
                            message: "Error",
                            description: error,
                        });
                    });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        setGetAgain((prev) => !prev);
    }, [open]);

    return (
        <Drawer width={700} open={open} placement="right" onClose={onClose} title="Playlist">
            <PlaylistTable
                current={current}
                getAgain={getAgain}
                getDataSource={getDataSource}
                handleMenuClick={handleMenuClick}
            />

            <EditableMovieDrawer
                open={editableMovie ? true : false}
                editable={editableMovie}
                getData={() => setGetAgain((prev) => !prev)}
                onClose={() => {
                    setEditableMovie(null);
                }}
            />
        </Drawer>
    );
}
