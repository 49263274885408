import { useState } from "react";
import styles from "./style.module.scss";
import VerifyForm from "./components/VerifyForm";
import SetPasswordForm from "./components/SetPasswordForm";
import { Form, notification } from "antd";
import ButtonComponent from "../../_components/ButtonComponent";
import CheckByEmail from "./components/CheckByEmail";
import REQUESTS from "../../api/requests";
import { useNavigate } from "react-router";
import PATHS from "../../config/paths";
import { useTranslation } from "react-i18next";
import LanguageDropDown from "../../components/languageDropDown/LanguageDropDown";

const ResetPasswordPage = () => {
  const [form] = Form.useForm();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [activeForm, setActiveForm] = useState(0);
  const [confirmCode, setConfirmCode] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFinish = (value) => {
    setLoading(true);
    if (activeForm == 0) {
      REQUESTS.FORGOT_PASSWORD.RESET({ email: value.email })
        .then((res) => {
          setLoading(false);
          if (!res.error) {
            setActiveForm(1);
            notification.success({
              message: res.message,
            });
          } else {
            notification.error({
              message: res.message,
            });
          }
        })
        .catch((err) => {
          setLoading(false);

          console.log(err);
        });
    } else if (activeForm == 1) {
      REQUESTS.FORGOT_PASSWORD.CONFIRM({ code: value.code })
        .then((res) => {
          setLoading(false);
          if (!res.error) {
            notification.success({
              message: res.message,
            });
            setActiveForm(2);
            console.log(res);
          } else {
            notification.error({
              message: res.message,
            });
          }
        })
        .catch((err) => {
          setLoading(false);

          console.log(err);
        });
    } else if (activeForm == 2) {
      const body = {
        code: form.getFieldValue("code"),
        new_password: value.new_password,
        repeat_password: value.confirm_password,
      };
      REQUESTS.FORGOT_PASSWORD.SET_PASSWORD(body)
        .then((res) => {
          setLoading(false);
          if (!res.error) {
            notification.success({
              message: res.message,
            });
            navigate(PATHS.LOGIN);
          } else {
            notification.error({
              message: res.message,
            });
          }
        })
        .catch((err) => {
          setLoading(false);

          console.log(err);
        });
    }
  };

  const renderForm = () => {
    switch (activeForm) {
      case 0:
        return <CheckByEmail />;
      case 1:
        return <VerifyForm onChange={setConfirmCode} />;
      case 2:
        return <SetPasswordForm />;

      default:
        return null;
    }
  };

  const handleBack = () => {
    navigate(PATHS.LOGIN);
  };

  return (
    <div className={styles["reset-password"]}>
      {/* <div className={styles["language-selector"]}>
        <LanguageDropDown />
      </div> */}
      <div className={styles["back"]} onClick={handleBack}>
        <i className="fa fa-arrow-left" style={{ marginLeft: 10 }} />
        <span>{t("login")}</span>
      </div>
      <div className={styles["reset-form-wrapper"]}>
        <h2>{t("reset_password")}</h2>
        <Form form={form} onFinish={handleFinish} layout="vertical">
          {renderForm()}
        </Form>
        <div className={styles["reset-form-button"]}>
          <ButtonComponent
            title={activeForm == 0 ? t("send") : t("reset")}
            onClick={() => form.submit()}
            style={{
              width: "100%",
            }}
            isLoading={loading}
          />
          {activeForm > 0 && (
            <div
              className={styles["back-step"]}
              onClick={() => {
                setActiveForm((prev) => prev - 1);
              }}
            >
              {t("back")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
