import { Tabs } from "antd";

import VodTab from "./tabs/VodTab";

import OthersTab from "./tabs/OthersTab";

import LiveTvTab from "./tabs/LiveTvTab";

import PageComponent from "../../../_components/page/PageComponent";

export default function CdnPage() {
  const tabs = [
    {
      label: "Vod",
      key: "vod",
      children: <VodTab />,
    },

    {
      label: "Live Tv",
      key: "live_tv",
      children: <LiveTvTab />,
    },

    {
      label: "Others",
      key: "others",
      children: <OthersTab />,
    },
  ];

  return (
    <PageComponent routes={["Settings", "CDN"]}>
      <div
        style={{
          width: "40%",
        }}
      >
        <Tabs tabPosition="left" items={tabs} />
      </div>
    </PageComponent>
  );
}
