import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";

export default function svodConfirm(title, confirmFunction) {
    Modal.confirm({
        title: title,
        icon: <ExclamationCircleFilled />,
        content: "Are you sure you want to continue?",
        onOk() {
            confirmFunction();
        },
        onCancel() {
            console.log("Cancel");
        },
    });
}
