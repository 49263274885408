import { Tabs } from "antd";
import PageComponent from "../../_components/page/PageComponent";
import GeneralTab from "./tabs/general/GeneralTab";
import VodTab from "./tabs/vod/VodTab";
import LiveTvTab from "./tabs/live-tv/LiveTvTab";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function DashboardPage() {
  const { permissions } = useSelector((state) => state.globalState);

  const { t } = useTranslation();

  const [tabs, setTabs] = useState([]);

  const defaultTab = [
    {
      label: t("general"),
      key: "general",
      children: <GeneralTab permissions={permissions} />,
      forceRender: true,
    },
  ];

  useEffect(() => {
    const tabObj = {};
    if (permissions?.LIVE_TV) {
      tabObj["live_tv"] = {
        label: t("live_tv"),
        key: "live_tv",
        children: <LiveTvTab />,
      };
    }
    if (permissions?.Vod_Manager) {
      tabObj["vod"] = {
        label: t("vod"),
        key: "vod",
        children: <VodTab />,
      };
    }

    for (const key in tabObj) {
      defaultTab.push(tabObj[key]);
      setTabs(defaultTab);
    }
  }, [permissions, t]);

  return (
    <PageComponent>
      <Tabs tabPosition="top" items={tabs} />
    </PageComponent>
  );
}
