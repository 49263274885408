import { useEffect, useState } from "react";
import { Badge, Popover, Tag, Popconfirm } from "antd";
import Icon from "@ant-design/icons";
import dayjs from "dayjs";

import PositionComponent from "../../../_components/PositionComponent";
import TableComponent from "../../../_components/table/TableComponent";
import {
  getColumnSearchProps,
  getColumnDateProps,
  parseDate,
  getQueryDate,
} from "../../../config/config";
import TableButtons from "../../../_components/table/TableButtons";
import ICONS from "../../../config/icons";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import {
  getAtLocal,
  getAtSession,
  setInLocal,
  setInSession,
} from "../../../helpers/storages";
import { asc_desc } from "../../../helpers/ascDesc";

const returnChannelsList = (channels) => {
  return (
    <div
      style={{
        maxWidth: "800px",
        width: "max-content",
        gap: 10,
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {channels.map((channel) => {
        return (
          <>
            <Tag>{channel.name}</Tag>
          </>
        );
      })}
    </div>
  );
};

export default function LavieTvCategoriesTable({
  getAgain,
  openDrawer,
  getDataSource,
  handleMenuClick,
  onChangePosition,
  handleBulkDelete,
}) {
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(getAtLocal("pageLimit_liveTvCategories") || 10);

  const [currentPage, setCurrentPage] = useState(
    getAtSession("liveTvCategories")?.page || 1
  );

  const [sort, setSort] = useState(
    getAtSession("liveTvCategories")?.sort || ["id", "DESC"]
  );

  const [total, setTotal] = useState(0);

  const [date, setDate] = useState(null);

  const [search, setSearch] = useState(
    getAtSession("liveTvCategories")?.search || {
      name: null,
    }
  );

  const [selectionRowKeys, setSelectionRowKeys] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [isEmpty, setIsEmpty] = useState(false);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: "Position",
      defaultSortOrder:
        getAtSession("liveTvCategories")?.sort?.[0] === "position" &&
        asc_desc(getAtSession("liveTvCategories")?.sort?.[1]),
      dataIndex: `position`,
      align: "center",
      key: "position",
      sorter: true,
      render: (text, record, index) => {
        return (
          <PositionComponent
            position={record.position}
            onChangePosition={(e) => onChangePosition(e, record.id)}
          />
        );
      },
    },

    {
      title: "Name",
      defaultFilteredValue: getAtSession("liveTvCategories")?.search?.name,
      filteredValue: getAtSession("liveTvCategories")?.search?.name,
      dataIndex: "name",
      key: "name",
      align: "center",
      ...getColumnSearchProps(),
    },

    {
      title: "Status",
      defaultSortOrder:
        getAtSession("liveTvCategories")?.sort?.[0] === "is_protected" &&
        asc_desc(getAtSession("liveTvCategories")?.sort?.[1]),
      dataIndex: "is_protected",
      key: "is_protected",
      align: "center",
      sorter: true,
      width: 150,
      render: (text, record, index) => {
        return record.is_protected ? (
          <Tag color="green">Protected</Tag>
        ) : (
          <Tag color="volcano">No Protected</Tag>
        );
      },
    },

    {
      title: "Channels",
      dataIndex: "channels",
      key: "channels",
      align: "center",
      ellipsis: true,

      render: (text, record, index) => {
        const channelsNames = record.channels.map((channel) => {
          return channel.name;
        });

        const list = channelsNames.join(", ");

        return (
          <Popover
            content={() => returnChannelsList(record.channels)}
            title="Channels"
            trigger="hover"
          >
            {list}
          </Popover>
        );
      },
    },

    {
      title: `Created date`,
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",

      render: (text, record, index) => {
        return parseDate(text);
      },

      ...getColumnDateProps(setDate),
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: ICONS.EDIT,
            },

            record.is_protected
              ? {
                  key: "unprotect",
                  name: "Unprotect",
                  icon: ICONS.UNPROTECT,
                  question: `Do you want to unprotect this category?`,
                  onConfirm: (e) => {
                    handleMenuClick(e, record);
                  },
                }
              : {
                  key: "protect",
                  name: "Protect",
                  icon: ICONS.PROTECT,
                  question: `Do you want to protect this category?`,
                  onConfirm: (e) => {
                    handleMenuClick(e, record);
                  },
                },

            record.archive
              ? {
                  key: "restore",
                  name: "Restore",
                  icon: ICONS.RESTORE,
                  question: `Do you want to restore this category?`,
                  onConfirm: (e) => {
                    handleMenuClick(e, record);
                  },
                }
              : {
                  key: "archive",
                  name: "Archive",
                  icon: ICONS.ARCHIVE_ICON,
                  question: `Do you want to archive this category?`,
                  onConfirm: (e) => {
                    handleMenuClick(e, record);
                  },
                },

            record.archive && {
              key: "delete",
              name: "Delete",
              icon: ICONS.DELETE,
              question: `Do you want to delete this category?`,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    const liveTvCategories = {
      page: data.page,
      // search: data.search,
    };
    if (data.sort) {
      liveTvCategories.sort = [`${data.sort[0]}`, `${data.sort[1]}`];
    }
    setInLocal("pageLimit_liveTvCategories", data.limit);
    setInSession("liveTvCategories", liveTvCategories);

    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data?.search);
  };

  const handleDelete = () => {
    setOpenConfirm(true);
  };

  useEffect(() => {
    const fetchLiveTvData = () => {
      setLoading(true);

      const query = {
        // sort,
        limit,
        page: currentPage,
      };

      if (sort && sort[1] && sort[1] != "undefined") {
        query.sort = sort;
      } else {
        query.sort = ["id", "DESC"];
      }

      if (search.name) {
        query.search = {
          name: search.name,
        };
      }

      if (search.categories) {
        query.filter = {
          categoryId: search.categories,
        };
      }

      function onSuccess(data) {
        setLoading(false);

        if (data?.rows.length > 0 || search.name) {
          setDataSource(data?.rows);
          setIsEmpty(false);
        } else {
          setIsEmpty(true);
        }

        setTotal(data?.count);

        const maxPage = Math.ceil(data?.count / limit);
        const storedPage = getAtSession("liveTvCategories")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("liveTvCategories", {
            ...getAtSession("liveTvCategories"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("liveTvCategories", {
            ...getAtSession("liveTvCategories"),
            page: currentPage,
          });
        }
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (
        getAtSession("liveTvCategories") &&
        getAtSession("liveTvCategories")?.page > currentPage
      ) {
        setInSession("liveTvCategories", {
          ...getAtSession("liveTvCategories"),
          page: 1,
        });
        setCurrentPage(getAtSession("liveTvCategories")?.page);
      }

      fetchLiveTvData();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, sort, search, currentPage, getAgain]);

  return (
    <TableComponent
      header={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            gap: 10,
          }}
        >
          <ButtonComponent icon={ICONS.ADD} title="Add Category" onClick={openDrawer} />
          <>
            {selectionRowKeys.length > 0 && (
              <>
                <ButtonComponent
                  icon={ICONS.DELETE}
                  title="Delete"
                  onClick={() => handleDelete()}
                />
                <Popconfirm
                  title="Are you sure to delete these items?"
                  onConfirm={(e) => {
                    const selected = selectionRowKeys.map((item) => {
                      return item.id;
                    });
                    const parsSelected = {
                      ids: selected.join(),
                    };
                    handleBulkDelete(parsSelected.ids);

                    setOpenConfirm(false);
                    setSelectionRowKeys([]);
                  }}
                  okText="delete"
                  cancelText="Cancel"
                  open={openConfirm}
                  onCancel={() => {
                    setOpenConfirm(false);
                  }}
                />
              </>
            )}
          </>
        </div>
      }
      isLoading={loading}
      dataSource={dataSource}
      isEmpty={isEmpty}
      onChange={handleTableChange}
      columns={columns}
      localeClick={openDrawer}
      rowClassName={(row) => {
        if (row.archive) return "red-row";
      }}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      rowSelection={{
        type: "checkbox",
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectionRowKeys(selectedRows);
        },
      }}
    />
  );
}
