import { Select, Space, Spin } from "antd";
import { useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";

import { host } from "../../../../../../server/url";

import { getToken, jsonToGetUrl } from "../../../../../../server/requests";
import { liveTvServiceId } from "../../../../../../config/statics";

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
        <Select
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            // options={options}
        >
            {options.map((item) => {
                return (
                    <Select.Option key={item.value} value={item.value} label={item.label}>
                        <Space>
                            <img
                                src={item.image}
                                style={{ width: 20, height: 20, borderRadius: "50%" }}
                            />
                            {item.label}
                        </Space>
                    </Select.Option>
                );
            })}
        </Select>
    );
}

// Usage of DebounceSelect

async function getLiveTv(search, filter) {
    let query = {
        limit: 50,
        enabled: true,
        search: { name: search },
    };
    return fetch(
        `${host}admin/tariff/content${jsonToGetUrl({
            query: JSON.stringify(query),
            filter: JSON.stringify(filter),
            serviceId: liveTvServiceId,
            search: true,
            token: getToken(),
        })}`
    )
        .then((response) => response.json())
        .then((body) => {
            return body.message?.rows?.map((item) => ({
                value: item.id,
                label: item.name,
                image: item.image,
            }));
        });
}

export default function LiveTvSearch({ selectedsIds, setSelectedsIds, filter }) {
    return (
        <DebounceSelect
            mode="multiple"
            value={selectedsIds}
            placeholder="Select live tv"
            fetchOptions={(search) => getLiveTv(search, filter)}
            onChange={(newValue) => {
                setSelectedsIds(newValue);
            }}
            style={{
                width: "100%",
            }}
        />
    );
}
