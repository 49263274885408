import { useState } from "react";
import { notification } from "antd";

import REQUESTS from "../../../api/requests";

import LanguagesTable from "./LanguagesTable";
import LanguageDrawer from "./LanguageDrawer";

import PageComponent from "../../../_components/page/PageComponent";

export default function LanguagesPage() {
    const [getAgain, setGetAgain] = useState(false);

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);

    const getDataSource = (query, onSuccess, onError) => {
        REQUESTS.APPLICATION.LANGUAGES.GET({ query: JSON.stringify(query) })
            .then((response) => {
                onSuccess(response.message);
            })
            .catch((error) => {
                onError(error);
            });
    };

    const onDisable = (id) => {
        REQUESTS.APPLICATION.LANGUAGES.DISABLE({ id })
            .then((response) => {
                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });

                    return;
                }

                setGetAgain((prev) => !prev);

                notification.success({
                    message: "Success",

                    description: "Language disabled successfully",
                });
            })

            .catch((error) => {
                notification.error({
                    message: "Error",

                    description: error,
                });
            });
    };

    return (
        <PageComponent routes={["Application", "Languages"]}>
            <LanguagesTable
                getAgain={getAgain}
                getDataSource={getDataSource}
                openDrawer={() => setIsOpenDrawer(true)}
                onDisable={onDisable}
            />

            <LanguageDrawer
                open={isOpenDrawer}
                getData={() => setGetAgain((prev) => !prev)}
                onClose={() => {
                    setIsOpenDrawer(false);
                }}
            />
        </PageComponent>
    );
}
