import REQUESTS from "../../../api/requests";

import PageComponent from "../../../_components/page/PageComponent";

import PaymentHistoryTable from "./PaymentHistoryTable";

export default function PaymentHistoryPage() {
    const getDataSource = (query, onSuccess, onError) => {
        REQUESTS.PAYMENTS.HISTORY.GET(query)
            .then((response) => {
                onSuccess(response.message);
            })
            .catch((error) => {
                onError(error);
            });
    };

    return (
        <PageComponent routes={["Payment", "History"]}>
            <PaymentHistoryTable getDataSource={getDataSource} />
        </PageComponent>
    );
}
