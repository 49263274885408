import { message, notification, Upload } from "antd";

import { InboxOutlined } from "@ant-design/icons";

import REQUESTS from "../../../../../../api/requests";

const { Dragger } = Upload;

export default function DocumentUploader({ id, getUser }) {
    const uploadDocument = async (options) => {
        const { onSuccess, file } = options;

        const formData = new FormData();

        formData.append("id", id);
        formData.append("document", file);

        REQUESTS.USERS.USER.DOCUMENT.ADD(formData).then((response) => {
            onSuccess("Ok");
            getUser();
            notification.success({
                message: "Success",
                description: "Document added is successfuly",
            });
        });
    };

    return (
        <div style={{ width: 230, height: 200 }}>
            <Dragger
                name="file"
                multiple={true}
                headers={{
                    id: id,
                }}
                showUploadList={false}
                customRequest={uploadDocument}
                onChange={(info) => {
                    const { status } = info.file;
                    if (status !== "uploading") {
                    }
                    if (status === "done") {
                    } else if (status === "error") {
                        message.error(`${info.file.name} file upload failed.`);
                    }
                }}
                onDrop={(e) => {}}
                style={{ padding: 20 }}
            >
                <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Dragger>
        </div>
    );
}
