import styles from "./chart.module.scss";
import { Radio } from "antd";

export default function ChartComponent({
  title,
  children,
  radioGroup,
  selectedTab,
  dataMark,
  markGroup,
}) {
  return (
    <div className={styles["chart-component"]}>
      <div className={styles["title"]}>{title}</div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        {radioGroup && (
          <Radio.Group
            defaultValue={radioGroup[2]}
            buttonStyle="solid"
            style={{ marginBottom: 25 }}
            size="small"
          >
            {radioGroup?.map((item, ind) => {
              return (
                <Radio.Button
                  key={item?.id}
                  value={item}
                  onClick={() => selectedTab(item)}
                >
                  {item?.name}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        )}

        {markGroup && (
          <Radio.Group
            buttonStyle="solid"
            size="small"
            defaultValue={markGroup[0] || [0]}
          >
            {markGroup?.map((item, idx) => {
              return (
                <Radio.Button key={idx} value={item} onClick={() => dataMark(item)}>
                  {item.name}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        )}
      </div>
      {children}
    </div>
  );
}
