import { createContext, useEffect, useState } from "react";
import PageComponent from "../../../_components/page/PageComponent";
import { Form, notification, Tabs } from "antd";
import MetaDataStep from "./steps/meta-data/MetaDataStep";
import SeasonsEpisodesStep from "./steps/seasons-episodes/SeasonsEpisodesStep";
import DetailsStep from "./steps/details/DetailsStep";
import generateMediaFormData from "./logic/generateMediaFormData";
import mediaInitialValues from "./logic/mediaInitialValues";
import GalleryStep from "./steps/gallery/GalleryStep";
import RestrictionMonetizationStep from "./steps/restriction-monetization/RestrictionMonetizationStep";
import StreamStep from "./steps/stream/StreamStep";
import REQUESTS from "../../../api/requests";
import redirectToStepOfTheErrorField from "./logic/redirectToStepOfTheErrorFiled";
import setEditableMediaInfo from "./logic/setEditableMediaInfo";
import onStep from "./logic/onStep";
import { useNavigate, useParams } from "react-router";
import {
  requestAddOrUpdateSeason,
  requestEpisodesById,
  requestGenresById,
  requestMovieById,
  requestNotesById,
  requestRatingById,
  requestSeasonsById,
  requestDeleteSeason,
  requestAddOrUpdateSerie,
} from "../../../server/requests";
import AddSeasonDrawer from "./steps/seasons-episodes/drawer/AddSeasonDrawer";
import AddEpisodeDearer from "./steps/seasons-episodes/drawer/AddEpisodeDrawer";
import { getAtLocal } from "../../../helpers/storages";

import ButtonComponent from "../../../components/ButtonComponent";
import Loading from "../../../_components/loadings/Loading";

import Player from "../medias/components/Player";
import { createPortal } from "react-dom";

export const TabContext = createContext();

export default function MediaContentPage() {
  const [form] = Form.useForm();

  const { id } = useParams();
  const navigate = useNavigate();

  const [step, setStep] = useState("details");

  const [editable, setEditable] = useState({
    movie: null,
    details: null,
    gallery: null,
    meta_data: null,
    restriction_monetization: null,
    stream: null,
    seasons_episodes: null,
    preview: null,
  });
  const [editableSeasonId, setEditableSeasonId] = useState(null);

  const [season, setSeason] = useState(null);
  const [episode, setEpisode] = useState(null);

  const [mediaType, setMediaType] = useState("");
  const [getAgain, setGetAgain] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAddSeason, setIsAddSeason] = useState(false);
  const [isAddEpisode, setIsAddEpisode] = useState(false);

  const [selectedEpisodeId, setSelectedEpisodeId] = useState(null);
  const [selectedSeasonId, setSelectedSeasonId] = useState(null);

  const [videoCourse, setVideoCourse] = useState(null);
  const [showEpisodeModal, setShowEpisodeModal] = useState(false);
  const [introStartTime, setIntroStartTime] = useState(0);
  const [introEndTime, setIntroEndTime] = useState(0);
  const [creditsTime, setCreditsTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [originalAudioLanguage, setOriginalAudioLanguage] = useState("");
  const [translationsSeason, setTranslationsSeason] = useState([]);
  const [translationsEpisode, setTranslationEpisode] = useState([]);
  const [genresList, setGenresList] = useState([]);

  const [transcodingId, setTranscodingId] = useState(null);
  const [transcodingStreamId, setTranscodingStreamId] = useState(null);
  const [trailerUrl, setTrailerUrl] = useState(null);
  const [streamUrl, setStreamUrl] = useState(null);
  const [movieStreamType, setMovieStreamType] = useState(null);
  const [trailerStreamType, setTrailerStreamType] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [selectOnTMDB, setSelectOnTMDB] = useState(false);

  const [validTranscodingId, setValidTranscodingId] = useState(false);

  const editableMovieId = id;
  const editSeasonId = season?.find((s) => s.id === editableSeasonId)?.id;

  const onFinish = (values) => {
    const body = generateMediaFormData({ values, form, editableMovieId, mediaType });
    setIsLoading(true);

    console.log("body", validTranscodingId, values);

    if (
      !validTranscodingId &&
      mediaType === "movie" &&
      values.stream_type === "internal"
    ) {
      setIsLoading(false);
      notification.error({
        message: "Error",
        description: "Internal source with this transcoding id not found",
      });
      return;
    } else if (mediaType === "tv_show" && !season.length) {
    }

    if (editableMovieId) {
      body.append("id", editableMovieId);
      body.type = mediaType;
      REQUESTS.VOD.MEDIA_CONTENT.EDIT(body)
        .then((response) => {
          setIsLoading(false);
          if (response.error) {
            notification.error({
              message: "Error",
              description: response.error,
            });

            setIsLoading(false);
            return;
          }

          notification.success({
            message: "Success",
            description: "Media content updated successfully",
          });
          setGetAgain((prev) => !prev);
        })
        .catch((error) => {
          setIsLoading(false);
          notification.error({
            message: "Error",
            description: "Something went wrong",
          });
        });
    } else {
      REQUESTS.VOD.MEDIA_CONTENT.ADD(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              message: "Error",
              description: response.error,
            });
            setGetAgain((prev) => !prev);

            return;
          }
          setIsLoading(false);

          notification.success({
            message: "Success",
            description: "Media content added successfully",
          });
          navigate(`/dashboard/media_content/${response.message.id}`);
        })
        .catch((error) => {
          setIsLoading(false);
          notification.error({
            message: "Error",
            description: "Something went wrong",
          });
        });
    }
  };

  const removeSeason = (seasonId) => {
    requestDeleteSeason(seasonId, (response) => {
      if (response.error) {
        notification.error({
          message: "Error",
          description: response.error,
        });

        return;
      }
      setGetAgain((prev) => !prev);
      notification.success({
        message: "Success",
        description: "Season deleted successfully",
      });
    });
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    redirectToStepOfTheErrorField(errorFields, setStep);
  };

  const onChangeStep = (type) => {
    onStep(step, type, mediaType, setStep);
  };

  const getMediaInfo = async () => {
    const queries = {
      movieDetails: { id },
      ratings: { id },
      genres: { id },
      season: { id },
      notes: { id },
    };

    try {
      const [movie, rating, genre, seasons, notes] = await Promise.all([
        fetchData(requestMovieById, queries.movieDetails),
        fetchData(requestRatingById, queries.ratings),
        fetchData(requestGenresById, queries.genres),
        fetchData(requestSeasonsById, queries.season),
        fetchData(requestNotesById, queries.notes),
      ]);

      setSeason(seasons);

      let newGenres = genre?.map(
        (genre) =>
          (genre = {
            label: genre.name,
            value: genre.id,
          })
      );

      if (movie) {
        const dataObj = {
          movie: movie,
          genres: newGenres,
          notes: notes,
          rating: rating,
          season: seasons,
        };

        setEditableMediaInfo(dataObj, form);

        setEditable(movie);
        setMediaType(movie.type);
        setTranscodingId(movie.trailer_transcoding_id);
        setTrailerUrl(movie.trailer_url);
        setStreamUrl(movie.stream_url);
        setTranscodingStreamId(movie?.transcoding_id);
        getMonetization(movie.content_monetization_type);
        setMovieStreamType(movie.stream_type);
        setTrailerStreamType(movie.trailer_stream_type);
        setOriginalAudioLanguage(movie.original_audio_language);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const fetchData = async (requestFunction, query) => {
    if (!requestFunction) return;
    return await new Promise((resolve, reject) => {
      requestFunction(query, (data) => {
        if (data) {
          resolve(data || []);
        } else {
          reject(new Error("Data not available"));
        }
      });
    });
  };

  const geEpisodes = async (query, successCallback, errorCallback) => {
    try {
      const seasonPromises = season?.map(async (season) => {
        setEditableSeasonId(season.id);
        try {
          const episodes = await fetchData(requestEpisodesById, {
            query: JSON.stringify(query),
          });
          setEpisode(episodes.rows);
          return episodes;
        } catch (error) {
          console.error("Error:", error);
        }
      });

      await Promise.all(seasonPromises)
        .then((episodes) => {
          successCallback(episodes);
        })
        .catch((error) => {
          errorCallback(error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const addOrUpdateSeason = (params, type) => {
    const paramsObj = {
      movieId: editableMovieId,
      id: editSeasonId,
      ...params,
    };

    if (editableMovieId) {
      requestAddOrUpdateSeason(type, paramsObj, (data) => {
        setIsAddSeason(false);

        setGetAgain((prev) => !prev);
      });
    }
  };

  const addOrUpdateEpisode = (selectedSeasonId, type, params, cb) => {
    requestAddOrUpdateSerie(type, params, (data) => {
      setGetAgain((prev) => !prev);
      setEditableSeasonId(selectedSeasonId);

      setIsAddEpisode(false);
      cb(data);
      setIsEdit(false);
    });
  };

  const removeEpisodes = (seasonId) => {
    requestAddOrUpdateSerie("delete", { id: seasonId }, (data) => {
      setGetAgain((prev) => !prev);
    });
  };

  const onFieldsChange = (changedFields, allFields) => {
    switch (changedFields[0].name[0]) {
      case "type":
        setMediaType(changedFields[0].value);
        break;

      default:
        break;
    }
  };

  const handlePlayerSourced = (src) => {
    setVideoCourse(src.stream_url);
    setCreditsTime(src.credits_time);
    setIntroStartTime(src.intro_start_time);
    setIntroEndTime(src.intro_end_time);
    setDuration(src.duration);
  };

  const getSeasonTranslation = (id, model) => {
    REQUESTS.VOD.MEDIA_CONTENT.TRANSLATIONS.GET(id, model)
      .then((res) => {
        if (model == "season") {
          setTranslationsSeason(res.message);
        }

        if (model == "episode") {
          setTranslationEpisode(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setSeasonTranslation = (body, cb, err) => {
    REQUESTS.VOD.MEDIA_CONTENT.TRANSLATIONS.ADD(JSON.stringify(body))
      .then((res) => {
        cb(res);
      })
      .catch((err) => {
        err(err);
        console.log(err);
      });
  };

  const getMonetization = (type) => {
    switch (type) {
      case "svod":
        REQUESTS.VOD.MEDIA_CONTENT.ADVERTISEMENT.SVOD(editableMovieId)
          .then(({ message }) => {
            const newTariffList = message?.map((tariff) => tariff?.id);
            form.setFieldsValue({ tariffIds: newTariffList });
          })
          .catch((error) => {
            console.log(error);
            notification.error({
              message: "Error",
              description: "Something went wrong",
            });
          });
        break;
      case "free":
        REQUESTS.VOD.MEDIA_CONTENT.ADVERTISEMENT.AVOD(editableMovieId)
          .then((response) => {
            const newAdList = response?.message?.map((list) => {
              if (list.type === "text") {
                return {
                  name: list.text,
                  value: list.id,
                };
              } else {
                return {
                  name: list.url,
                  value: list.id,
                };
              }
            });
            form.setFieldsValue({ ads_id: newAdList });
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: "Something went wrong",
            });
          });

        break;

      default:
        break;
    }
  };

  const getListOfGenre = (callback) => {
    REQUESTS.VOD.MEDIA_CONTENT.GENRES.GET()
      .then((res) => {
        const list = res.message.data.map((item) => ({
          label: item.name,
          value: item.name,
          isProtected: item.is_protected,
        }));
        setGenresList(list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (editableMovieId) {
      getMediaInfo();
      form.setFieldsValue({ id: editableMovieId });
    }
  }, [getAgain, editableMovieId]);

  useEffect(() => {
    if (!editableMovieId) setMediaType(getAtLocal("mediaType"));
    getListOfGenre();
    form.setFields([
      { name: "trailer_stream_type", value: "external" },
      { name: "stream_type", value: "external" },
    ]);
  }, []);

  const tabs = [
    {
      label: "Details",
      key: "details",
      forceRender: true,
      children: (
        <DetailsStep
          form={form}
          editableMovieId={editableMovieId}
          onChangeStep={onChangeStep}
        />
      ),
    },

    {
      label: "Gallery",
      key: "gallery",
      forceRender: true,
      children: (
        <GalleryStep
          form={form}
          onChangeStep={onChangeStep}
          editable={editable}
          getAgain={getAgain}
          onSelectTmdb={selectOnTMDB}
        />
      ),
    },

    {
      label: "Meta data",
      key: "meta-data",
      forceRender: true,
      children: (
        <MetaDataStep
          form={form}
          mediaType={mediaType}
          onChangeStep={onChangeStep}
          editableMovieId={editableMovieId}
          trailerUrl={trailerUrl}
        />
      ),
    },

    mediaType === "movie" && {
      label: "Stream",
      key: "stream",
      forceRender: true,
      children: (
        <StreamStep
          form={form}
          onChangeStep={onChangeStep}
          transcodingId={transcodingStreamId}
          mediaType={mediaType}
          streamUrl={streamUrl}
          originalAudioLAnguage={originalAudioLanguage}
        />
      ),
    },

    {
      label: "Restriction & Monetization",
      key: "restriction_monetization",
      forceRender: true,
      children: <RestrictionMonetizationStep form={form} onChangeStep={onChangeStep} />,
    },

    mediaType === "tv_show" &&
      editableMovieId && {
        label: "Series & Episodes",
        key: "series_episodes",
        forceRender: true,
        children: (
          <SeasonsEpisodesStep
            form={form}
            onChangeStep={onChangeStep}
            season={season}
            episodeSourced={geEpisodes}
            getAgain={getAgain}
            editableId={id}
            removeSeason={(seasonId) => removeSeason(seasonId)}
            removeEpisodes={(seasonId) => removeEpisodes(seasonId)}
            playerSourced={(src) => handlePlayerSourced(src)}
            sowModal={() => {
              setShowEpisodeModal(true);
            }}
            showSeasonDrawer={(key) => {
              if (key === "edit") {
                setIsEdit(true);
              } else if (key === "add") {
                setIsEdit(false);
              }
              setIsAddSeason(true);
            }}
            showAddEpisodeDrawer={(key, selectedSeasonId) => {
              if (key === "edit") {
                setIsEdit(true);
                setSelectedEpisodeId(selectedSeasonId);
              } else if (key === "add") {
                setIsEdit(false);
                setSelectedSeasonId(selectedSeasonId);
              }
              setIsAddEpisode(true);
            }}
            seasonId={(seasonId) => {
              setEditableSeasonId(seasonId);
            }}
          />
        ),
      },
  ];

  return (
    <>
      <PageComponent
        routes={["Vod", "Medias", mediaType.split("_").join(" ")]}
        backPath="/dashboard/medias"
      >
        <Form
          form={form}
          name="media-content"
          layout="vertical"
          onFinish={onFinish}
          // onKeyPress={(e) => {
          //   if (e.key === "Enter") {
          //     form.submit();
          //   }
          // }}
          onFieldsChange={onFieldsChange}
          onFinishFailed={onFinishFailed}
          initialValues={mediaInitialValues}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 10,
            }}
          >
            <ButtonComponent
              type="save_settings"
              htmlType="submit"
              style={{ color: "#000" }}
              onClick={() => form.submit()}
            />
          </div>
          <TabContext.Provider
            value={{
              streamUrl,
              setStreamUrl,
              trailerUrl,
              setTrailerUrl,
              transcodingId,
              movieStreamType,
              trailerStreamType,
              mediaType,
              genresList,
              setSelectOnTMDB,
              setValidTranscodingId,
            }}
          >
            <Tabs tabPosition="left" items={tabs} activeKey={step} onChange={setStep} />
          </TabContext.Provider>
        </Form>
        {isAddSeason && (
          <AddSeasonDrawer
            show={isAddSeason}
            close={() => {
              setIsAddSeason(false);
              setIsEdit(false);
            }}
            addOrUpdateSeason={(season, type) => addOrUpdateSeason(season, type)}
            isEditMode={isEdit}
            initialValues={season}
            selectedSeasonId={editableSeasonId}
            getTranslation={(id, model) => getSeasonTranslation(id, model)}
            translations={translationsSeason}
            addSeasonTranslation={(body, cb, err) => setSeasonTranslation(body, cb, err)}
          />
        )}

        {isAddEpisode && (
          <AddEpisodeDearer
            show={isAddEpisode}
            close={() => {
              setIsAddEpisode(false);
              setIsEdit(false);
            }}
            isEditMode={isEdit}
            initialValues={episode}
            selectedEpisodeId={selectedEpisodeId}
            addOrUpdateEpisode={(selectedSeasonId, episode, type, cb) =>
              addOrUpdateEpisode(selectedSeasonId, episode, type, cb)
            }
            seasonId={selectedSeasonId}
            showPlayerPopup={(src) => handlePlayerSourced(src)}
            getTranslation={(id, model) => getSeasonTranslation(id, model)}
            translations={translationsEpisode}
            addEpisodeTranslation={(body, cb, err) => setSeasonTranslation(body, cb, err)}
            validTranscodingId={setValidTranscodingId}
          />
        )}
        {createPortal(
          <Player
            type="hls"
            src={videoCourse}
            isVisible={showEpisodeModal}
            hideModal={() => {
              setVideoCourse(null);
              setShowEpisodeModal(false);
            }}
            showVideoAudioSubtitleTracks={true}
            useToken={movieStreamType === "internal" ? true : false}
          />,
          document.body
        )}
      </PageComponent>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 100,
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, .6)",
          }}
        >
          <Loading
            size="large"
            style={{
              height: "100%",
            }}
          />
        </div>
      )}
    </>
  );
}
