import { useEffect, useState } from "react";

import { Button, Modal, notification, Table, Tooltip } from "antd";

import { QuestionOutlined } from "@ant-design/icons";

import REQUESTS from "../../../api/requests";

import TableButtons from "../../../components/TableButtons";
import FaqDrawer from "./FaqDrawer";
import {
  getColumnDateProps,
  getColumnSearchProps,
  getQueryDate,
  parseDate,
} from "../../../config/config";
import TableComponent from "../../../_components/table/TableComponent";
import PageComponent from "../../../_components/page/PageComponent";

export default function FaqPage({}) {
  const [list, setList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  const [current, setCurrent] = useState(null);

  const [total, setTotal] = useState(0);

  const [limit, setLimit] = useState(10);

  const [date, setDate] = useState("");

  const [sort, setSort] = useState(["id", "DESC"]);

  const [search, setSearch] = useState({
    question: null,
    answer: null,
  });

  const [currentPage, setCurrentPage] = useState(1);

  const [isOpenFaqDrawer, setIsOpenFaqDrawer] = useState(false);

  useEffect(() => {
    let timeout = setTimeout(() => {
      getFaq();
    }, [500]);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, sort, search, currentPage, date]);

  const getFaq = () => {
    setIsLoading(true);

    const query = {
      limit,
      page: currentPage,
      // sort,
    };

    const queryDate = getQueryDate(date);

    if (queryDate) query.between = queryDate;

    if (search.question) {
      query.search = {
        ...query.search,
        question: search.question[0],
      };
    }

    if (search.answer) {
      query.search = {
        ...query.search,
        answer: search.answer[0],
      };
    }

    if (sort && sort[1] && sort[1] !== "undefined") {
      query.sort = sort;
    } else {
      query.sort = ["id", "DESC"];
    }

    REQUESTS.WEB_PAGE.FAQ.GET({ query: JSON.stringify(query) })
      .then((response) => {
        setIsLoading(false);

        if (!response.error) {
          if (response?.message?.rows?.length === 0 && currentPage > 1) {
            setCurrentPage(currentPage - 1);
            return;
          }

          const hasSearchKeyWithValue = () => {
            const keys = Object.keys(search);

            return keys.some((key) => search[key] && search[key][0]);
          };

          if (response?.message?.rows?.length > 0 || hasSearchKeyWithValue()) {
            setList(response?.message?.rows);
            setIsEmpty(false);
          } else {
            setIsEmpty(true);
          }

          setTotal(response?.message?.total);
          setLimit(response?.message?.limit);
          setCurrentPage(response?.message?.page);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "delete":
        Modal.confirm({
          title: "Do you want to delete this answer",

          icon: <QuestionOutlined />,
          content: "",
          okText: "Delete",
          okButtonProps: {
            style: {
              background: "#d88484",
            },
          },
          onOk() {
            REQUESTS.WEB_PAGE.FAQ.DELETE({ id: item.id })
              .then(() => {
                getFaq();
              })
              .catch((err) => {
                notification.error({
                  message: "Error",
                  description: err,
                });
              });
          },
        });
        break;
      case "edit":
        setCurrent(item);
        setIsOpenFaqDrawer(true);
        break;
      default:
        break;
    }
  };

  const closeDrawer = () => {
    setCurrent(null);
    setIsOpenFaqDrawer(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      fixed: "left",
      render: (record, text, index) => {
        // return limit * (currentPage - 1) + index + 1;
        return index + 1;
      },
    },

    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      fixed: "left",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (question) => (
        <Tooltip placement="topLeft" title={question}>
          {question}
        </Tooltip>
      ),

      ...getColumnSearchProps(),
    },

    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (answer) => (
        <Tooltip placement="topLeft" title={answer}>
          {answer}
        </Tooltip>
      ),

      ...getColumnSearchProps(),
    },

    {
      title: `Last updated`,
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      sorter: true,
      render: (text, record, index) => {
        return parseDate(text);
      },
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 100,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          buttons={[
            {
              key: "edit",
              text: "Edit",
              // icon: ICONS.PEN,
            },
            {
              key: "delete",
              text: "Delete",
              // icon: ICONS.TRASH,
            },
          ]}
        />
      ),
    },
  ];

  return (
    <PageComponent routes={["News", "F.A.Q"]}>
      <TableComponent
        header={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            {/* <h3>FAQ</h3> */}
            <Button type="primary" onClick={() => setIsOpenFaqDrawer(true)}>
              Add Answer
            </Button>
          </div>
        }
        rowKey="id"
        size="small"
        bordered
        columns={columns}
        dataSource={list}
        onChange={handleTableChange}
        loading={isLoading}
        localeClick={() => setIsOpenFaqDrawer(true)}
        isEmpty={isEmpty}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
      />
      <FaqDrawer
        open={isOpenFaqDrawer}
        onClose={closeDrawer}
        getData={getFaq}
        current={current}
      />
    </PageComponent>
    // <div>
    //   <div
    //     style={{
    //       width: "100%",
    //       display: "flex",
    //       justifyContent: "space-between",
    //       alignItems: "center",
    //       marginBottom: 20,
    //     }}
    //   >
    //     <h3>FAQ</h3>
    //     <Button type="primary" onClick={() => setIsOpenFaqDrawer(true)}>
    //       Add Answer
    //     </Button>
    //   </div>
    //   <Table
    //     rowKey="id"
    //     size="small"
    //     bordered
    //     columns={columns}
    //     dataSource={list}
    //     onChange={handleTableChange}
    //     loading={isLoading}
    //     pagination={{
    //       position: ["bottomCenter"],
    //       current: currentPage,
    //       total: total,
    //       pageSize: limit,
    //       showSizeChanger: true,
    //     }}
    //   />

    //   <FaqDrawer
    //     open={isOpenFaqDrawer}
    //     onClose={closeDrawer}
    //     getData={getFaq}
    //     current={current}
    //   />
    // </div>
  );
}
