import { Popconfirm, Space, notification } from "antd";

import REQUESTS from "../../../../api/requests";
import icons from "../../../../config/icons";
import ButtonComponent from "../../../../_components/button/ButtonComponent";

export default function ActionsForAll({ setGetAgain }) {
  const onChange = (enable) => {
    REQUESTS.VOD.MEDIAS.ENABLE_OR_DISABLE_ALL({
      enable,
    })
      .then((response) => {
        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message,
          });

          return;
        }

        setGetAgain((prev) => !prev);

        notification.success({
          message: "Success",
          description: response.message,
        });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error,
        });
      });
  };

  return (
    <Space>
      <Popconfirm
        placement="topRight"
        title="Do you want enable all content"
        onConfirm={() => onChange(true)}
        okText="Enable"
        cancelText="Cancel"
        okButtonProps={{
          className: "enable-button",
        }}
      >
        <ButtonComponent
          icon={icons.ENABLE}
          title="Enable All"
          className="enable-button"
        />
      </Popconfirm>

      <Popconfirm
        placement="topRight"
        title="Do you want disable all content"
        onConfirm={() => onChange(false)}
        okText="Disable"
        cancelText="Cancel"
        okButtonProps={{
          className: "disable-button",
        }}
      >
        <ButtonComponent
          icon={icons.DISABLE}
          title="Disable All"
          className="disable-button"
        />
      </Popconfirm>
    </Space>
  );
}
