import { useEffect, useState } from "react";

import TableComponent from "../../../_components/table/TableComponent";
import {
  getColumnDateProps,
  getColumnSearchProps,
  getQueryDate,
  parseDate,
} from "../../../config/config";
import { getAtSession, setInSession } from "../../../helpers/storages";
import TableButtons from "../../../_components/table/TableButtons";
import icons from "../../../config/icons";
import ButtonComponent from "../../../_components/button/ButtonComponent";

export default function TranscodersGroupsTable({
  getAgain,
  openDrawer,
  getDataSource,
  handleMenuClick,
}) {
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [isEmpty, setIsEmpty] = useState(false);

  const [date, setDate] = useState(null);

  const [limit, setLimit] = useState(10);

  const [currentPage, setCurrentPage] = useState(
    getAtSession("transcoders_groups")?.page || 1
  );

  const [sort, setSort] = useState(
    getAtSession("transcoders_groups")?.sort || ["id", "DESC"]
  );

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState(
    getAtSession("transcoders_groups")?.search || {
      name: null,
    }
  );

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      ...getColumnSearchProps(),
    },

    {
      title: "Transcoding in group",
      dataIndex: "channels",
      key: "channels",
      align: "center",
      render: (record, text, index) => {
        return record.channels ? record.channels.length : 0;
      },
    },

    {
      title: `Created date`,
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",

      render: (text, record, index) => {
        return parseDate(text);
      },

      ...getColumnDateProps(setDate),
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: icons.EDIT,
            },

            {
              key: "delete",
              name: "Delete",
              icon: icons.DELETE,
              question: `Do you want to delete this group?`,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);
  };

  useEffect(() => {
    const fetchLiveTvData = () => {
      setLoading(true);

      const query = {
        // sort,
        limit,
        page: currentPage,
      };

      if (sort && sort[1] && sort[1] !== "undefined") {
        query.sort = sort;
      } else {
        query.sort = ["id", "DESC"];
      }

      if (search.name) {
        query.search = {
          name: search.name,
        };
      }

      if (search.categories) {
        query.filter = {
          categoryId: search.categories,
        };
      }

      const hasSearchKeyWithValue = () => {
        const keys = Object.keys(search);

        return keys.some((key) => search[key] && search[key][0]);
      };

      function onSuccess(data) {
        setLoading(false);

        if (data?.rows?.length > 0 || hasSearchKeyWithValue()) {
          setDataSource(data?.rows);
          setIsEmpty(false);
        } else {
          setIsEmpty(true);
        }

        setTotal(data?.count);

        const maxPage = Math.ceil(data?.count / limit);
        const storedPage = getAtSession("transcoders_groups")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("transcoders_groups", {
            ...getAtSession("transcoders_groups"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("transcoders_groups", {
            ...getAtSession("transcoders_groups"),
            page: currentPage,
          });
        }
      }

      function onError(data) {
        setLoading(false);
      }
      getDataSource({ query: JSON.stringify(query) }, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (
        getAtSession("transcoders_groups") &&
        getAtSession("transcoders_groups")?.page > currentPage
      ) {
        setInSession("transcoders_groups", {
          ...getAtSession("transcoders_groups"),
          page: 1,
        });
        setCurrentPage(getAtSession("transcoders_groups")?.page);
      }

      fetchLiveTvData();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, getAgain]);

  return (
    <TableComponent
      header={
        <>
          <ButtonComponent icon={icons.ADD} title="Add group" onClick={openDrawer} />
        </>
      }
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      localeClick={openDrawer}
      isEmpty={isEmpty}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
    />
  );
}
