import { Descriptions } from "antd";

import { useEffect, useState } from "react";

import REQUESTS from "../../../../api/requests";

const showTime = (time) => {
  let hour = parseInt(time / 60 / 60);
  let day = hour / 24;
  let _hour = hour % 24;

  return (
    <p>
      {parseInt(day)} day {parseInt(_hour)} hour
    </p>
  );
};

export default function ServerStorage() {
  const [data, setData] = useState({
    cpu: 0,
    inputMBS: 0,
    outputMBS: 0,
    ram: 0,

    storage: { size: 0, available: 0, used: 0, used_percentages: 0 },
    uptime: 0,
  });

  const getData = () => {
    REQUESTS.VOD.TRANSCODING.STORAGE()
      .then((data) => {
        setData(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <Descriptions title="Server" style={{ width: "50%" }} column={2}>
        <Descriptions.Item label="Cpu">
          {parseFloat(data.cpu).toFixed(0)} %
        </Descriptions.Item>
        <Descriptions.Item label="Ram">
          {parseFloat(data.ram).toFixed(0)} %
        </Descriptions.Item>

        <Descriptions.Item label="Input">
          {parseFloat(data.inputMBS).toFixed(0)}
        </Descriptions.Item>

        <Descriptions.Item label="Output">
          {parseFloat(data.outputMBS).toFixed(0)}
        </Descriptions.Item>

        <Descriptions.Item label="Uptime">
          {showTime(data && data?.uptime)}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Storage" style={{ width: "50%" }} column={2}>
        <Descriptions.Item label="Size">
          {parseFloat(data.storage?.size).toFixed(0)} gb
        </Descriptions.Item>

        <Descriptions.Item label="Used">
          {parseFloat(data.storage?.used).toFixed(0)} gb (
          {parseFloat(data.storage?.used_percentages).toFixed(0)} %)
        </Descriptions.Item>

        <Descriptions.Item label="Available">
          {parseFloat(data.storage?.available).toFixed(0)} gb
        </Descriptions.Item>
        <br />
      </Descriptions>
    </div>
  );
}
