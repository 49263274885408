import { useEffect, useState } from "react";

import { Drawer, Radio, Select, Space } from "antd";

import REQUESTS from "../../../../../../api/requests";

import FilteredMoviesTable from "./FilteredMoviesTable";

import ButtonComponent from "../../../../../../_components/button/ButtonComponent";

import { useVodGenresOptions, useYearOptions } from "../../../../../../hooks/selectOptions";

import styles from "./vod.module.scss";
import { vodServiceId } from "../../../../../../config/statics";

export default function SetupFilterDrawer({ open, onClose, onAdd, actualVodService }) {
    const [filterData, setFilterData] = useState({
        year: {
            type: "",
            values: [],
        },
        category: {
            type: "",
            values: [],
        },
    });

    const genresOptions = useVodGenresOptions();
    const yearOptions = useYearOptions();

    const add = () => {
        onAdd(filterData);
        onClose();
    };

    const onChangeCategoryType = (value) => {
        setFilterData((prev) => ({
            ...prev,
            category: {
                type: value,
                values: prev.category.values,
            },
        }));
    };

    const onChangeCategoryValues = (values) => {
        setFilterData((prev) => ({
            ...prev,
            category: {
                type: prev.category.type,
                values: values,
            },
        }));
    };

    const onChangeYearType = (value) => {
        setFilterData((prev) => ({
            ...prev,
            year: {
                type: value,
                values: prev.year.values,
            },
        }));
    };

    const onChangeYearValues = (values) => {
        setFilterData((prev) => ({
            ...prev,
            year: {
                type: prev.year.type,
                values: values,
            },
        }));
    };

    const getDataSource = (query, onSuccess, onError) => {
        REQUESTS.PAYMENTS.SUBSCRIPTIONS.CONTENT({
            query: JSON.stringify(query),
            filter: JSON.stringify(filterData),
            serviceId: vodServiceId,
        })
            .then((response) => {
                onSuccess(response.message);
            })
            .catch((error) => {
                onError(error);
            });
    };

    useEffect(() => {
        if (open) {
            setFilterData({
                year: actualVodService.year,
                category: actualVodService.category,
            });
        }
    }, [actualVodService, open]);

    return (
        <Drawer width={800} title="Setup filter" placement="right" onClose={onClose} open={open}>
            <div className={styles["header"]}>
                <h6>Here you can filter and add your moveis</h6>
            </div>

            <div className={styles["setup-filters-section"]}>
                <div style={{ width: "50%" }}>
                    <Space>
                        <label>Genres: </label>
                        <Radio.Group
                            buttonStyle="solid"
                            value={filterData?.category?.type}
                            onChange={(e) => {
                                onChangeCategoryType(e.target.value);
                            }}
                        >
                            <Radio value="exclude">Exclude</Radio>

                            <Radio value="include">Include</Radio>
                        </Radio.Group>
                    </Space>
                    <Select
                        options={genresOptions}
                        onChange={onChangeCategoryValues}
                        value={filterData?.category?.values}
                        style={{ width: "100%", marginTop: 20 }}
                        mode="multiple"
                        maxTagCount="responsive"
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label.toLowerCase() ?? "").includes(input)
                        }
                        filterSort={(optionA, optionB) => {
                            return (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare((optionB?.label ?? "").toLowerCase());
                        }}
                    />
                </div>

                <div style={{ width: "50%" }}>
                    <Space>
                        <label>Year: </label>

                        <Radio.Group
                            buttonStyle="solid"
                            value={filterData?.year?.type}
                            onChange={(e) => {
                                onChangeYearType(e.target.value);
                            }}
                        >
                            <Radio value="exclude">Exclude</Radio>

                            <Radio value="include">Include</Radio>
                        </Radio.Group>
                    </Space>

                    <Select
                        options={yearOptions}
                        onChange={onChangeYearValues}
                        value={filterData?.year?.values}
                        style={{ width: "100%", marginTop: 20 }}
                        mode="multiple"
                        maxTagCount="responsive"
                    />
                </div>
            </div>

            <FilteredMoviesTable getDataSource={getDataSource} filterData={filterData} />

            <div style={{ marginTop: 20, textAlign: "center" }}>
                <ButtonComponent title="Add" actionType="add" onClick={add} />
            </div>
        </Drawer>
    );
}
