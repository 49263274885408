import { Select } from "antd";

import { useEffect, useState } from "react";

import REQUESTS from "../../api/requests";

export default function TranscodingSearch({ label, value, onChange }) {
  const [search, setSearch] = useState("");
  const [isDefault, setIsDefault] = useState(true);

  const [list, setList] = useState([]);

  const onSearch = () => {
    let query = {
      limit: 50,

      search: { name: search },
    };

    if (isDefault && value) {
      query.filter = {
        id: value,
      };
    }

    REQUESTS.VOD.TRANSCODING.GET({ query })
      .then((res) => {
        setIsDefault(false);
        if (res?.rows) {
          const list = res.rows.map((item) => ({
            value: item.id,
            label: item.name,
          }));

          setList(list);
        }
      })
      .catch((err) => {
        console.log(err, " ERR");
      });
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      onSearch();
    }, [500]);

    return () => {
      clearTimeout(timeout);
    };
  }, [search, value]);

  return (
    <div style={{ width: "100%" }}>
      <Select
        showSearch
        allowClear
        value={value}
        showArrow={false}
        autoClearSearchValue={false}
        placeholder="Search movie and select"
        filterOption={(input, option) =>
          (option?.label?.toLowerCase() ?? "").includes(input?.toLocaleLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={list}
        onSearch={setSearch}
        onChange={onChange}
        style={{
          textAlign: "start",
        }}
      />
    </div>
  );
}
