import { Input } from "antd";
import { useEffect, useState } from "react";

import REQUESTS from "../../../api/requests";

export default function Resolutions({ editable, setSelectedResolutions }) {
    const [resolutions, setResolutions] = useState([]);

    const getResolutions = () => {
        REQUESTS.TRANSCODERS.RESOLUTIONS.GET().then((response) => {
            if (response.error) return;

            const list = response.message?.rows.map((item) => {
                return {
                    id: item.id,
                    input: "",
                    name: item.name,
                    output: "",
                };
            });

            if (editable) {
                const editableResolutions = editable.resolutions || [];
                for (let i = 0; i < list.length; i++) {
                    for (let g = 0; g < editableResolutions.length; g++) {
                        if (list[i].id === editableResolutions[g].id) {
                            list[i].input = editableResolutions[g].info.input;
                            list[i].output = editableResolutions[g].info.output;
                        }
                    }
                }
            }

            setResolutions(list);
        });
    };

    const onChangeResolutions = (e, name) => {
        const { value } = e.target;

        const _resolution = [...resolutions];

        for (let i = 0; i < _resolution.length; i++) {
            if (_resolution[i].name === name) {
                _resolution[i].input = value;
            }
        }

        setResolutions(_resolution);
    };

    useEffect(() => {
        getResolutions();
    }, []);

    useEffect(() => {
        const list = resolutions.filter((item) => item.input !== "");

        setSelectedResolutions(list);
    }, [resolutions]);

    return (
        <div style={{ marginBottom: 20 }}>
            <span>Resolutions</span>
            {resolutions.map((resolution, index) => {
                return (
                    <div style={{ display: "flex", gap: 10, marginTop: 10 }}>
                        <Input
                            value={resolution.name}
                            readOnly
                            style={{ color: "gray", background: "#8080801c" }}
                        />
                        <Input
                            placeholder="Input"
                            value={resolution.input}
                            onChange={(e) => onChangeResolutions(e, resolution.name)}
                        />
                        <Input
                            placeholder="Output"
                            value={resolution.output}
                            readOnly={true}
                            style={{ color: "gray", background: "#8080801c" }}
                        />
                    </div>
                );
            })}
        </div>
    );
}
