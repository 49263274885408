import { useState, useEffect } from "react";
import { Divider } from "antd";

import Restriction from "./Restriction";

import Monetization from "./Monetization";

import NextBackButton from "../../../../../_components/button/NextBackButton";

import styles from "./_restriction_monetization.module.scss";
import ButtonComponent from "../../../../../_components/button/ButtonComponent";
import icons from "../../../../../config/icons";

export default function RestrictionMonetizationStep({ form, onChangeStep }) {
  const [mediaType, setMediaType] = useState("");

  useEffect(() => {
    const { media_type } = form.getFieldsValue();
    setMediaType(media_type);
  }, [form]);

  return (
    <div>
      <div className={styles["container"]}>
        <Restriction form={form} />

        <Monetization form={form} />
      </div>
      <Divider />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <NextBackButton type="back" onClick={() => onChangeStep("back")} />
        {mediaType === "video" ? (
          <NextBackButton type="next" onClick={() => onChangeStep("next")} />
        ) : (
          <ButtonComponent
            title="Save"
            icon={icons.CHECK}
            onClick={() => form.submit()}
          />
        )}
      </div>
    </div>
  );
}
