import { useEffect, useState } from "react";

import { Form, Input, notification, Switch, Tag } from "antd";

import REQUESTS from "../../../../api/requests";

import ImageUpload from "../../../../components/ImageUpload";

import ButtonComponent from "../../../../_components/button/ButtonComponent";
import Upploader from "../../../../_components/uploader/Uploader";
import Check from "../../../../_components/table/Check";

export default function GoogleConfig({ data, selectedCurrency }) {
    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);

    const [icon, setIcon] = useState({
        url: null,
        file: null,
    });

    const onChangeJson = (info) => {
        form.setFields([{ name: "google_config_json", value: info?.file?.originFileObj }]);
    };

    const onFinish = (values) => {
        setIsLoading(true);

        const formData = new FormData();

        if (icon.file) {
            formData.append("google_icon", icon.file);
        }

        formData.append("id", data.id);
        formData.append("currency_id", selectedCurrency);
        formData.append("google_enabled", values.google_enabled);
        formData.append("google_package_name", values.google_package_name || "");

        if (values?.google_config_json) {
            formData.append("google_config_json", values.google_config_json);
        }

        REQUESTS.PAYMENTS.CONFIGS.UPDATE(formData)
            .then((response) => {
                setIsLoading(false);

                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });

                    return;
                }

                notification.success({
                    message: "Success",
                    description: "Google pay configs is changed",
                });
            })
            .catch((error) => {
                setIsLoading(false);
                notification.error({
                    message: "Error",
                    description: error,
                });
            });
    };

    const onChangeIcon = (value) => {
        setIcon(value);

        form.setFieldsValue({
            google_icon: value.file,
        });
    };

    useEffect(() => {
        if (data) {
            setIcon({
                url: data.google_icon,
                file: null,
            });

            form.setFields([
                { name: "google_icon", value: data.google_icon },
                { name: "google_enabled", value: data.google_enabled },
                { name: "google_package_name", value: data.google_package_name },
            ]);
        }
    }, [data]);

    return (
        <Form
            form={form}
            name="google-pay-config"
            layout="vertical"
            onFinish={onFinish}
            style={{ maxWidth: 350 }}
        >
            <Form.Item label="Icon" name="google_icon">
                <ImageUpload image={icon} setImage={onChangeIcon} />
            </Form.Item>

            <Form.Item label="Enabled" name="google_enabled" valuePropName="checked">
                <Switch />
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
                {() => {
                    const { google_enabled } = form.getFieldsValue();

                    return (
                        <Form.Item
                            label="Package name"
                            name="google_package_name"
                            rules={[
                                {
                                    required: google_enabled,
                                    message: "Please input package name",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    );
                }}
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
                {() => {
                    const { google_enabled } = form.getFieldsValue();

                    return (
                        <Form.Item
                            label="Service account"
                            name="google_config_json"
                            rules={[
                                {
                                    required: google_enabled,
                                    message: "Please select json file",
                                },
                            ]}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Upploader
                                    accept=".json"
                                    onChange={onChangeJson}
                                    style={{ width: 320 }}
                                />

                                {data?.google_config ? (
                                    <Check value={true} />
                                ) : (
                                    <Check value={false} />
                                )}
                            </div>
                        </Form.Item>
                    );
                }}
            </Form.Item>

            <ButtonComponent
                title="Save"
                actionType="save"
                isLoading={isLoading}
                onClick={() => form.submit()}
            />
        </Form>
    );
}
