import dayjs from "dayjs";
import { parseDateFormat } from "../../../../config/config";
import { AGE_RESTRICTION } from "../../../../config/statics";

const imagePath = "https://image.tmdb.org/t/p/original/";

export default function selectFromTmdb(data, form) {
  form.resetFields();

  let duration = 0;

  let releaseDate = null;

  if (data.runtime) {
    duration = data.runtime;
  }

  if (data.episode_run_time && data.episode_run_time[0]) {
    duration = data.episode_run_time[0];
  }

  if (data.release_date) {
    let date = dayjs(parseDateFormat(data?.release_date), "DD-MM-YYYY");

    releaseDate = date;
  }

  if (data.first_air_date) {
    let date = dayjs(parseDateFormat(data?.first_air_date), "DD-MM-YYYY");

    releaseDate = date;
  }

  if (data.rating) {
    let imdb = 0;
    let rotten_tomatos = 0;
    let metacritic = 0;

    for (let i = 0; i < data.rating.length; i++) {
      let { Source, Value } = data.rating[i];

      switch (Source) {
        case "Internet Movie Database":
          imdb = Value;
          break;
        case "Rotten Tomatoes":
          rotten_tomatos = Value;
          break;
        case "Metacritic":
          metacritic = Value;
          break;
      }
    }

    form.setFieldsValue({
      imdb: imdb,
      metacritic: metacritic,
      rotten_tomatos: rotten_tomatos,
    });
  }

  const newGenres = data?.genres?.map(
    (genre) =>
      (genre = {
        label: genre.name,
        value: genre.id,
      })
  );

  const newCountries = data?.production_countries?.map(
    (country) =>
      (country = {
        label: country.name,
        value: country.id,
      })
  );

  let newCast = [];
  for (let i = 0; i < 10; i++) {
    if (data.credits.cast[i]) {
      newCast.push({
        name: data.credits.cast[i]?.name,
        tmdb_id: data.credits.cast[i]?.id,
      });
    }
  }

  form.setFieldsValue({
    casts: JSON.stringify(newCast) || "",
  });

  form.setFieldsValue({
    type: data.media_type === "movie" ? "movie" : "tv_show",
    name: data.name || data.title,
    description: data.overview,
    duration: duration,
    release_date: dayjs(data?.release_date),
    poster: imagePath + data?.poster_path,
    backdrop: imagePath + data?.backdrop_path,
    content_monetization_type: "free",
    original_audio_language: data?.original_language,
    tmdb: data.vote_average || 0,
    genres: newGenres,
    countries: newCountries,
    pg: AGE_RESTRICTION,
    license_ending_date: "",

    is_location_restriction: data.is_location_restricted,
    is_protected: data.adult,
    available_countries: data?.production_countries?.map((country) => country.name),
    tmdb_id: data.id,
    trailer_stream_type: "external",
    stream_type: "external",
  });
}
