import { Select } from "antd";

import { useEffect, useState } from "react";

import REQUESTS from "../../../../api/requests";

import { getToken } from "../../../../server/requests";

export default function SearchEpg({ form, epg_type, defaultValue }) {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState(null);

  const [epgList, setEpgList] = useState([]);

  const onSearchEpg = () => {
    const query = {
      name: search,
      type: epg_type,
      token: getToken(),
    };

    REQUESTS.EPG.SEARCH(query).then((response) => {
      let list = response?.message?.map((item) => ({
        label: item.name,
        value: item.channel_id,
      }));

      setEpgList(list);
    });
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      onSearchEpg();
    }, [500]);

    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  return (
    <Select
      showSearch
      showArrow={false}
      autoClearSearchValue={false}
      placeholder="Search epg and select"
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
      }
      filterSort={(optionA, optionB) => {
        return (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase());
      }}
      allowClear
      options={epgList}
      value={defaultValue}
      onChange={(value) => {
        form.setFields([{ name: "id_epg", value: value || "" }]);
      }}
    />
  );
}
