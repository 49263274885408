import { useEffect, useState } from "react";

import dayjs from "dayjs";

import { Checkbox, DatePicker, Drawer, Form, Input, Select, notification } from "antd";

import REQUESTS from "../../../api/requests";

import { parseDateFormat } from "../../../config/config";

import { useUsersGroupsOptions } from "../../../hooks/selectOptions";

import ButtonComponent from "../../../_components/ButtonComponent";

export default function UserDrawer({
  onClose,
  open,
  editable,
  getData,
  automaticlySelectUser,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const usersGroupsOptions = useUsersGroupsOptions();

  const onFinish = (values) => {
    setIsLoading(true);
    const body = {
      name: values.name,
      surname: values.surname,
      username: values.username,
      password: values.password,
      phone: values.phone,
      email: values.email,
      birthday: new Date(values.birthday),
      auto_renewal: values.auto_renewal,
      parental_code: values.parental_code,
      groupId: values.groupId,
    };

    if (editable) {
      body.id = editable.id;

      REQUESTS.USERS.USERS.EDIT(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              message: "Error",
              description: response.message,
            });

            return;
          }

          getData();
          onClose();
        })
        .catch((err) => {
          notification.error({
            message: "Error",
            description: err,
          });
          setIsLoading(false);
        });
    } else {
      REQUESTS.USERS.USERS.ADD(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              message: "Error",
              description: response.message,
            });

            return;
          }
          if (automaticlySelectUser) {
            automaticlySelectUser(response.message);
          }

          getData();
          onClose();
        })
        .catch((err) => {
          notification.error({
            message: "Error",
            description: err,
          });
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }

    if (editable) {
      form.setFields([
        { name: "name", value: editable.name },
        { name: "surname", value: editable.surname },
        { name: "username", value: editable.username },
        { name: "phone", value: editable.phone },
        { name: "email", value: editable.email },

        { name: "auto_renewal", value: editable.auto_renewal },
        { name: "parental_code", value: editable.parental_code },
        { name: "groupId", value: editable.groupId },
      ]);

      if (editable.birthday) {
        form.setFields([
          {
            name: "birthday",
            value: dayjs(parseDateFormat(editable.birthday), "DD-MM-YYYY"),
          },
        ]);
      }
    } else {
      REQUESTS.USERS.USERS.UNIQUE_USERNAME().then((response) => {
        if (response.error) {
          return;
        }

        form.setFields([{ name: "username", value: response.message }]);
      });
    }
  }, [open, editable]);

  return (
    <Drawer
      width={600}
      open={open}
      placement="right"
      onClose={onClose}
      title={`${editable ? "Edit" : "Add"} User`}
    >
      <Form
        form={form}
        name="user"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //   if (e.key === "Enter") {
        //     form.submit();
        //   }
        // }}
        autoComplete="false"
      >
        <div style={{ display: "flex", gap: 20 }}>
          <div style={{ width: "50%" }}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input name",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Surname" name="surname">
              <Input />
            </Form.Item>

            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input username",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Birthday" name="birthday">
              <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
            </Form.Item>

            <Form.Item label="Phone" name="phone">
              <Input />
            </Form.Item>
          </div>

          <div style={{ width: "50%" }}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input email",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: editable ? false : true,
                  message: "Please input password",
                },
              ]}
            >
              <Input.Password autoComplete="false" autoCapitalize="new password" />
            </Form.Item>

            <Form.Item label="Group" name="groupId">
              <Select options={usersGroupsOptions} />
            </Form.Item>

            <Form.Item label="Parental code" name="parental_code">
              <Input maxLength={4} />
            </Form.Item>

            <Form.Item name="auto_renewal" noStyle valuePropName="checked">
              <Checkbox style={{ marginTop: 30 }}>Auto Renewal</Checkbox>
            </Form.Item>
          </div>
        </div>

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title="Save"
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
