import { useEffect, useState } from "react";

import { Drawer, Form, Input, InputNumber, notification } from "antd";

import REQUESTS from "../../../api/requests";

import ButtonComponent from "../../../_components/ButtonComponent";

export default function ResolutionDrawer({ onClose, open, editable, getData }) {
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const body = {
      name: values.name,
      width: values.width,
      height: values.height,
      fps: values.fps,
      audio_bitrate: values.audio_bitrate,
      buffer_size: values.buffer_size,
      max_bitrate: values.max_bitrate,
      min_bitrate: values.min_bitrate,
    };

    if (editable) {
      body.id = editable.id;

      REQUESTS.TRANSCODERS.RESOLUTIONS.EDIT(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              message: "Error",
              description: response.message,
            });

            return;
          }

          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      REQUESTS.TRANSCODERS.RESOLUTIONS.ADD(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              message: "Error",
              description: response.message,
            });

            return;
          }

          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }

    if (editable) {
      form.setFields([
        { name: "name", value: editable.name },
        { name: "width", value: editable.width },
        { name: "height", value: editable.height },
        { name: "fps", value: editable.fps },
        { name: "audio_bitrate", value: editable.audio_bitrate },
        { name: "buffer_size", value: editable.buffer_size },
        { name: "max_bitrate", value: editable.max_bitrate },
        { name: "min_bitrate", value: editable.min_bitrate },
      ]);
    }
  }, [open, editable]);

  return (
    <Drawer
      title={`${editable ? "Edit" : "Add"} resolution`}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Form
        form={form}
        name="transcoders-resolution"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //     if (e.key === "Enter") {
        //         form.submit();
        //     }
        // }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "0px 25px" }}>
          <Form.Item
            label="Width"
            name="width"
            rules={[
              {
                required: true,
                message: "Please input width",
              },
            ]}
          >
            <InputNumber controls={false} style={{ width: 150 }} />
          </Form.Item>

          <Form.Item
            label="Height"
            name="height"
            rules={[
              {
                required: true,
                message: "Please input height",
              },
            ]}
          >
            <InputNumber controls={false} style={{ width: 150 }} />
          </Form.Item>

          <Form.Item
            label="Fps"
            name="fps"
            rules={[
              {
                required: true,
                message: "Please input fps",
              },
            ]}
          >
            <InputNumber controls={false} style={{ width: 150 }} />
          </Form.Item>

          <Form.Item
            label="Audio bitrate"
            name="audio_bitrate"
            rules={[
              {
                required: true,
                message: "Please input audio bitrate",
              },
            ]}
          >
            <InputNumber controls={false} style={{ width: 150 }} />
          </Form.Item>

          <Form.Item
            label="Buffer size"
            name="buffer_size"
            rules={[
              {
                required: true,
                message: "Please input buffer size",
              },
            ]}
          >
            <InputNumber controls={false} style={{ width: 150 }} />
          </Form.Item>

          <Form.Item
            label="Max bitrate"
            name="max_bitrate"
            rules={[
              {
                required: true,
                message: "Please input max bitrate",
              },
            ]}
          >
            <InputNumber controls={false} style={{ width: 150 }} />
          </Form.Item>

          <Form.Item
            label="Min bitrate"
            name="min_bitrate"
            rules={[
              {
                required: true,
                message: "Please input min bitrate",
              },
            ]}
          >
            <InputNumber controls={false} style={{ width: 150 }} />
          </Form.Item>
        </div>
        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title="Save"
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
