import { useEffect, useState } from "react";

import { Form, Input, Radio, Space } from "antd";

import Upploader from "../../../_components/uploader/Uploader";

export default function ApkTabs({ form, editable }) {
    const [type, setType] = useState("url");

    const chooseApk = (info) => {
        form.setFields([{ name: "apk", value: info?.file?.originFileObj }]);
    };

    useEffect(() => {
        form.setFields([{ name: "apk", value: undefined }]);
    }, [type]);

    useEffect(() => {
        if (type === "url" && editable) {
            form.setFields([{ name: "apk", value: editable.apk }]);
        }
    }, [type, editable]);

    return (
        <div>
            <Space>
                Apk
                <Radio.Group
                    name="radiogroup"
                    defaultValue={"url"}
                    value={type}
                    onChange={(e) => {
                        setType(e.target.value);
                    }}
                >
                    <Radio value="url">Url</Radio>
                    <Radio value="file">File</Radio>
                </Radio.Group>
            </Space>

            <div style={{ marginTop: 20 }}>
                {type === "url" ? (
                    <Form.Item
                        name="apk"
                        rules={[
                            {
                                required: editable ? false : true,
                                message: "Please input apk",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                ) : (
                    <Form.Item
                        name="apk"
                        rules={[
                            {
                                required: editable ? false : true,
                                message: "Please choose apk",
                            },
                        ]}
                    >
                        <Upploader accept=".apk" onChange={chooseApk} />
                    </Form.Item>
                )}
            </div>
        </div>
    );
}
