import { useEffect, useState } from "react";

import REQUESTS from "../../api/requests";

import Statistics from "./Statistics";

import PlaylistForm from "./PlaylistForm";

import PageComponent from "../../_components/page/PageComponent";

export default function ImportPage() {
  const [data, setData] = useState({
    status: "IDLE",
    channels: { total: 0, done: 0 },
    movies: { total: 0, done: 0 },
    series: { total: 0, done: 0 },
  });

  const getData = () => {
    REQUESTS.IMPORT.PARSE().then((response) => {
      if (!response.error) {
        setData(response.message);
      }
    });
  };

  useEffect(() => {
    let interval = setInterval(() => {
      getData();
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <PageComponent routes={["Import"]}>
      <PlaylistForm status={data?.status} />

      {data?.status !== "IDLE" && <Statistics data={data} />}
    </PageComponent>
  );
}
