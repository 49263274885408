import { Form, Input, InputNumber, Select, Space } from "antd";

export default function Scheduling({ form }) {
  const options = [
    {
      value: "day",
      label: "Day"
    },
    {
      value: "month",
      label: "Month"
    },
    {
      value: "year",
      label: "Year"
    }
  ];

  return (
    <Space>
      <Form.Item
        label="Scheduling type"
        name="scheduling_type"
        style={{ width: 180 }}
      >
        <Select options={options} />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }} shouldUpdate noStyle>
        {() => {
          const { scheduling_type } = form.getFieldsValue();
          return (
            <Form.Item
              label="Scheduling Time"
              name="scheduling_time"
              rules={[
                {
                  required: true,
                  message: "Please input scheduling time"
                }
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={scheduling_type === "day" ? 7 : 1}
                max={scheduling_type === "day" ? 365 : 12}
                controls={false}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    </Space>
  );
}
