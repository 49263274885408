import { Drawer, Form, Select, Input, Button, message } from "antd";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import icons from "../../../config/icons";
import { useEffect, useState } from "react";
import { useDevicesOptions } from "../../../hooks/selectOptions";
import ImageUpload from "../../../components/ImageUpload";

const MenuConfigDrawer = ({ open, close, onSave, editable, loading }) => {
  const [form] = Form.useForm();

  const deviceOptions = useDevicesOptions();

  const [selectedImage, setSelectedImage] = useState({
    file: "",
    url: "",
  });
  const [selectIcon, setSelectIcon] = useState({
    file: "",
    url: "",
  });

  const typeOption = [
    {
      label: "home",
      value: "home",
    },
    {
      label: "movies",
      value: "movies",
    },
    {
      label: "tv shows",
      value: "tv-shows",
    },
    {
      label: "collection",
      value: "collection",
    },
    {
      label: "category",
      value: "category",
    },
    {
      label: "favorites",
      value: "favorites",
    },
    {
      label: "live tv",
      value: "live-tv",
    },
  ];

  const handleFinish = async (value) => {
    try {
      await form.validateFields();

      if (!selectIcon.file) {
        form.setFields([{ name: "icon", errors: ["Please choose an icon!"] }]);

        message.error("Please choose an icon!");
        return;
      }

      if (!selectedImage.file) {
        form.setFields([{ name: "selectedIcon", errors: ["Please choose an icon!"] }]);
        message.error("Please choose an active icon!");
        return;
      }

      const formData = new FormData();
      formData.append("name", value?.name);
      formData.append("type", value?.type);
      formData.append("devices", value?.devices);
      formData.append("is_enabled", false);

      if (selectIcon.file) {
        formData.append("selectedIcon", selectIcon?.file);
      }

      if (selectedImage.file) {
        formData.append("icon", selectedImage?.file);
      }

      if (editable) {
        formData.append("id", editable?.id);
      }

      onSave(formData);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleClose = () => {
    setSelectIcon({
      url: "",
      file: "",
    });

    setSelectedImage({
      url: "",
      file: "",
    });

    form.resetFields();
    close();
  };

  useEffect(() => {
    const defaultSelected = deviceOptions?.map((item) => item.value);
    if (!editable) {
      form.setFieldsValue({ devices: defaultSelected });
    }
  }, [open]);

  useEffect(() => {
    if (editable) {
      setSelectIcon({ ...selectIcon, url: editable.icon });
      setSelectedImage({ ...selectedImage, url: editable.selectedIcon });
      form.setFields([
        { name: "name", value: editable?.name },
        { name: "type", value: editable?.type },
        { name: "devices", value: editable?.devices },
      ]);
    }
  }, [editable, open]);

  return (
    <Drawer
      title={editable ? "Edit config" : "Add config"}
      width={400}
      open={open}
      onClose={handleClose}
      destroyOnClose
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Form.Item label="Icon" name="icon">
            <ImageUpload
              label={"Choose icon"}
              image={selectIcon}
              setImage={setSelectIcon}
              removeImage
            />
          </Form.Item>
          <Form.Item name="selectedIcon" label="Active icon">
            <ImageUpload
              label={"Choose active icon"}
              image={selectedImage}
              setImage={setSelectedImage}
              removeImage
            />
          </Form.Item>
        </div>

        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: "Please select the type!" }]}
        >
          <Select
            options={typeOption}
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(input?.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          name="devices"
          label="Devices"
          rules={[{ required: true, message: "Please select the devices!" }]}
        >
          <Select
            mode="multiple"
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(input?.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={deviceOptions}
          />
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <ButtonComponent
            title="Save"
            icon={icons.CHECK}
            onClick={() => form.submit()}
            isLoading={loading}
          />
        </div>
      </Form>
    </Drawer>
  );
};

export default MenuConfigDrawer;
