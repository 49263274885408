import { useEffect, useState } from "react";
import { Badge, Image, Modal, Popconfirm, Space, Switch, Typography } from "antd";

import icons from "../../../config/icons";
import { getColumnSearchProps } from "../../../config/config";

import Check from "../../../_components/table/Check";
import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import PositionComponent from "../../../_components/PositionComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import { useLiveTvCategoriesOptions } from "../../../hooks/selectOptions";
import TableImage from "../../../_components/table/TableImage";
import {
  getAtLocal,
  getAtSession,
  setInLocal,
  setInSession,
} from "../../../helpers/storages";
import { asc_desc } from "../../../helpers/ascDesc";
import BulkEditDrawer from "./drawer/BulkEditDrawer";
import REQUESTS from "../../../api/requests";
import { notification } from "antd";

export default function LavieTvTable({
  getAgain,
  openDrawer,
  getDataSource,
  handleMenuClick,
  onPlay,
  onEnable,
  onChangePosition,
  bulkActions,
  onEnableOrDisableAll,
}) {
  const categories = useLiveTvCategoriesOptions("text");

  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(getAtLocal("pageLimit_live_tv") || 10);

  const [currentPage, setCurrentPage] = useState(getAtSession("liveTv")?.page || 1);

  const [sort, setSort] = useState(getAtSession("liveTv")?.sort || ["id", "DESC"]);

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState(
    getAtSession("liveTv")?.search || {
      name: null,
      categories: null,
    }
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [getAganaFromDrawer, setGetAgainFromDrawer] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const setUrl = (type, record) => {
    let streamUrl = "";
    if (type === "internal") {
      streamUrl = record?.streaming_channel?.output;
    } else if (type === "external") {
      streamUrl = record?.url;
    }
    return streamUrl;
  };

  const columns = [
    {
      width: 60,
      title: "#",
      key: "index",
      defaultSortOrder:
        getAtSession("liveTv")?.sort?.[0] === "id" &&
        asc_desc(getAtSession("liveTv")?.sort?.[1]),
      dataIndex: `id`,
      fixed: "left",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      width: 120,
      title: "Position",
      defaultSortOrder:
        getAtSession("liveTv")?.sort?.[0] === "position" &&
        asc_desc(getAtSession("liveTv")?.sort?.[1]),
      dataIndex: `position`,
      align: "center",
      key: "position",
      sorter: true,
      render: (text, record, index) => {
        return (
          <PositionComponent
            position={record.position}
            onChangePosition={(e) => onChangePosition(e, record.id)}
          />
        );
      },
    },

    {
      title: "Enable",
      defaultSortOrder:
        getAtSession("liveTv")?.sort?.[0] === "is_enabled" &&
        asc_desc(getAtSession("liveTv")?.sort?.[1]),
      dataIndex: "is_enabled",
      align: "center",
      key: "is_enabled",
      width: 100,
      sorter: true,
      render: (text, record, index) => {
        return (
          <Switch
            size="small"
            checked={record.is_enabled}
            onChange={(e) => onEnable(e, record.id)}
          />
        );
      },
    },

    {
      width: 70,
      sorter: true,
      title: "Id",
      key: "id",
      dataIndex: "id",
      align: "center",
    },

    {
      title: "Name",
      defaultFilteredValue: getAtSession("liveTv")?.search?.name,
      filteredValue: getAtSession("liveTv")?.search?.name,
      dataIndex: "name",
      key: "name",
      align: "center",
      width: 200,
      ellipsis: true,
      render: (text, record, index) => {
        return (
          <Space>
            <TableImage src={record.image} />
            <span
              style={{
                display: "block",
                width: 100,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {record.name}
            </span>
          </Space>
        );
      },

      ...getColumnSearchProps(),
    },

    {
      title: "Play",
      dataIndex: "play",
      key: "play",
      align: "center",
      width: 50,
      render: (text, record, index) => {
        const url = setUrl(record.stream_type, record);
        const recordObj = {
          ...record,
          url,
        };
        return (
          <div
            onClick={() => onPlay(recordObj)}
            style={{ fontSize: 20, color: "#43b594", cursor: "pointer" }}
          >
            {icons.PLAY}
          </div>
        );
      },
    },

    {
      title: "Url",
      dataIndex: "url",
      key: "url",
      align: "center",
      width: 120,
      ellipsis: true,
      render: (text, record, index) => {
        const { stream_type } = record;
        return (
          <Typography.Paragraph
            ellipsis={true}
            style={{ margin: 0 }}
            copyable={{
              tooltips: false,
            }}
          >
            {setUrl(stream_type, record)}
          </Typography.Paragraph>
        );
      },
    },

    {
      title: "Devices",
      dataIndex: "permission_devices",
      key: "permission_devices",
      align: "center",
      ellipsis: true,

      render: (text, record, index) => {
        const devicesNames = record.permission_devices.map((device) => {
          return device;
        });

        const list = devicesNames.join(", ");

        return list;
      },
    },

    {
      title: "Categories",
      defaultFilteredValue: getAtSession("liveTv")?.search?.categories,
      filteredValue: getAtSession("liveTv")?.search?.categories,
      dataIndex: "categories",
      key: "categories",
      align: "center",
      ellipsis: true,
      filters: categories,
      filterMultiple: false,

      render: (text, record, index) => {
        const categoriesNames = record.categories.map((category) => {
          return category.name;
        });

        const list = categoriesNames.join(", ");

        return list;
      },
    },

    {
      width: 90,
      sorter: true,
      defaultSortOrder:
        getAtSession("liveTv")?.sort?.[0] === "Catch-up" &&
        asc_desc(getAtSession("liveTv")?.sort?.[1]),
      title: "Catch-up",
      key: "has_archive",
      dataIndex: "has_archive",
      align: "center",
      render: (text, record, index) => {
        return <Check value={record.has_archive} />;
      },
    },

    {
      width: 90,
      sorter: true,
      defaultSortOrder:
        getAtSession("liveTv")?.sort?.[0] === "Archive" &&
        asc_desc(getAtSession("liveTv")?.sort?.[1]),
      title: "Archive",
      key: "archive",
      dataIndex: "archive",
      align: "center",
      render: (text, record, index) => {
        return <Check value={record.archive} />;
      },
    },

    {
      // width: 60,
      sorter: true,
      defaultSortOrder:
        getAtSession("liveTv")?.sort?.[0] === "age_restricted" &&
        asc_desc(getAtSession("liveTv")?.sort?.[1]),
      title: "Age",
      key: "age_restricted",
      dataIndex: "age_restricted",
      align: "center",
    },

    {
      // width: 50,
      title: "View",
      dataIndex: "view",
      align: "center",
      render: (text, record, index) => {
        return record?.view?.count || 0;
      },
    },
    {
      // width: 50,
      title: "Like",
      dataIndex: "like",
      align: "center",
      render: (text, record, index) => {
        return record?.like || 0;
      },
    },

    {
      // width: 50,
      title: "Dislike",
      dataIndex: "dislike",
      align: "center",
      render: (text, record, index) => {
        return record?.dislike || 0;
      },
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: icons.EDIT,
            },

            record.archive
              ? {
                  key: "restore",
                  name: "Restore",
                  icon: icons.RESTORE,
                  question: `Do you want to restore this live tv?`,
                  onConfirm: (e) => {
                    handleMenuClick(e, record);
                  },
                }
              : {
                  key: "archive",
                  name: "Archive",
                  icon: icons.ARCHIVE_ICON,
                  question: `Do you want to archive this live tv?`,
                  onConfirm: (e) => {
                    handleMenuClick(e, record);
                  },
                },

            record.archive && {
              key: "delete",
              name: "Delete",
              icon: icons.DELETE,
              question: `Do you want to delete this live tv?`,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
            {
              key: "statistics",
              name: "Statistics",
              icon: icons.STATISTICS,
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    const liveTv = {
      page: data.page,
      // search: data.search,
    };
    if (data.sort) {
      liveTv.sort = [`${data.sort[0]}`, `${data.sort[1]}`];
    }
    setInLocal("pageLimit_live_tv", data.limit);
    setInSession("liveTv", liveTv);

    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);
    setSearch(data.search);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleFinish = (values, fieldChecked) => {
    const body = {
      ids: selectedRowKeys.join(","),
    };

    if (fieldChecked.age_restriction_enable) {
      body.age_restricted = values["age_restriction"];
    }

    if (fieldChecked.tariff_enable) {
      if (values.content_monetization_type === "stv" && fieldChecked.tariff_enable) {
        body.tariff_ids = values["tariffIds"]?.join(",");
        body.content_monetization_type = values["content_monetization_type"];
      } else if (
        values.content_monetization_type === "free" &&
        fieldChecked.tariff_enable
      ) {
        body.content_monetization_type = values["content_monetization_type"];
        body.ads_id = values["ads_id"];
      }
    }

    if (fieldChecked.devices_enable) {
      body.permission_devices = values["deviceIds"].join(",");
    }

    if (fieldChecked.location_restriction_enable) {
      body.available_countries = values["countries"]?.join(",");
      body.is_location_restriction = values["location_restriction_enable"];
    }

    if (fieldChecked.is_protected_enable) {
      // body.is_protected = values["protected"];
      body.is_protected = values["protected"] ? values["protected"] : false;
    }

    if (fieldChecked.categories_enable) {
      body.categories_ids = values["categories"].join(",");
    }

    REQUESTS.LIVE_TV.CHANNELS.BULK_UPDATE(body)
      .then((response) => {
        if (!response.error) {
          notification.success({
            message: "Success",
            description: response.message,
          });
          setShowEditDrawer(false);
          setGetAgainFromDrawer((prev) => !prev);
        } else {
          notification.error({
            message: "Error",
            description: response?.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const fetchLiveTvData = () => {
      setLoading(true);

      const query = {
        // sort,
        limit,
        page: currentPage,
      };

      if (sort && sort[1] && sort[1] != "undefined") {
        query.sort = sort;
      } else {
        query.sort = ["id", "DESC"];
      }

      if (search.name) {
        query.search = {
          name: search.name[0],
        };
      }

      if (search.categories) {
        query.filter = {
          categoryId: search.categories,
        };
      }

      function onSuccess(data) {
        setLoading(false);

        if (data?.rows?.length > 0 || search.name) {
          setDataSource(data?.rows);
          setIsEmpty(false);
        } else {
          setIsEmpty(true);
        }

        setTotal(data?.count);

        const maxPage = Math.ceil(data?.count / limit);
        const storedPage = getAtSession("liveTv")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("liveTv", {
            ...getAtSession("liveTv"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("liveTv", {
            ...getAtSession("liveTv"),
            page: currentPage,
          });
        }
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (getAtSession("liveTv") && getAtSession("liveTv")?.page > currentPage) {
        setInSession("liveTv", {
          ...getAtSession("liveTv"),
          page: 1,
        });
        setCurrentPage(getAtSession("liveTv")?.page);
      }

      fetchLiveTvData();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, sort, search, currentPage, getAgain, getAganaFromDrawer]);

  return (
    <TableComponent
      header={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Space style={{ justifyContent: "flex-end" }}>
            <Popconfirm
              placement="topRight"
              title="Do you want enable all content"
              onConfirm={() => onEnableOrDisableAll(true)}
              okText="Enable"
              cancelText="Cancel"
              okButtonProps={{
                className: "enable-button",
              }}
            >
              <ButtonComponent title="Enable All" className="enable-button" />
            </Popconfirm>

            <Popconfirm
              placement="topRight"
              title="Do you want disable all content"
              onConfirm={() => onEnableOrDisableAll(false)}
              okText="Disable"
              cancelText="Cancel"
              okButtonProps={{
                className: "disable-button",
              }}
            >
              <ButtonComponent title="Disable All" className="disable-button" />
            </Popconfirm>

            <ButtonComponent icon={icons.ADD} title="Add Live TV" onClick={openDrawer} />
          </Space>
          <Space style={{ marginTop: 20 }}>
            {selectedRowKeys.length ? (
              <>
                <Popconfirm
                  placement="topRight"
                  title="Do you want to enable selected live tvs?"
                  onConfirm={(e) => bulkActions("enable", selectedRowKeys)}
                  okText="Enable"
                  cancelText="Cancel"
                >
                  <ButtonComponent type="default" title="Enable" />
                </Popconfirm>

                <Popconfirm
                  placement="topRight"
                  title="Do you want to disable selected live tvs?"
                  onConfirm={(e) => bulkActions("disable", selectedRowKeys)}
                  okText="Disable"
                  cancelText="Cancel"
                >
                  <ButtonComponent type="default" title="Disable" />
                </Popconfirm>

                <Popconfirm
                  placement="topRight"
                  title="Do you want to archive selected live tvs?"
                  onConfirm={(e) => bulkActions("archive", selectedRowKeys)}
                  okText="Archive"
                  cancelText="Cancel"
                >
                  <ButtonComponent type="default" title="Archive" />
                </Popconfirm>

                <Popconfirm
                  placement="topRight"
                  title="Do you want to restore selected live tvs?"
                  onConfirm={(e) => bulkActions("restore", selectedRowKeys)}
                  okText="Restore"
                  cancelText="Cancel"
                >
                  <ButtonComponent type="default" title="Restore" />
                </Popconfirm>

                <Popconfirm
                  placement="topRight"
                  title="Do you want to delete selected live tvs?"
                  onConfirm={(e) => bulkActions("delete", selectedRowKeys)}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <ButtonComponent type="default" title="Delete" />
                </Popconfirm>

                <ButtonComponent
                  type="default"
                  title="Edit"
                  onClick={() => setShowEditDrawer(true)}
                />
              </>
            ) : (
              ""
            )}
          </Space>

          <BulkEditDrawer
            open={showEditDrawer}
            onClose={() => setShowEditDrawer(false)}
            // ids={selectedRowKeys}
            finish={(value, field) => handleFinish(value, field)}
          />
        </div>
      }
      rowSelection={rowSelection}
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      localeClick={openDrawer}
      isEmpty={isEmpty}
      rowClassName={(row) => {
        if (row.archive) return "red-row";
      }}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      scroll={{
        x: 1500,
      }}
    />
  );
}
