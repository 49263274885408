import { Alert, Drawer, Form, Input, Select, notification } from "antd";
import { usePaymentsSubscriptionsOptions } from "../../../hooks/selectOptions";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import icons from "../../../config/icons";
import { useState } from "react";

const AddMultiUserDrawer = ({ open, onClose, sendValue }) => {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const paymentOPtion = usePaymentsSubscriptionsOptions();

  const handleFinish = (value) => {
    setIsLoading(true);

    const success = (data) => {
      setIsLoading(false);
      const response = data.message;

      const blob = new Blob([response], { type: "text/plain" });

      const downloadLink = document.createElement("a");

      downloadLink.download = "users.csv";

      downloadLink.href = window.URL.createObjectURL(blob);

      downloadLink.click();

      window.URL.revokeObjectURL(downloadLink.href);
      form.resetFields();
      onClose();
    };

    const error = (err) => {
      console.log(err);
      setIsLoading(false);

      notification.error({
        error: "Error",
        message: "Something wrong",
      });
    };

    sendValue(value, success, error);
  };

  return (
    <>
      <Drawer
        title={"Add user multiple"}
        open={open}
        onClose={() => {
          onClose();
          form.resetFields();
        }}
      >
        <Form form={form} onFinish={handleFinish} layout="vertical">
          <Form.Item label="User Count" name="user_count">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Select tariff" name="user_tariff">
            <Select options={paymentOPtion} />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 10,
            }}
          >
            <ButtonComponent
              title={"Save"}
              icon={icons.CHECK}
              isLoading={isLoading}
              onClick={() => {
                form.submit();
              }}
            />
          </div>
        </Form>
        {isLoading && (
          <div
            style={{
              marginTop: 10,
            }}
          >
            <Alert message="Please do not close this window" type="warning" />
          </div>
        )}
      </Drawer>
    </>
  );
};

export default AddMultiUserDrawer;
