import { useEffect, useState } from "react";
import PositionComponent from "../../../_components/PositionComponent";
import TableComponent from "../../../_components/table/TableComponent";
import {
  getAtSession,
  getAtLocal,
  setInSession,
  setInLocal,
} from "../../../helpers/storages";
import { Image, Space, Typography } from "antd";
import defaultImage from "../../../img/photo.png";
import TableButtons from "../../../_components/table/TableButtons";
import icons from "../../../config/icons";
import TableEmpty from "../../../_components/table/TableEmpty";
import { asc_desc } from "../../../helpers/ascDesc";
import ButtonComponent from "../../../_components/ButtonComponent";

const CollectionPageTable = ({
  getAgain,
  getDataSource,
  handleMenuClick,
  handleChangePosition,
  showDrawer,
  bulkDelete,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [limit, setLimit] = useState(getAtLocal("pageLimit_collections_page") || 10);
  const [currentPage, setCurrentPage] = useState(
    getAtSession("collections_page")?.page || 1
  );
  const [sort, setSort] = useState(
    getAtSession("collections_page")?.sort || ["id", "DESC"]
  );
  const [total, setTotal] = useState(0);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: "#",
      key: "index",
      defaultSortOrder:
        getAtSession("collections_page")?.sort?.[0] === "id" &&
        asc_desc(getAtSession("collections_page")?.sort?.[1]),
      dataIndex: `id`,
      align: "center",
      fixed: "left",
      width: 30,

      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 30,
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      render: (text, record, index) => {
        return (
          <PositionComponent
            position={record.collection_movie.position}
            onChangePosition={(e) => handleChangePosition(e, record.collection_movie.id)}
          />
        );
      },
      align: "center",
      width: 110,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: 120,
    },

    {
      title: "Image",
      dataIndex: "img",
      key: "image",
      render: (text, record, index) => {
        return (
          <Image
            src={record.poster_compressed}
            width={30}
            height={30}
            style={{ objectFit: "cover", borderRadius: "50%" }}
            fallback={defaultImage}
          />
        );
      },
      align: "center",
      width: 60,
    },
    {
      title: "year",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 60,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      width: 80,
    },
    {
      title: "Countries",
      dataIndex: "countries",
      key: "countries",
      align: "center",
      width: 80,
      render: (text, record, index) => {
        return record.available_countries?.length ? (
          <Space>
            <Typography.Text>{record.available_countries}</Typography.Text>
          </Space>
        ) : (
          <TableEmpty />
        );
      },
    },
    {
      key: "",
      dataIndex: "operations",
      align: "center",
      fixed: "right",
      width: 60,

      render: (text, record, index) => {
        return (
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e, record)}
            items={[
              {
                key: "delete",
                name: "Delete",
                icon: icons.DELETE,
              },
            ]}
          />
        );
      },
    },
  ];

  const handleTableChange = (data) => {
    const collections = {
      page: data.page,
    };
    if (data.sort) {
      collections.sort = [`${data.sort[0]}`, `${data.sort[1]}`];
    }
    setInLocal("pageLimit_collections_page", data.limit);
    setInSession("collections_page", collections);

    setCurrentPage(data?.page);
    setLimit(data?.limit);
    setSort(data?.sort);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    const fetchCollections = () => {
      setLoading(true);

      const query = {
        sort,
        limit,
        page: currentPage,
      };

      function onSuccess(data) {
        setLoading(false);

        if (data?.message?.rows?.length > 0) {
          setDataSource(data?.message?.rows);
          setIsEmpty(false);
        } else {
          setIsEmpty(true);
        }

        setTotal(data?.message?.total);

        const maxPage = Math.ceil(data?.message?.count / limit);
        const storedPage = getAtSession("collections_page")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("collections_page", {
            ...getAtSession("collections_page"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("collections_page", {
            ...getAtSession("collections_page"),
            page: currentPage,
          });
        }
      }

      function onError(data) {
        setLoading(false);
        console.log(data);
      }

      getDataSource(query, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (
        getAtSession("collections_page") &&
        getAtSession("collections_page")?.page > currentPage
      ) {
        setInSession("collections_page", {
          ...getAtSession("collections_page"),
          page: 1,
        });
        setCurrentPage(getAtSession("collections_page")?.page);
      }

      fetchCollections();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, sort, currentPage, getAgain]);

  return (
    <div>
      <TableComponent
        header={
          <div style={{ display: "flex", justifyContent: "space-between", gap: 10 }}>
            {selectedRowKeys.length > 0 && (
              <ButtonComponent
                title={"Delete"}
                icon={icons.DELETE}
                onClick={() => bulkDelete(selectedRowKeys)}
              />
            )}
            <ButtonComponent
              title={"Add Content"}
              onClick={() => showDrawer()}
              // icon={"add"}
              actionType={"add"}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            />
          </div>
        }
        columns={columns}
        dataSource={dataSource}
        pagination={{
          page: currentPage,
          limit: limit,
          total: total,
        }}
        isLoading={loading}
        onChange={handleTableChange}
        rowClassName={(row) => {
          if (row.archive) return "red-row";
        }}
        rowSelection={rowSelection}
        localeClick={() => showDrawer()}
        isEmpty={isEmpty}
        scroll={{
          x: 1400,
        }}
      />
    </div>
  );
};

export default CollectionPageTable;
