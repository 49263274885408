import { useEffect, useState } from "react";
import { Pie } from "@ant-design/plots";
import ChartComponent from "../../../../../_components/chart/ChartComponent";
import REQUESTS from "../../../../../api/requests";
import styles from "../../../styles/_charts.module.scss";
import { Empty } from "antd";

const PlatformChart = () => {
  const [isEmpty, setIsEmpty] = useState(false);

  const [deviceData, setDeviceData] = useState({
    appendPadding: 10,
    data: [],
    angleField: "count",
    colorField: "name",
    // radius: 0.8,
    label: {
      type: "inner",
      content: " ",
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  });
  const getDevices = () => {
    REQUESTS.DASHBOARD.DEVICE_PLATFORM()
      .then((response) => {
        if (!response.error) {
          setDeviceData({
            ...deviceData,
            data: response?.message,
          });
        }
      })
      .catch((err) => {
        console.log(`err`, err);
      });
  };

  useEffect(() => {
    getDevices();
  }, []);

  useEffect(() => {
    !deviceData.data.length ? setIsEmpty(true) : setIsEmpty(false);
  }, [deviceData]);

  return (
    <div className={styles["chart-wrapper"]}>
      <ChartComponent
        title="Device platform"
        children={isEmpty ? <Empty style={{ height: 400 }} /> : <Pie {...deviceData} />}
      />
    </div>
  );
};

export default PlatformChart;
