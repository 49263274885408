import { notification } from "antd";
import { useEffect, useState } from "react";

import REQUESTS from "../../../../../../api/requests";

import SubusersTable from "./SubusersTable";
import SubuserDrawer from "./SubuserDrawer";

export default function Subusers({ user }) {
    const [getAgain, setGetAgain] = useState(false);

    const [editable, setEditable] = useState(null);

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);

    const getDataSource = (query, onSuccess, onError) => {
        const id = user?.id;

        REQUESTS.USERS.USER.SUBUSERS.GET(id, { query: JSON.stringify(query) })
            .then((response) => {
                onSuccess(response.message);
            })
            .catch((error) => {
                onError(error);
            });
    };

    const handleMenuClick = (event, item) => {
        switch (event.key) {
            case "edit":
                setEditable(item);
                setIsOpenDrawer(true);
                break;

            case "delete":
                REQUESTS.USERS.USER.SUBUSERS.DELETE({ id: item.id })
                    .then((response) => {
                        if (response.error) {
                            notification.error({
                                message: "Error",
                                description: response.message,
                            });

                            return;
                        }

                        notification.success({
                            message: "Success",
                            description: response.message,
                        });

                        setGetAgain((prev) => !prev);
                    })
                    .catch((error) => {
                        notification.error({
                            message: "Error",
                            description: error,
                        });
                    });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        setGetAgain((prev) => !prev);
    }, [user]);

    return (
        <div>
            <SubusersTable
                getAgain={getAgain}
                getDataSource={getDataSource}
                handleMenuClick={handleMenuClick}
            />

            <SubuserDrawer
                open={isOpenDrawer}
                editable={editable}
                getData={() => setGetAgain((prev) => !prev)}
                onClose={() => {
                    setIsOpenDrawer(false);
                    setEditable(null);
                }}
            />
        </div>
    );
}
