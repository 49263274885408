import { useEffect, useState } from "react";
import { Divider, Select, Space, Tabs } from "antd";

import REQUESTS from "../../../api/requests";

import ImagesTab from "./tabs/ImagesTab";
import InformaitonTab from "./tabs/InformationTab";
import PageComponent from "../../../_components/page/PageComponent";

export default function BasicInfoPage({}) {
    const [data, setData] = useState([]);

    const [languageOptions, setLanguageOptions] = useState([]);

    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const [selectedData, setSelectedData] = useState(null);

    const getData = (again) => {
        REQUESTS.APPLICATION.BASIC_INFO.GET()
            .then((response) => {
                if (response.message) {
                    if (!again) {
                        let languages = response.message.map((item) => {
                            return {
                                label: item.language.name,
                                value: item.language.id,
                                iso_code: item.language.iso_code,
                            };
                        });

                        setLanguageOptions(languages);

                        const eng = languages.find((item) => item.iso_code === "en");

                        setSelectedLanguage(eng.value);
                    }

                    setData(response.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            const selected = data.find((item) => item.language.id === selectedLanguage);

            setSelectedData(selected);
        }
    }, [selectedLanguage]);

    const tabs = [
        {
            label: "Informaiton",
            key: "informaiton",
            children: <InformaitonTab data={selectedData} getData={getData} />,
        },

        {
            label: "Images",
            key: "images",
            children: <ImagesTab data={selectedData} getData={getData} />,
        },
    ];

    return (
        <PageComponent routes={["Application", "Basic Info"]}>
            <Space>
                <span>Language</span>
                <Select
                    style={{ width: "200px" }}
                    options={languageOptions}
                    value={selectedLanguage}
                    onChange={setSelectedLanguage}
                />
            </Space>

            <Divider />

            <Tabs tabPosition="left" items={tabs} />
        </PageComponent>
    );
}
