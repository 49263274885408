import { Drawer, Tabs } from "antd";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import ChannelTab from "./ChannelTab";
import VodTab from "./VodTab";

export default function WebSliderDrawer({ onClose, open, getData }) {
    const { permissions } = useSelector((state) => state.globalState);

    const [active, setActive] = useState("1");

    const items = [
        permissions?.Vod_Manager && {
            key: permissions?.Vod_Manager && "1",
            label: `Vod`,
            children: (
                <VodTab
                    open={open}
                    onClose={() => {
                        onClose();
                        getData();
                    }}
                />
            ),
        },

        permissions?.LIVE_TV && {
            key: permissions?.Vod_Manager ? "2" : "1",
            label: `Channel`,
            children: (
                <ChannelTab
                    open={open}
                    onClose={() => {
                        onClose();
                        getData();
                    }}
                />
            ),
        },
    ];

    useEffect(() => {
        if (!open) {
            setActive("1");
        }
    }, [open]);

    return (
        <Drawer title="Add" placement="right" onClose={onClose} open={open}>
            <Tabs defaultActiveKey="1" activeKey={active} onChange={setActive} items={items} />
        </Drawer>
    );
}
