import { Empty, notification } from "antd";
import { useEffect, useState } from "react";

import REQUESTS from "../../../api/requests";

import Server from "../../../_components/server/Server";
import Loading from "../../../_components/loadings/Loading";
import PageComponent from "../../../_components/page/PageComponent";

import styles from "./_catch-up-servers.module.scss";

export default function CatchUpServersPage({}) {
    const [servers, setServers] = useState([]);
    const [getAgain, setGetAgain] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const getServers = (haveLoading) => {
        if (haveLoading) setIsLoading(true);

        REQUESTS.CATCH_UP.SERVERS.GET()
            .then((response) => {
                if (haveLoading) setIsLoading(false);

                if (response.error) {
                    return;
                }
                setServers(response.message?.rows);
            })
            .catch(() => {
                if (haveLoading) setIsLoading(true);
            });
    };

    const deleteServer = (item) => {
        REQUESTS.CATCH_UP.SERVERS.DELETE({ id: item.id })
            .then((response) => {
                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });

                    return;
                }

                notification.success({
                    message: "Success",
                    description: "Server is deleted",
                });

                setGetAgain((prev) => !prev);
            })
            .catch((error) => {
                notification.error({
                    message: "Error",
                    description: error,
                });
            });
    };

    useEffect(() => {
        getServers(true);
    }, [getAgain]);

    useEffect(() => {
        const interval = setInterval(() => {
            getServers(false);
        }, 3000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <PageComponent routes={["Catch-up", "Servers"]}>
            {isLoading ? (
                <Loading />
            ) : servers.length === 0 ? (
                <div
                    style={{
                        height: 300,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Empty description="You do not have servers." />
                </div>
            ) : (
                <div className={styles["servers-list"]}>
                    {servers.map((item) => {
                        return <Server item={item} onDelete={() => deleteServer(item)} />;
                    })}
                </div>
            )}
        </PageComponent>
    );
}
