import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  MobileOutlined,
  LockOutlined,
  UnlockOutlined,
  TagsOutlined,
  MinusOutlined,
  SettingOutlined,
  SafetyCertificateOutlined,
  CloseCircleOutlined,
  SendOutlined,
  FolderOpenOutlined,
  CodeOutlined,
  DragOutlined,
  LeftOutlined,
  InfoCircleOutlined,
  LoginOutlined,
} from "@ant-design/icons";

import Icon from "@ant-design/icons";

export default {
  // a
  ADD: <PlusOutlined />,
  ARCHIVE_ICON: <i className="fas fa-archive" />,

  ARROW_UP: <ArrowUpOutlined />,
  ARROW_DOWN: <ArrowDownOutlined />,

  ANDROID_MOBILE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M398 931h164v-40H398v40Zm-138 85q-24 0-42-18t-18-42V196q0-24 18-42t42-18h440q24 0 42 18t18 42v760q0 24-18 42t-42 18H260Zm0-150v90h440v-90H260Zm0-60h440V286H260v520Zm0-580h440v-30H260v30Zm0 640v90-90Zm0-640v-30 30Z" />
    </svg>
  ),

  ADMIN: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M480 575q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM160 896v-94q0-38 19-65t49-41q67-30 128.5-45T480 636q62 0 123 15.5t127.921 44.694q31.301 14.126 50.19 40.966Q800 764 800 802v94H160Zm60-60h520v-34q0-16-9.5-30.5T707 750q-64-31-117-42.5T480 696q-57 0-111 11.5T252 750q-14 7-23 21.5t-9 30.5v34Zm260-321q39 0 64.5-25.5T570 425q0-39-25.5-64.5T480 335q-39 0-64.5 25.5T390 425q0 39 25.5 64.5T480 515Zm0-90Zm0 411Z" />
    </svg>
  ),

  ADS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M468 816q-96-5-162-74t-66-166q0-100 70-170t170-70q97 0 166 66t74 163l-63-20q-11-64-60-106.5T480 396q-75 0-127.5 52.5T300 576q0 67 42.5 116.5T449 753l19 63Zm48 158q-9 1-18 1.5t-18 .5q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 9-.5 18t-1.5 18l-58-18v-18q0-142-99-241t-241-99q-142 0-241 99t-99 241q0 142 99 241t241 99h18l18 58Zm305 22L650 825l-50 151-120-400 400 120-151 50 171 171-79 79Z" />
    </svg>
  ),

  APPLICATION_SETTINGS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M452 770h56l10-54q20-6 34-15t26-21l62 19 26-54-47-30q4-21 4-39t-4-39l47-30-26-54-62 19q-12-12-26-21t-34-15l-10-54h-56l-10 54q-20 6-34 15t-26 21l-62-19-26 54 47 30q-4 21-4 39t4 39l-47 30 26 54 62-19q12 12 26 21t34 15l10 54Zm28-109q-36 0-60.5-24.5T395 576q0-36 24.5-60.5T480 491q36 0 60.5 24.5T565 576q0 36-24.5 60.5T480 661ZM180 936q-24 0-42-18t-18-42V276q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600V276H180v600Zm0-600v600-600Z" />
    </svg>
  ),

  ARCHIVE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M120 896V748h720v148H120Zm60-38h72v-72h-72v72Zm-60-454V256h720v148H120Zm60-38h72v-72h-72v72Zm-60 284V502h720v148H120Zm60-38h72v-72h-72v72Z" />
    </svg>
  ),

  // b
  BACK: <LeftOutlined />,

  // c
  CHAT: (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 2C0.447715 2 0 2.44772 0 3V12C0 12.5523 0.447715 13 1 13H14C14.5523 13 15 12.5523 15 12V3C15 2.44772 14.5523 2 14 2H1ZM1 3L14 3V3.92494C13.9174 3.92486 13.8338 3.94751 13.7589 3.99505L7.5 7.96703L1.24112 3.99505C1.16621 3.94751 1.0826 3.92486 1 3.92494V3ZM1 4.90797V12H14V4.90797L7.74112 8.87995C7.59394 8.97335 7.40606 8.97335 7.25888 8.87995L1 4.90797Z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  ),

  CHECK: <CheckOutlined />,

  CHECK_PRICE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.0229 6.52289L9.4263 14.1195L5.28795 9.99265L3.66258 11.618L9.4263 17.3817L18.6483 8.15979L17.0229 6.52289ZM11.7318 0.0905762C5.36864 0.0905762 0.204346 5.25487 0.204346 11.618C0.204346 17.9812 5.36864 23.1455 11.7318 23.1455C18.0949 23.1455 23.2592 17.9812 23.2592 11.618C23.2592 5.25487 18.0949 0.0905762 11.7318 0.0905762ZM11.7318 20.84C6.63666 20.84 2.50983 16.7132 2.50983 11.618C2.50983 6.52289 6.63666 2.39607 11.7318 2.39607C16.8269 2.39607 20.9537 6.52289 20.9537 11.618C20.9537 16.7132 16.8269 20.84 11.7318 20.84Z"
        fill="#0F87B2"
      />
    </svg>
  ),

  CONFIGS: <SettingOutlined />,

  CLOSE: <i className="fa fa-times" aria-hidden="true" />,

  COLLECTION: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path d="M320-320h480v-480h-80v280l-100-60-100 60v-280H320v480Zm0 80q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm360-720h200-200Zm-200 0h480-480Z" />
    </svg>
  ),

  // d
  DASHBOARD: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M160 896V456h140v440H160Zm250 0V256h140v640H410Zm250 0V616h140v280H660Z" />
    </svg>
  ),
  DELETE: <i className="fas fa-trash-alt" />,

  DISABLE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //enableBackground="new 0 0 24 24"
      enableBackground="new 0 0 24 24"
      height="18px"
      viewBox="0 0 24 24"
      width="18px"
      fill="currentColor"
    >
      <path d="M7.94,5.12L6.49,3.66C8.07,2.61,9.96,2,12,2c5.52,0,10,4.48,10,10c0,2.04-0.61,3.93-1.66,5.51l-1.46-1.46 C19.59,14.86,20,13.48,20,12c0-4.41-3.59-8-8-8C10.52,4,9.14,4.41,7.94,5.12z M17.66,9.53l-1.41-1.41l-2.65,2.65l1.41,1.41 L17.66,9.53z M19.78,22.61l-2.27-2.27C15.93,21.39,14.04,22,12,22C6.48,22,2,17.52,2,12c0-2.04,0.61-3.93,1.66-5.51L1.39,4.22 l1.41-1.41l18.38,18.38L19.78,22.61z M16.06,18.88l-3.88-3.88l-1.59,1.59l-4.24-4.24l1.41-1.41l2.83,2.83l0.18-0.18L5.12,7.94 C4.41,9.14,4,10.52,4,12c0,4.41,3.59,8,8,8C13.48,20,14.86,19.59,16.06,18.88z" />
    </svg>
  ),

  // e
  EPG: (
    // <svg
    //     width="15"
    //     height="15"
    //     viewBox="0 0 15 15"
    //     fill="currentColor"
    //     xmlns="http://www.w3.org/2000/svg"
    // >
    //     <path
    //         d="M0 1.5C0 1.22386 0.223858 1 0.5 1H2.5C2.77614 1 3 1.22386 3 1.5C3 1.77614 2.77614 2 2.5 2H0.5C0.223858 2 0 1.77614 0 1.5ZM4 1.5C4 1.22386 4.22386 1 4.5 1H14.5C14.7761 1 15 1.22386 15 1.5C15 1.77614 14.7761 2 14.5 2H4.5C4.22386 2 4 1.77614 4 1.5ZM4 4.5C4 4.22386 4.22386 4 4.5 4H11.5C11.7761 4 12 4.22386 12 4.5C12 4.77614 11.7761 5 11.5 5H4.5C4.22386 5 4 4.77614 4 4.5ZM0 7.5C0 7.22386 0.223858 7 0.5 7H2.5C2.77614 7 3 7.22386 3 7.5C3 7.77614 2.77614 8 2.5 8H0.5C0.223858 8 0 7.77614 0 7.5ZM4 7.5C4 7.22386 4.22386 7 4.5 7H14.5C14.7761 7 15 7.22386 15 7.5C15 7.77614 14.7761 8 14.5 8H4.5C4.22386 8 4 7.77614 4 7.5ZM4 10.5C4 10.2239 4.22386 10 4.5 10H11.5C11.7761 10 12 10.2239 12 10.5C12 10.7761 11.7761 11 11.5 11H4.5C4.22386 11 4 10.7761 4 10.5ZM0 13.5C0 13.2239 0.223858 13 0.5 13H2.5C2.77614 13 3 13.2239 3 13.5C3 13.7761 2.77614 14 2.5 14H0.5C0.223858 14 0 13.7761 0 13.5ZM4 13.5C4 13.2239 4.22386 13 4.5 13H14.5C14.7761 13 15 13.2239 15 13.5C15 13.7761 14.7761 14 14.5 14H4.5C4.22386 14 4 13.7761 4 13.5Z"
    //         fill="currentColor"
    //         fillRule="evenodd"
    //         clipRule="evenodd"
    //     ></path>
    // </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M100 726v-60h306v60H100Zm0-165v-60h473v60H100Zm0-165v-60h473v60H100Zm542 540V614l218 161-218 161Z" />
    </svg>
  ),

  EDIT: <i className="fas fa-pencil-alt" />,

  ENABLE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18px"
      viewBox="0 0 24 24"
      width="18px"
      fill="currentColor"
    >
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
    </svg>
  ),

  // f
  FOLDER: <FolderOpenOutlined />,
  // g
  GALLERY: <i className="fas fa-images" />,

  // h
  HISTORY: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M142 449V241h60v106q52-61 123.5-96T477 216q151 0 257 104t106 254v10h-60v-13q0-124-89-209.5T477 276q-68 0-127.5 31T246 389h105v60H142Zm-22 126h60q0 125 86 213t211 88h12l34 57q-11 2-22.5 2.5t-23.5.5q-149 0-253-105.5T120 575Zm440 119L451 587V373h60v189l81 79-32 53Zm175 352-10-66q-18-5-34-13.5T661 944l-55 12-25-42 47-44q-3-12-3-25t3-25l-47-44 25-42 55 12q14-14 30-22.5t34-13.5l10-66h54l10 66q18 5 34 13.5t30 22.5l55-12 25 42-47 44q3 12 3 25t-3 25l47 44-25 42-55-12q-14 14-30 22.5T799 980l-10 66h-54Zm27-121q36 0 58-22t22-58q0-36-22-58t-58-22q-36 0-58 22t-22 58q0 36 22 58t58 22Z" />
    </svg>
  ),
  // i
  INFO: <InfoCircleOutlined />,
  IMPORT: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="m570 635 146-146-42-43-74 73V339h-60v181l-74-74-42 43 146 146Zm114 223h123q0 21-15.5 35.5T754 910l-540 65q-25 3-44.5-11.5T147 924L83 426q-3-25 12.237-44.964Q110.473 361.071 135 358l64-7v61l-57 6 65 498 477-58Zm-365-60q-24.75 0-42.375-17.625T259 738V236q0-24.75 17.625-42.375T319 176h502q24.75 0 42.375 17.625T881 236v502q0 24.75-17.625 42.375T821 798H319Zm0-60h502V236H319v502ZM207 916Zm112-178V236v502Z" />
    </svg>
  ),
  // j
  // k
  // l
  LOGIN: <LoginOutlined />,
  LOGS: <CodeOutlined />,
  LOCK: <LockOutlined />,
  LAUNCHER: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M40 817q8-106 65-196.5T256 477l-75-129q-3-9-.5-18t10.5-14q9-5 19.5-2t15.5 12l74 127q86-37 180-37t180 37l75-127q5-9 15.5-12t19.5 2q8 5 11.5 14.5T780 348l-76 129q94 53 151 143.5T920 817H40Zm240-110q20 0 35-15t15-35q0-20-15-35t-35-15q-20 0-35 15t-15 35q0 20 15 35t35 15Zm400 0q20 0 35-15t15-35q0-20-15-35t-35-15q-20 0-35 15t-15 35q0 20 15 35t35 15Z" />
    </svg>
  ),

  LESSONS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M493 917q3 14 11.5 31t17.5 29H180q-24 0-42-18t-18-42V236q0-24 18-42t42-18h520q24 0 42 18t18 42v339q-13.5-2-30-2t-30 2V236H462v278l-96-59-96 59V236h-90v681h313Zm235 99q-81 0-136.5-55.5T536 824q0-81 55.5-136.5T728 632q81 0 136.5 55.5T920 824q0 81-55.5 136.5T728 1016Zm-49-100 143-92-143-92v184ZM270 236h192-192Zm223 0H180h520-223 16Z" />
    </svg>
  ),

  LIVE_TV: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="m383 706 267-170-267-170v340Zm-53 230v-80H140q-24 0-42-18t-18-42V276q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H630v80H330ZM140 796h680V276H140v520Zm0 0V276v520Z" />
    </svg>
  ),
  // m
  MOBILE: <MobileOutlined />,
  MINUS: <MinusOutlined />,
  MOVE: <DragOutlined />,
  // n
  NOTIFICATIONS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M480 976q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576v53q0 56-39.5 94.5T744 762q-36 0-68-17.5T627 695q-26 34-65 50.5T480 762q-78 0-132.5-54T293 576q0-78 54.5-133T480 388q78 0 132.5 55T667 576v53q0 31 22.5 52t54.5 21q31 0 53.5-21t22.5-52v-53q0-142-99-241t-241-99q-142 0-241 99t-99 241q0 142 99 241t241 99h214v60H480Zm0-274q53 0 90-36.5t37-89.5q0-54-37-91t-90-37q-53 0-90 37t-37 91q0 53 37 89.5t90 36.5Z" />
    </svg>
  ),
  // o
  ONLINE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 -960 960 960"
      width="48"
    >
      <path d="M197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763l36 36q-49 47-76 111.5T130-480q0 71 27 135.5T233-233l-36 36Zm80-80q-38-38-62.5-91.5T190-480q0-58 24.5-111.5T277-683l35 35q-34 30-53 76t-19 92q0 46 19 92t53 76l-35 35Zm165-23q0-21-15-43t-32.5-45Q377-411 362-436.5T347-490q0-55 39-94t94-39q55 0 94 39t39 94q0 28-15 53.5T565.5-388Q548-365 533-343t-15 43h-76Zm-2 90v-50h80v50h-80Zm243-67-35-35q34-30 53-76t19-92q0-46-19-92t-53-76l35-35q38 38 62.5 91.5T770-480q0 58-24.5 111.5T683-277Zm80 80-36-36q49-47 76-111.5T830-480q0-71-27-135.5T727-727l36-36q54 54 85.5 127T880-480q0 83-31.5 156T763-197Z" />
    </svg>
  ),
  // p
  PUBLISH: <i className="fa fa-download" />,

  PLAY: <PlayCircleOutlined />,

  PAUSE: <PauseCircleOutlined />,

  PAYMENTS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M540 636q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM220 776q-24.75 0-42.375-17.625T160 716V316q0-24.75 17.625-42.375T220 256h640q24.75 0 42.375 17.625T920 316v400q0 24.75-17.625 42.375T860 776H220Zm100-60h440q0-42 29-71t71-29V416q-42 0-71-29t-29-71H320q0 42-29 71t-71 29v200q42 0 71 29t29 71Zm480 180H100q-24.75 0-42.375-17.625T40 836V376h60v460h700v60ZM220 716V316v400Z" />
    </svg>
  ),

  PROTECT: <SafetyCertificateOutlined />,
  // q
  // r
  RESTORE: <i className="fas fa-window-restore" />,

  // s
  SEND: <SendOutlined />,
  SETTINGS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="m388 976-20-126q-19-7-40-19t-37-25l-118 54-93-164 108-79q-2-9-2.5-20.5T185 576q0-9 .5-20.5T188 535L80 456l93-164 118 54q16-13 37-25t40-18l20-127h184l20 126q19 7 40.5 18.5T669 346l118-54 93 164-108 77q2 10 2.5 21.5t.5 21.5q0 10-.5 21t-2.5 21l108 78-93 164-118-54q-16 13-36.5 25.5T592 850l-20 126H388Zm92-270q54 0 92-38t38-92q0-54-38-92t-92-38q-54 0-92 38t-38 92q0 54 38 92t92 38Zm0-60q-29 0-49.5-20.5T410 576q0-29 20.5-49.5T480 506q29 0 49.5 20.5T550 576q0 29-20.5 49.5T480 646Zm0-70Zm-44 340h88l14-112q33-8 62.5-25t53.5-41l106 46 40-72-94-69q4-17 6.5-33.5T715 576q0-17-2-33.5t-7-33.5l94-69-40-72-106 46q-23-26-52-43.5T538 348l-14-112h-88l-14 112q-34 7-63.5 24T306 414l-106-46-40 72 94 69q-4 17-6.5 33.5T245 576q0 17 2.5 33.5T254 643l-94 69 40 72 106-46q24 24 53.5 41t62.5 25l14 112Z" />
    </svg>
  ),

  STREAM: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M197 859q-54-54-85.5-126.5T80 576q0-84 31.5-156.5T197 293l43 43q-46 46-73 107.5T140 576q0 71 26.5 132T240 816l-43 43Zm113-113q-32-32-51-75.5T240 576q0-51 19-94.5t51-75.5l43 43q-24 24-38.5 56.5T300 576q0 38 14 70t39 57l-43 43Zm170-90q-33 0-56.5-23.5T400 576q0-33 23.5-56.5T480 496q33 0 56.5 23.5T560 576q0 33-23.5 56.5T480 656Zm170 90-43-43q24-24 38.5-56.5T660 576q0-38-14-70t-39-57l43-43q32 32 51 75.5t19 94.5q0 50-19 93.5T650 746Zm113 113-43-43q46-46 73-107.5T820 576q0-71-26.5-132T720 336l43-43q54 55 85.5 127.5T880 576q0 83-31.5 155.5T763 859Z"></path>
    </svg>
  ),

  STREAMING: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      viewBox="0 90 900 900"
      width="15"
      fill="currentColor"
    >
      <path d="M197 859q-54-54-85.5-126.5T80 576q0-84 31.5-156.5T197 293l43 43q-46 46-73 107.5T140 576q0 71 26.5 132T240 816l-43 43Zm113-113q-32-32-51-75.5T240 576q0-51 19-94.5t51-75.5l43 43q-24 24-38.5 56.5T300 576q0 38 14 70t39 57l-43 43Zm170-90q-33 0-56.5-23.5T400 576q0-33 23.5-56.5T480 496q33 0 56.5 23.5T560 576q0 33-23.5 56.5T480 656Zm170 90-43-43q24-24 38.5-56.5T660 576q0-38-14-70t-39-57l43-43q32 32 51 75.5t19 94.5q0 50-19 93.5T650 746Zm113 113-43-43q46-46 73-107.5T820 576q0-71-26.5-132T720 336l43-43q54 55 85.5 127.5T880 576q0 83-31.5 155.5T763 859Z"></path>
    </svg>
  ),

  SUBSCRIPTION: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 -960 960 960"
      width="48"
    >
      <path d="M480-40q-112 0-216-66T100-257v137H40v-240h240v60H143q51 77 145.5 138.5T480-100q78 0 147.5-30t121-81.5Q800-263 830-332.5T860-480h60q0 91-34.5 171T791-169q-60 60-140 94.5T480-40Zm-29-153v-54q-45-12-75.5-38.5T324-358l51-17q12 38 42.5 60t69.5 22q40 0 66.5-19.5T580-364q0-33-25-55.5T463-470q-60-25-90-54t-30-78q0-44 30-75t80-38v-51h55v51q38 4 66 24t45 55l-48 23q-15-28-37-42t-52-14q-39 0-61.5 18T398-602q0 32 26 51t84 43q69 29 98 61t29 83q0 25-9 46t-25.5 36Q584-267 560-257.5T506-245v52h-55ZM40-480q0-91 34.5-171T169-791q60-60 140-94.5T480-920q112 0 216 66t164 151v-137h60v240H680v-60h137q-51-77-145-138.5T480-860q-78 0-147.5 30t-121 81.5Q160-697 130-627.5T100-480H40Z" />
    </svg>
  ),

  SOUNDTRACKS: <i className="fa fa-music" />,
  // <FontAwesomeIcon icon="fa-solid fa-chart-simple" />

  STATISTICS: (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 448 512">
      <path d="M160 80c0-26.5 21.5-48 48-48h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V80zM0 272c0-26.5 21.5-48 48-48H80c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272zM368 96h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H368c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z" />
    </svg>
  ),

  STOP: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#e8eae"
    >
      <rect fill="none" height="24" width="24" />
      <path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M12,20c-4.42,0-8-3.58-8-8s3.58-8,8-8 s8,3.58,8,8S16.42,20,12,20z M16,16H8V8h8V16z" />
    </svg>
  ),

  SECONDARY_BACK: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5.25 11.25H20.25C20.4489 11.25 20.6397 11.329 20.7803 11.4697C20.921 11.6103 21 11.8011 21 12C21 12.1989 20.921 12.3897 20.7803 12.5303C20.6397 12.671 20.4489 12.75 20.25 12.75H5.25C5.05109 12.75 4.86032 12.671 4.71967 12.5303C4.57902 12.3897 4.5 12.1989 4.5 12C4.5 11.8011 4.57902 11.6103 4.71967 11.4697C4.86032 11.329 5.05109 11.25 5.25 11.25Z"
        fill="#0F87B2"
      />
      <path
        d="M5.5605 12L11.781 18.219C11.9218 18.3598 12.0009 18.5508 12.0009 18.75C12.0009 18.9491 11.9218 19.1401 11.781 19.281C11.6402 19.4218 11.4492 19.5009 11.25 19.5009C11.0508 19.5009 10.8598 19.4218 10.719 19.281L3.969 12.531C3.89915 12.4613 3.84374 12.3785 3.80593 12.2874C3.76812 12.1963 3.74866 12.0986 3.74866 12C3.74866 11.9013 3.76812 11.8036 3.80593 11.7125C3.84374 11.6214 3.89915 11.5386 3.969 11.469L10.719 4.71897C10.8598 4.57814 11.0508 4.49902 11.25 4.49902C11.4492 4.49902 11.6402 4.57814 11.781 4.71897C11.9218 4.8598 12.0009 5.05081 12.0009 5.24997C12.0009 5.44913 11.9218 5.64014 11.781 5.78097L5.5605 12Z"
        fill="#0F87B2"
      />
    </svg>
  ),

  // t
  TARIFF: <TagsOutlined />,

  TV: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="#e8eaed"
    >
      <path d="M170 936v-80h-30q-24.75 0-42.375-17.625T80 796V316q0-24.75 17.625-42.375T140 256h680q24.75 0 42.375 17.625T880 316v480q0 24.75-17.625 42.375T820 856h-30v80h-23l-26-80H220l-27 80h-23Zm-30-140h680V316H140v480Zm340-240Z" />
    </svg>
  ),
  TRANSCODING: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M271 936 80 745l192-192 42 42-120 120h649v60H194l119 119-42 42Zm418-337-42-42 119-119H117v-60h649L646 258l42-42 192 192-191 191Z" />
    </svg>
  ),
  // u
  UNLOCK: <UnlockOutlined />,

  // UNPROTECT: <CloseCircleOutlined />,
  UNPROTECT: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="18px"
      viewBox="0 0 24 24"
      width="18px"
      fill="currentColor"
    >
      <g>
        <path d="M12,4.14l6,2.25v4.7c0,1.19-0.23,2.36-0.64,3.44l1.51,1.51c0.72-1.53,1.13-3.22,1.13-4.95V5l-8-3L6.78,3.96l1.55,1.55 L12,4.14z M2.81,2.81L1.39,4.22L4,6.83v4.26c0,5.05,3.41,9.76,8,10.91c1.72-0.43,3.28-1.36,4.55-2.62l3.23,3.23l1.41-1.41 L2.81,2.81z M12,19.92c-3.45-1.13-6-4.82-6-8.83V8.83l9.14,9.14C14.24,18.85,13.17,19.54,12,19.92z" />
      </g>
    </svg>
  ),

  USERS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M38 896v-94q0-35 18-63.5t50-42.5q73-32 131.5-46T358 636q62 0 120 14t131 46q32 14 50.5 42.5T678 802v94H38Zm700 0v-94q0-63-32-103.5T622 633q69 8 130 23.5t99 35.5q33 19 52 47t19 63v94H738ZM358 575q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm360-150q0 66-42 108t-108 42q-11 0-24.5-1.5T519 568q24-25 36.5-61.5T568 425q0-45-12.5-79.5T519 282q11-3 24.5-5t24.5-2q66 0 108 42t42 108ZM98 836h520v-34q0-16-9.5-31T585 750q-72-32-121-43t-106-11q-57 0-106.5 11T130 750q-14 6-23 21t-9 31v34Zm260-321q39 0 64.5-25.5T448 425q0-39-25.5-64.5T358 335q-39 0-64.5 25.5T268 425q0 39 25.5 64.5T358 515Zm0 321Zm0-411Z" />
    </svg>
  ),

  USERS_GROUP: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 -960 960 960"
      width="48"
    >
      <path d="M0-240v-53q0-38.567 41.5-62.784Q83-380 150.376-380q12.165 0 23.395.5Q185-379 196-377.348q-8 17.348-12 35.165T180-305v65H0Zm240 0v-65q0-32 17.5-58.5T307-410q32-20 76.5-30t96.5-10q53 0 97.5 10t76.5 30q32 20 49 46.5t17 58.5v65H240Zm540 0v-65q0-19.861-3.5-37.431Q773-360 765-377.273q11-1.727 22.171-2.227 11.172-.5 22.829-.5 67.5 0 108.75 23.768T960-293v53H780Zm-480-60h360v-6q0-37-50.5-60.5T480-390q-79 0-129.5 23.5T300-305v5ZM149.567-410Q121-410 100.5-430.562 80-451.125 80-480q0-29 20.562-49.5Q121.125-550 150-550q29 0 49.5 20.5t20.5 49.933Q220-451 199.5-430.5T149.567-410Zm660 0Q781-410 760.5-430.562 740-451.125 740-480q0-29 20.562-49.5Q781.125-550 810-550q29 0 49.5 20.5t20.5 49.933Q880-451 859.5-430.5T809.567-410ZM480-480q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-600q0 50-34.5 85T480-480Zm.351-60Q506-540 523-557.351t17-43Q540-626 522.851-643t-42.5-17Q455-660 437.5-642.851t-17.5 42.5Q420-575 437.351-557.5t43 17.5ZM480-300Zm0-300Z" />
    </svg>
  ),

  UNPUBLISH: <i className="fa fa-upload" />,

  // v
  VOD: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M314 853V689l132 82-132 82Zm206-382q-45.833 0-77.917-32.118-32.083-32.117-32.083-78Q410 315 442.083 283q32.084-32 77.917-32h40v40h-40q-29.167 0-49.583 20.382Q450 331.765 450 360.882 450 390 470.417 410.5 490.833 431 520 431h40v40h-40Zm120 0v-40h40q29.167 0 49.583-20.382Q750 390.235 750 361.118 750 332 729.583 311.5 709.167 291 680 291h-40v-40h40q45.833 0 77.917 32.118 32.083 32.117 32.083 78Q790 407 757.917 439 725.833 471 680 471h-40Zm-110-90v-40h140v40H530Zm123 265v-80h207V196H340v370h-60V196q0-24.75 17.625-42.375T340 136h520q24.75 0 42.375 17.625T920 196v390q0 24.75-17.625 42.375T860 646H653Zm-553 370q-24.75 0-42.375-17.625T40 956V586q0-24.75 17.625-42.375T100 526h520q24.75 0 42.375 17.625T680 586v370q0 24.75-17.625 42.375T620 1016H100Zm0-60h520V586H100v370Zm500-575ZM360 771Z" />
    </svg>
  ),
  // w
  WEB: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M480 976q-84 0-157-31.5T196 859q-54-54-85-127.5T80 574q0-84 31-156.5T196 291q54-54 127-84.5T480 176q84 0 157 30.5T764 291q54 54 85 126.5T880 574q0 84-31 157.5T764 859q-54 54-127 85.5T480 976Zm0-58q35-36 58.5-82.5T577 725H384q14 60 37.5 108t58.5 85Zm-85-12q-25-38-43-82t-30-99H172q38 71 88 111.5T395 906Zm171-1q72-23 129.5-69T788 725H639q-13 54-30.5 98T566 905ZM152 665h159q-3-27-3.5-48.5T307 574q0-25 1-44.5t4-43.5H152q-7 24-9.5 43t-2.5 45q0 26 2.5 46.5T152 665Zm221 0h215q4-31 5-50.5t1-40.5q0-20-1-38.5t-5-49.5H373q-4 31-5 49.5t-1 38.5q0 21 1 40.5t5 50.5Zm275 0h160q7-24 9.5-44.5T820 574q0-26-2.5-45t-9.5-43H649q3 35 4 53.5t1 34.5q0 22-1.5 41.5T648 665Zm-10-239h150q-33-69-90.5-115T565 246q25 37 42.5 80T638 426Zm-254 0h194q-11-53-37-102.5T480 236q-32 27-54 71t-42 119Zm-212 0h151q11-54 28-96.5t43-82.5q-75 19-131 64t-91 115Z" />
    </svg>
  ),
  // x
  X: <CloseOutlined />,
  // y
  // z
};
