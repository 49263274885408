import { Tabs } from "antd";
import Subusers from "./subusers/Subusers";
import PaymentsTab from "./payments/PaymentsTab";
import DevicesTab from "./devices/DevicesTab";
import MostWatchedChannelsTab from "./most-watched-channels/MostWatchedChannelsTab";
import DocumentsTab from "./documents/DocumentsTab";
import PlaylistsTab from "./playlists/PlaylistsTab";

export default function TabsList({ user, getUser }) {
    const tabs = [
        {
            label: "Subusers",
            key: "sub_users",
            children: <Subusers user={user} />,
        },

        {
            label: "Playlists",
            key: "playlists",
            children: <PlaylistsTab user={user} />,
        },

        {
            label: "Devices",
            key: "devices",
            children: <DevicesTab user={user} />,
        },

        {
            label: "Most watched channels",
            key: "most_watched_channels",
            children: <MostWatchedChannelsTab user={user} />,
        },

        {
            label: "Documents",
            key: "documents",
            children: <DocumentsTab user={user} getUser={getUser} />,
        },

        {
            label: "Payments",
            key: "payments",
            children: <PaymentsTab user={user} />,
        },
    ];

    return <Tabs tabPosition="top" items={tabs} />;
}
