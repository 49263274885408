export default function createLiveTvFormData(
  values,
  poster,
  backdrop,
  editable,
  permissions
) {
  const formData = new FormData();
  formData.append("name", values.name);
  formData.append("age_restricted", values.age_restricted);
  formData.append("content_monetization_type", values.content_monetization_type);
  formData.append("is_protected", values.is_protected);
  formData.append("epg_type", values.epg_type);
  formData.append("is_location_restriction", values.is_location_restriction);
  formData.append("id_epg", values?.id_epg);

  if (values.adds_id?.length) {
    formData.append("ads_id", values?.adds_id.join(","));
  }

  if (values.categories && values.categories.length > 0) {
    formData.append("categories", values.categories.join(","));
  }

  if (values.permission_devices && values.permission_devices.length > 0) {
    formData.append("permission_devices", values.permission_devices.join(","));
  }

  if (
    values.is_location_restriction &&
    values.available_countries &&
    values.available_countries.length > 0
  ) {
    formData.append("available_countries", values.available_countries.join(","));
  }

  if (values.comment) {
    formData.append("comment", values.comment);
  }

  if (permissions?.Archive) {
    formData.append("has_archive", values.has_archive);

    if (values.has_archive) {
      const archivedChannels = {
        url: values.archive_url,
        archiveDays: values.archive_day,
        type: values.archived_channel_type,
      };

      if (values.archived_channel_type === "inorain") {
        archivedChannels.archiverId = values.archive_server;
      }

      if (editable) {
        if (editable?.archived_channel) {
          archivedChannels.id = editable.archived_channel.id;
        }
      }

      formData.append("archivedChannels", JSON.stringify(archivedChannels));
    }
  }

  formData.append("stream_type", values.stream_type);

  if (values.stream_type === "external") {
    formData.append("url", values.url);
  } else {
    formData.append("stream_id", values.stream_id);
  }

  formData.append("urls", JSON.stringify(values?.urls || []));

  if (poster.file) {
    formData.append("image", values.poster);
  }

  if (backdrop.file) {
    formData.append("backdrop", values.backdrop);
  }

  if (values.content_monetization_type === "stv") {
    if (values?.tariffIds?.length) {
      formData.append("tariffIds", values.tariffIds?.join(","));
    }
  }

  return formData;
}
