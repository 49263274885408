import { useState } from "react";
import styles from "./collapse.module.scss";

const CollapseComponent = ({ children }) => {
  const [show, setShw] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShw((prev) => !prev);
  };
  return (
    <div className={styles["collapse"]}>
      <div className={styles["collapse-head"]}>
        <b className={styles["collapse-name"]}>More info</b>
        <div className={styles["collapse-line"]}></div>
        <div
          className={styles["collapse-button"]}
          onClick={handleClick}
          style={
            show
              ? {
                  transform: "rotate(-180deg)",
                  transition: ".3s linear",
                }
              : {
                  transform: "rotate(0deg)",
                  transition: ".3s linear",
                }
          }
        >
          <div className={styles["collapse-icon"]}></div>
        </div>
      </div>
      <div
        className={styles["collapse-content"]}
        style={show ? { height: "100%" } : { height: "0px" }}
      >
        <div className={styles["collapse-child"]}>{children}</div>
        {/* {children} */}
      </div>
    </div>
  );
};

export default CollapseComponent;
