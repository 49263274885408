import { useEffect, useState } from "react";

import icons from "../../../config/icons";

import {
  getColumnDateProps,
  getColumnSearchProps,
  getQueryDate,
  parseDate,
} from "../../../config/config";

import { getAtSession, setInSession, setInLocal } from "../../../helpers/storages";

import TableImage from "../../../_components/table/TableImage";
import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";

export default function AvailableDevicesTable({
  getAgain,
  openDrawer,
  getDataSource,
  handleMenuClick,
}) {
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [isEmpty, setISEmpty] = useState(false);

  const [limit, setLimit] = useState(10);

  const [currentPage, setCurrentPage] = useState(
    getAtSession("availableDevices")?.page || 1
  );

  const [sort, setSort] = useState(
    getAtSession("availableDevices")?.sort || ["id", "DESC"]
  );

  const [total, setTotal] = useState(0);

  const [date, setDate] = useState(null);

  const [search, setSearch] = useState(
    getAtSession("availableDevices")?.search || {
      name: null,
    }
  );

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      fixed: "left",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      align: "center",

      render: (text, record, index) => {
        return <TableImage src={record.image} />;
      },
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      align: "center",

      ...getColumnSearchProps(),
    },

    {
      title: `Created date`,
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) => {
        return parseDate(text);
      },

      ...getColumnDateProps(setDate),
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: icons.EDIT,
            },

            {
              key: "delete",
              name: "Delete",
              icon: icons.DELETE,
              question: `Do you want to delete this device?`,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setInLocal("pageLimit", data.limit);
    setInSession("availableDevices", {
      ...getAtSession("availableDevices"),
      page: data.page,
      limit: data.limit,
      sort: data.sort,
    });

    setSearch(data.search);
  };

  useEffect(() => {
    const fetchLiveTvData = () => {
      setLoading(true);

      const query = {
        // sort,
        limit,
        page: currentPage,
      };

      if (sort && sort[1] && sort[1] !== "undefined") {
        query.sort = sort;
      } else {
        query.sort = ["id", "DESC"];
      }

      if (search.name) {
        query.search = {
          name: search.name,
        };
      }

      if (search.categories) {
        query.filter = {
          categoryId: search.categories,
        };
      }

      function onSuccess(data) {
        setLoading(false);

        if (data?.rows?.length > 0 || search?.name) {
          setDataSource(data?.rows);
          setISEmpty(false);
        } else {
          setISEmpty(true);
        }

        setTotal(data?.count);

        const maxPage = Math.ceil(data?.count / limit);
        const storedPage = getAtSession("availableDevices")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("availableDevices", {
            ...getAtSession("availableDevices"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("availableDevices", {
            ...getAtSession("availableDevices"),
            page: currentPage,
          });
        }
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (
        getAtSession("availableDevices") &&
        getAtSession("availableDevices")?.page > currentPage
      ) {
        setInSession("availableDevices", {
          ...getAtSession("availableDevices"),
          page: 1,
        });
        setCurrentPage(getAtSession("availableDevices")?.page);
      }

      fetchLiveTvData();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, getAgain, date]);

  return (
    <TableComponent
      header={
        <>
          <ButtonComponent icon={icons.ADD} title="Add device" onClick={openDrawer} />
        </>
      }
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      localeClick={openDrawer}
      isEmpty={isEmpty}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      scroll={{
        x: 800,
      }}
    />
  );
}
