import { useEffect, useState } from "react";

import { Button, Drawer, Form, Input, notification } from "antd";
import REQUESTS from "../../../api/requests";

export default function FaqDrawer({ onClose, open, current, getData }) {
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);

        const body = {
            question: values.question,
            answer: values.answer,
        };

        if (current) {
            body.id = current.id;

            REQUESTS.WEB_PAGE.FAQ.EDIT(body)
                .then((response) => {
                    setIsLoading(false);

                    if (response.error) {
                        notification.error({
                            description: response.message,
                        });

                        return;
                    }

                    getData();
                    onClose();
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        } else {
            REQUESTS.WEB_PAGE.FAQ.ADD(body)
                .then((response) => {
                    setIsLoading(false);

                    if (response.error) {
                        notification.error({
                            description: response.message,
                        });

                        return;
                    }
                    getData();
                    onClose();
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        if (!open) {
            form.resetFields();
        }

        if (current) {
            form.setFields([
                { name: "question", value: current.question },
                { name: "answer", value: current.answer },
            ]);
        }
    }, [open, current]);

    return (
        <Drawer
            title={`${current ? "Edit" : "Add"} answer`}
            placement="right"
            onClose={onClose}
            open={open}
        >
            <Form form={form} name="faq" layout="vertical" onFinish={onFinish}>
                <Form.Item
                    label="Question"
                    name="question"
                    rules={[
                        {
                            required: true,
                            message: "Please input question",
                        },
                    ]}
                >
                    <Input.TextArea rows={4} />
                </Form.Item>

                <Form.Item
                    label="Answer"
                    name="answer"
                    rules={[
                        {
                            required: true,
                            message: "Please input answer",
                        },
                    ]}
                >
                    <Input.TextArea rows={6} />
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                    <Button type="primary" onClick={() => form.submit()} loading={isLoading}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
}
