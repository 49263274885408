import { notification } from "antd";

import { useEffect, useState } from "react";

import REQUESTS from "../../../../../../api/requests";

import DevicesTable from "./DevicesTable";

export default function DevicesTab({ user }) {
    const [getAgain, setGetAgain] = useState(false);

    const getDataSource = (query, onSuccess, onError) => {
        REQUESTS.USERS.USER.DEVICES.GET(query)
            .then((response) => {
                onSuccess(response.message);
            })
            .catch((error) => {
                onError(error);
            });
    };

    const handleMenuClick = (event, item) => {
        switch (event.key) {
            case "delete":
                REQUESTS.USERS.USER.DEVICES.DELETE({ id: item.id })
                    .then((response) => {
                        if (response.error) {
                            notification.error({
                                message: "Error",
                                description: response.message,
                            });

                            return;
                        }

                        notification.success({
                            message: "Success",
                            description: response.message,
                        });

                        setGetAgain((prev) => !prev);
                    })
                    .catch((error) => {
                        notification.error({
                            message: "Error",
                            description: error,
                        });
                    });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        setGetAgain((prev) => !prev);
    }, [user]);

    return (
        <div>
            <DevicesTable
                userId={user?.id}
                getAgain={getAgain}
                getDataSource={getDataSource}
                handleMenuClick={handleMenuClick}
            />
        </div>
    );
}
