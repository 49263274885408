import { useEffect, useState } from "react";
import TableComponent from "../../../_components/table/TableComponent";
import {
  getColumnSearchProps,
  parseDate,
  getColumnDateProps,
  getQueryDate,
} from "../../../config/config";
import icons from "../../../config/icons";
import { Button } from "antd";

const NewLauncherDevice = ({ getDataSource, getAgain, activation, buttonLoading }) => {
  const [dataSource, setDataSource] = useState([]);

  const [limit, setLimit] = useState(10);

  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [sort, setSort] = useState(["id", "DESC"]);

  const [date, setDate] = useState(null);

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState({
    device_model: null,
  });

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: "Device id",
      dataIndex: "device_id",
      key: "device_id",
      align: "center",

      ...getColumnSearchProps(),
    },
    {
      title: "Device platform",
      dataIndex: "device_model",
      key: "device_model",
      align: "center",

      ...getColumnSearchProps(),
    },

    {
      title: "Type",
      dataIndex: "device_type",
      key: "device_type",
      align: "center",
    },

    {
      title: `Date`,
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) => {
        return parseDate(text);
      },
      ...getColumnDateProps(setDate),
    },

    {
      title: "Activation",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      render: (is_active, record) => (
        <Button
          icon={icons.ENABLE}
          onClick={() => {
            activation(record?.id);
          }}
          loading={buttonLoading}
        >
          Activate
        </Button>
      ),
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);

      const query = {
        sort,
        limit,
        page: currentPage,
      };

      if (search.device_model) {
        query.search = {
          device_model: search.device_model[0],
        };
      }

      const queryDate = getQueryDate(date);

      if (queryDate) query.between = queryDate;

      function onSuccess(data) {
        if (data?.rows.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
          return;
        }

        setLoading(false);
        const newData = data?.rows.filter((item) => !item?.is_active);
        setDataSource(newData);

        setTotal(data?.total);
        setLimit(data?.limit);
        setCurrentPage(data?.page);
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, date, getAgain]);

  return (
    <TableComponent
      rowClassName={(row) => {
        if (row.is_blocked) return "red-row";
      }}
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
    />
  );
};

export default NewLauncherDevice;
