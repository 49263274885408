import { useEffect, useState, useRef } from "react";
import { Form, Input, Select, Image, Typography, Empty, Modal, Divider } from "antd";
import ButtonComponent from "../../../../../_components/button/ButtonComponent";
import NextBackButton from "../../../../../_components/button/NextBackButton";
import TableComponent from "../../../../../_components/table/TableComponent";

import { copy } from "../../../../../helpers/formats";
import icons from "../../../../../config/icons";
import TableButtons from "../../../../../_components/table/TableButtons";
import {
  getAtLocal,
  getAtSession,
  setInLocal,
  setInSession,
} from "../../../../../helpers/storages";

export default function SeasonsEpisodesStep({
  form,
  onChangeStep,
  season,
  episodeSourced,
  showSeasonDrawer,
  showAddEpisodeDrawer,
  removeSeason,
  seasonId,
  getAgain,
  removeEpisodes,
  playerSourced,
  editableId,
  sowModal,
}) {
  const [episodes, setEpisodes] = useState([]);
  const [seasonOption, setSeasonOption] = useState([]);
  const [episodesinfo, setEpisodesinfo] = useState(null);
  const [selectedKey, setSelectedKey] = useState();
  const [currentPage, setCurrentPage] = useState(
    getAtSession("seasons_episodes")?.page || 1
  );
  const [total, setTotal] = useState(getAtSession("seasons_episodes")?.total || 10);
  const [limit, setLimit] = useState(getAtLocal("pageLimit_seasons_episodes") || 5);
  const [sort, setSort] = useState(
    getAtSession("seasons_episodes")?.sort || ["id", "DESC"]
  );
  const [search, setSearch] = useState(
    getAtSession("seasons_episodes")?.search || {
      name: null,
      type: null,
    }
  );

  const isInitialLoad = useRef(true);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "5%",
    },
    {
      title: "Poster",
      dataIndex: "poster",
      key: "poster",
      align: "center",
      width: "10%",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Image
            src={record.poster}
            style={{ width: 50, height: 50, borderRadius: 10 }}
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      align: "center",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <div style={{ width: 20 }}></div> */}
          <div style={{ marginLeft: 10 }}>{text}</div>
        </div>
      ),
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      align: "center",
      width: "5%",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      align: "center",
      width: "10%",
    },
    {
      title: "Play",
      dataIndex: "play",
      key: "play",
      align: "center",
      width: 50,
      render: (text, record, index) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                sowModal(true);
                playerSourced(record);
              }}
              style={{ fontSize: 20, color: "#43b594", cursor: "pointer" }}
            >
              {icons.PLAY}
            </div>

            <Input
              id={"stream_url" + record.id}
              type="text"
              readOnly
              value={record.stream_url}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              style={{
                background: "none",
                border: "none",
                outline: "none",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            />
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                copy("stream_url" + record.id);
              }}
              className={"copy-btn"}
              style={{ fontSize: 20 }}
            >
              <i className={"fa fa-copy"} />
            </div>
          </div>
        );
      },
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: "10%",
      align: "center",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: icons.EDIT,
            },
            {
              key: "delete",
              name: "Delete",
              icon: icons.DELETE,
              question: `Do you want to delete this episode?`,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleMenuClick = (e, record) => {
    switch (e.key) {
      case "edit":
        showAddEpisodeDrawer("edit", record.id);
        break;
      case "delete":
        removeEpisodes(record.id);
        break;
      default:
        break;
    }
  };

  const handleTableChange = (data) => {
    const episodes = {
      page: data.page,
      search: data.search,
    };
    if (data.sort) {
      episodes.sort = [`${data.sort[0]}`, `${data.sort[1]}`];
    }
    setInLocal("pageLimit_seasons_episodes", data.limit);
    setInSession("seasons_episodes", episodes);
    setCurrentPage(data.page);
    setLimit(data.limit);
    setTotal(data.total);
    setSort(data.sort);
    setSearch(data.search);
  };

  const handleSeasonChange = (key) => {
    if (editableId) {
      setSelectedKey(key);
      seasonId(key);
      isInitialLoad.current = false;
    }
  };

  useEffect(() => {
    if (season) {
      const params = {
        page: currentPage,
        limit,
        total,
        sort,
        filter: { seasonId: selectedKey },
      };

      const successData = (episodes) => {
        episodes?.forEach((item) => {
          setEpisodes(item?.rows);
          setTotal(item.total);
          setLimit(item.limit);
          setCurrentPage(item.page);
        });
      };

      const errorData = (error) => {
        console.error("Error:", error);
      };

      episodeSourced(params, successData, errorData);

      const newEpisodesInfo = season?.find((item) => item.id === selectedKey);
      setEpisodesinfo(newEpisodesInfo || "");
    }
  }, [selectedKey, getAgain, season, currentPage, limit, total, sort]);

  useEffect(() => {
    if (isInitialLoad.current && season && season.length > 0) {
      const lastSeason = season[season.length - 1];
      setSelectedKey(lastSeason.id);

      isInitialLoad.current = false;
    } else if (!isInitialLoad.current) {
      const editableSeason = season.find((item) => item.id == selectedKey);
      setSelectedKey(editableSeason?.id);
    }
    const seasonOptions = season?.map((item) => ({
      label: item.name,
      value: item.id,
    }));
    setSeasonOption(seasonOptions);
  }, [season, setSelectedKey]);

  return (
    <>
      <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 20 }}>
        {season?.length > 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 20,
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ width: "25%", display: "flex", flexDirection: "column", gap: 5 }}
            >
              <span
                style={{
                  fontSize: "16px",
                }}
              >
                Season
              </span>
              <Select
                options={seasonOption}
                onChange={handleSeasonChange}
                value={selectedKey}
                disabled={seasonOption.length === 0}
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) => {
                  return (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase());
                }}
                placeholder="Select season"
              />
            </div>
          </div>
        )}
        {editableId && (
          <div style={{ position: "absolute", top: 30, right: 0 }}>
            <ButtonComponent
              title="Add Season"
              icon={icons.ADD}
              onClick={() => showSeasonDrawer("add")}
            />
          </div>
        )}
        {episodesinfo && season.length > 0 ? (
          <div>
            <div
              style={{ display: "flex", alignItems: "center", gap: 20, flexWrap: "wrap" }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                <Typography.Title level={4}>{episodesinfo?.name}</Typography.Title>
                <Typography.Text>{`${episodes?.length} Episodes`}</Typography.Text>
                <Typography.Text>
                  {episodesinfo?.description
                    ? `Description:  ${episodesinfo?.description}`
                    : "N/A"}
                </Typography.Text>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "flex-end",
                  gap: 10,
                  marginLeft: 10,
                  flex: 1,
                }}
              >
                <ButtonComponent
                  title="Edit"
                  icon={icons.EDIT}
                  onClick={() => showSeasonDrawer("edit")}
                />
                <ButtonComponent
                  title="Delete"
                  icon={icons.DELETE}
                  onClick={() => {
                    return Modal.confirm({
                      title: "Do you want to delete this season?",
                      content: "",
                      okText: "Delete",
                      onOk() {
                        removeSeason(selectedKey);
                      },
                    });
                  }}
                />
              </div>
            </div>

            <div style={{ marginTop: 20 }}>
              <TableComponent
                header={
                  <ButtonComponent
                    title="Add Episode"
                    icon={icons.ADD}
                    onClick={() => showAddEpisodeDrawer("add", selectedKey)}
                  />
                }
                dataSource={episodes}
                columns={columns}
                onChange={handleTableChange}
                localeClick={() => showAddEpisodeDrawer("add")}
                pagination={{
                  page: currentPage,
                  limit,
                  total,
                }}
              />
            </div>
          </div>
        ) : (
          <Empty />
        )}
      </div>
      <Divider />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <NextBackButton type="back" onClick={() => onChangeStep("back")} />
        <ButtonComponent title="Save" onClick={() => form.submit()} />
      </div>
    </>
  );
}
